import React, { useMemo } from 'react';

import FormField, { FormFieldOptions } from '@gi/form-responsive';
import { DistanceUnits } from '@gi/units';

import LengthInput from './length-input';

type FieldProps =
  | {
      x: { htmlFor: string };
      y: { htmlFor: string };
    }
  | {
      x: { fakeLabel: true };
      y: { fakeLabel: true };
    };

interface iProps {
  value: Vector2;
  onChange: (value: Vector2) => void;
  xLabel?: string;
  yLabel?: string;
  id?: string;
  distanceUnits: DistanceUnits;
  disabled?: boolean;
  labelSize?: FormFieldOptions['labelSize'];
}

const VectorInput = ({ value, onChange, xLabel = 'x', yLabel = 'y', id, distanceUnits, disabled, labelSize = 10 }: iProps): JSX.Element => {
  const ids = useMemo<FieldProps>(() => {
    return id
      ? {
          x: { htmlFor: `${id}-x` },
          y: { htmlFor: `${id}-y` },
        }
      : {
          x: { fakeLabel: true },
          y: { fakeLabel: true },
        };
  }, [id]);

  return (
    <div>
      <FormField label={xLabel} disabled={disabled} desktopLayout={{ labelSize }} mobileLayout={{ layout: 'row', labelSize }} {...ids.x}>
        <LengthInput
          id={id ? `${id}-x` : undefined}
          value={value.x}
          onChange={(newValue) => onChange({ x: newValue, y: value.y })}
          distanceUnits={distanceUnits}
          disabled={disabled}
        />
      </FormField>
      <FormField label={yLabel} disabled={disabled} desktopLayout={{ labelSize }} mobileLayout={{ layout: 'row', labelSize }} {...ids.y}>
        <LengthInput
          id={id ? `${id}-y` : undefined}
          value={value.y}
          onChange={(newValue) => onChange({ x: value.x, y: newValue })}
          distanceUnits={distanceUnits}
          disabled={disabled}
        />
      </FormField>
    </div>
  );
};

export default VectorInput;
