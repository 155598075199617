import React from 'react';
import MenuBar from '../common/menu-bar';
import MenuBarGroup from '../common/menu-bar-group';

import NotesControls from './notes-controls';

interface iProps {
  hideFileControls?: boolean;
}

const DesktopNotesMenu = ({ hideFileControls }: iProps): JSX.Element => {
  return (
    <MenuBar>
      <MenuBarGroup title='Notes'>
        <NotesControls hideFileControls={hideFileControls} />
      </MenuBarGroup>
    </MenuBar>
  );
};

export default DesktopNotesMenu;
