import React from 'react';
import { useElement } from '@gi/react-utils';

import FixedTutorialWindow from './fixed-tutorial-window';
import AttachedTutorialWindow from './attached-tutorial-window';
import TutorialDisplayContainer from './tutorial-display-container';
import { TutorialAttachment } from '../tutorial-base';
import PausedTutorialWindow from './paused-tutorial-window';

interface iProps {
  attachment?: TutorialAttachment;
  paused: boolean;
  children: React.ReactNode;
}

const TutorialWindow = ({ children, attachment, paused }: iProps): JSX.Element => {
  const element = useElement(attachment?.locator);

  if (paused) {
    return <PausedTutorialWindow>{children}</PausedTutorialWindow>;
  }

  if (attachment && !element) {
    console.error(`Invalid tutorial step attachment, no element found for locator ${attachment.locator}`);
  }

  if (!attachment || !element) {
    return (
      <TutorialDisplayContainer>
        <FixedTutorialWindow>{children}</FixedTutorialWindow>
      </TutorialDisplayContainer>
    );
  }

  return (
    <AttachedTutorialWindow target={element} attachment={attachment}>
      {children}
    </AttachedTutorialWindow>
  );
};

export default TutorialWindow;
