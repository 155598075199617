import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import TopMenuButton from '../components/top-menu-button';
import MenuIcon from '../desktop-menu/common/menu-icon';
import { PlannerCommandContext } from '../../planner-command-provider';

import styles from './save-top-menu-button.module.css';

const SaveTopMenuButton = (): JSX.Element => {
  const { savePlan, savingPlan, hasUnsavedChanges } = useContext(PlannerCommandContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  return (
    <TopMenuButton
      type='button'
      disabled={savePlan === null || savingPlan}
      onClick={() => {
        if (savePlan) savePlan();
      }}
      className={styles.saveTopMenuButton}
      data-identifier='top-menu-save'
    >
      <MenuIcon icon='icon-floppy' loading={savingPlan} overlayIcon={hasUnsavedChanges || activePlan === null ? null : 'icon-ok'} /> Save
    </TopMenuButton>
  );
};

export default SaveTopMenuButton;
