import { PlantSpacings } from '@gi/plant';
import { CalendarRangeStrings, EMPTY_PLANTING_CALENDAR, getPlantingCalendarStrings } from '@gi/planting-calendar';
import { DistanceUnits } from '@gi/units';
import { toCSV } from '@gi/to-csv';

import { PlantListAreaGroup, PlantListColumns, PlantListOptions, PlantListSummaryGroup, PlantListVarietyGroup, TypeCounts } from '../types/plant-list-types';
import { AreaCSVRow, SummaryCSVRow, VarietyCSVRow } from './csv-types';

// How many half-months southern hemisphere users should be offset by for planting calendars.
const SOUTHERN_HEMISPHERE_HALF_MONTH_OFFSET = 12; // 12 = 6 months

function calendarRangeToString(monthRangeText: CalendarRangeStrings): string {
  if (monthRangeText.allYearRound) {
    return 'All year round';
  }

  return `${monthRangeText.start} → ${monthRangeText.end}`;
}

const AREA_CSV_COLUMNS: Record<keyof AreaCSVRow, string> = {
  plantName: 'Plant Name',
  varietyName: 'Variety Name',
  scientificName: 'Scientific Name',
  plantLabel: 'Plant Label',
  quantity: 'Quantity',
  spacings: 'Spacings',
  inGroundStart: 'In-Ground Start',
  inGroundEnd: 'In-Ground End',
  inGroundAll: 'In-Ground All',
  plantingCalendarSow: 'Sow Indoors',
  plantingCalendarPlant: 'Sow/Plant Outdoors',
  plantingCalendarHarvest: 'Harvest',
};

const VARIETY_CSV_COLUMNS: Record<keyof VarietyCSVRow, string> = {
  plantName: 'Plant Name',
  varietyName: 'Variety Name',
  scientificName: 'Scientific Name',
  quantity: 'Quantity',
  spacings: 'Spacings',
  plantingCalendarSow: 'Sow Indoors',
  plantingCalendarPlant: 'Sow/Plant Outdoors',
  plantingCalendarHarvest: 'Harvest',
  notes: 'Notes',
};

const SUMMARY_CSV_COLUMNS: Record<keyof SummaryCSVRow, string> = {
  plantName: 'Plant Name',
  scientificName: 'Scientific Name',
  quantity: 'Quantity',
  spacings: 'Spacings',
  plantingCalendarSow: 'Sow Indoors',
  plantingCalendarPlant: 'Sow/Plant Outdoors',
  plantingCalendarHarvest: 'Harvest',
};

function getAreaCSVColumns(columns: PlantListColumns, options: PlantListOptions): (keyof AreaCSVRow)[] {
  const csvColumns: (keyof AreaCSVRow)[] = [];

  csvColumns.push('plantName');
  csvColumns.push('varietyName');

  if (options.showScientificName) {
    csvColumns.push('scientificName');
  }

  csvColumns.push('plantLabel');

  if (columns.spacings) {
    csvColumns.push('spacings');
  }

  if (columns.counts) {
    csvColumns.push('quantity');
  }

  if (columns.inGroundDates) {
    csvColumns.push('inGroundStart');
    csvColumns.push('inGroundEnd');
    csvColumns.push('inGroundAll');
  }

  if (columns.calendar) {
    csvColumns.push('plantingCalendarSow');
    csvColumns.push('plantingCalendarPlant');
    csvColumns.push('plantingCalendarHarvest');
  }

  return csvColumns;
}

function getVarietyCSVColumns(columns: PlantListColumns, options: PlantListOptions): (keyof VarietyCSVRow)[] {
  const csvColumns: (keyof VarietyCSVRow)[] = [];

  csvColumns.push('plantName');
  csvColumns.push('varietyName');

  if (options.showScientificName) {
    csvColumns.push('scientificName');
  }

  if (columns.spacings) {
    csvColumns.push('spacings');
  }

  if (columns.counts) {
    csvColumns.push('quantity');
  }

  if (columns.calendar) {
    csvColumns.push('plantingCalendarSow');
    csvColumns.push('plantingCalendarPlant');
    csvColumns.push('plantingCalendarHarvest');
  }

  if (columns.notes) {
    csvColumns.push('notes');
  }

  return csvColumns;
}

function getPlantCSVColumns(columns: PlantListColumns, options: PlantListOptions): (keyof SummaryCSVRow)[] {
  const csvColumns: (keyof SummaryCSVRow)[] = [];

  csvColumns.push('plantName');

  if (options.showScientificName) {
    csvColumns.push('scientificName');
  }

  if (columns.spacings) {
    csvColumns.push('spacings');
  }

  if (columns.counts) {
    csvColumns.push('quantity');
  }

  if (columns.calendar) {
    csvColumns.push('plantingCalendarSow');
    csvColumns.push('plantingCalendarPlant');
    csvColumns.push('plantingCalendarHarvest');
  }

  return csvColumns;
}

function spacingToString(countsByType: TypeCounts, spacing: PlantSpacings, distanceUnits: DistanceUnits): string {
  const strings: string[] = [];
  if (countsByType.area > 0) {
    strings.push(
      `Single: ${distanceUnits.getUnitString(spacing.spacing)}, In-Row: ${distanceUnits.getUnitString(spacing.inRowSpacing)}, ${distanceUnits.getUnitString(spacing.rowSpacing)} row gap`
    );
  }

  if (countsByType.sfg > 0) {
    strings.push(`SFG: ${spacing.sfgCount} plants/sqft`);
  }

  return strings.join(', ');
}

function createAreaCSVRow(areaData: PlantListAreaGroup, distanceUnits: DistanceUnits, isNorthernHemisphere: boolean): AreaCSVRow {
  const textPlantingCalendar = getPlantingCalendarStrings(
    areaData.plantingCalendar === null ? EMPTY_PLANTING_CALENDAR : areaData.plantingCalendar,
    isNorthernHemisphere ? 0 : SOUTHERN_HEMISPHERE_HALF_MONTH_OFFSET
  );

  return {
    plantName: areaData.plantName,
    varietyName: areaData.variety,
    scientificName: areaData.plant.scientificName,
    plantLabel: areaData.planPlant.labelText,
    quantity: areaData.count,
    spacings: spacingToString(areaData.countsByType, areaData.spacing, distanceUnits),
    inGroundStart: areaData.planPlant.inGroundStart,
    inGroundEnd: areaData.planPlant.inGroundEnd,
    inGroundAll: areaData.planPlant.inGroundAll ? 'true' : 'false',
    plantingCalendarSow: textPlantingCalendar.sow.map(calendarRangeToString).join('|'),
    plantingCalendarPlant: textPlantingCalendar.plant.map(calendarRangeToString).join('|'),
    plantingCalendarHarvest: textPlantingCalendar.harvest.map(calendarRangeToString).join('|'),
  };
}

function createSummaryCSVRow(plantData: PlantListSummaryGroup, distanceUnits: DistanceUnits, isNorthernHemisphere: boolean): SummaryCSVRow {
  const textPlantingCalendar = getPlantingCalendarStrings(
    plantData.plantingCalendar === null ? EMPTY_PLANTING_CALENDAR : plantData.plantingCalendar,
    isNorthernHemisphere ? 0 : SOUTHERN_HEMISPHERE_HALF_MONTH_OFFSET
  );

  return {
    plantName: plantData.plantName,
    scientificName: plantData.plant.scientificName,
    quantity: plantData.count,
    spacings: spacingToString(plantData.countsByType, plantData.spacing, distanceUnits),
    plantingCalendarSow: textPlantingCalendar.sow.map(calendarRangeToString).join('|'),
    plantingCalendarPlant: textPlantingCalendar.plant.map(calendarRangeToString).join('|'),
    plantingCalendarHarvest: textPlantingCalendar.harvest.map(calendarRangeToString).join('|'),
  };
}

function createVarietyCSVRow(varietyData: PlantListVarietyGroup, distanceUnits, isNorthernHemisphere: boolean): VarietyCSVRow {
  const textPlantingCalendar = getPlantingCalendarStrings(
    varietyData.plantingCalendar === null ? EMPTY_PLANTING_CALENDAR : varietyData.plantingCalendar,
    isNorthernHemisphere ? 0 : SOUTHERN_HEMISPHERE_HALF_MONTH_OFFSET
  );

  return {
    plantName: varietyData.plantName,
    scientificName: varietyData.plant.scientificName,
    varietyName: varietyData.variety,
    quantity: varietyData.count,
    spacings: spacingToString(varietyData.countsByType, varietyData.spacing, distanceUnits),
    plantingCalendarSow: textPlantingCalendar.sow.map(calendarRangeToString).join('|'),
    plantingCalendarPlant: textPlantingCalendar.plant.map(calendarRangeToString).join('|'),
    plantingCalendarHarvest: textPlantingCalendar.harvest.map(calendarRangeToString).join('|'),
    notes: varietyData.plantNote,
  };
}

export function createAreaCSVString(
  areaData: readonly PlantListAreaGroup[],
  distanceUnits: DistanceUnits,
  columns: PlantListColumns,
  options: PlantListOptions,
  isNorthernHemisphere: boolean
): string {
  const csvColumns = getAreaCSVColumns(columns, options);
  const csvRows = areaData.map((a) => {
    return createAreaCSVRow(a, distanceUnits, isNorthernHemisphere);
  });

  return toCSV<AreaCSVRow>(csvRows, csvColumns, AREA_CSV_COLUMNS);
}

export function createVarietyCSVString(
  varietyData: readonly PlantListVarietyGroup[],
  distanceUnits: DistanceUnits,
  columns: PlantListColumns,
  options: PlantListOptions,
  isNorthernHemisphere: boolean
): string {
  const csvColumns = getVarietyCSVColumns(columns, options);
  const csvRows = varietyData.map((a) => {
    return createVarietyCSVRow(a, distanceUnits, isNorthernHemisphere);
  });

  return toCSV<VarietyCSVRow>(csvRows, csvColumns, VARIETY_CSV_COLUMNS);
}

export function createSummaryCSVString(
  plantData: readonly PlantListSummaryGroup[],
  distanceUnits: DistanceUnits,
  columns: PlantListColumns,
  options: PlantListOptions,
  isNorthernHemisphere: boolean
): string {
  const csvColumns = getPlantCSVColumns(columns, options);
  const csvRows = plantData.map((a) => {
    return createSummaryCSVRow(a, distanceUnits, isNorthernHemisphere);
  });

  return toCSV<SummaryCSVRow>(csvRows, csvColumns, SUMMARY_CSV_COLUMNS);
}
