import { Geometry, Matrix } from '@gi/math';
import { SimulatedPlant } from '../simulated-plant';
import { EditSimulatedGardenItem } from './edit-simulated-garden-item';

class EditSimulatedPlantLabel extends EditSimulatedGardenItem<SimulatedPlant> {
  labelOffset: Vector2;
  labelPosition: Vector2;
  plantCenter: Vector2;
  isEditingPlant: boolean;

  start(): void {
    this.plantCenter = Geometry.midpoint(this.target.rowStart, this.target.rowEnd);
    this.labelOffset = { ...this.target.labelOffset };
    this.labelPosition = Geometry.addPoint(this.labelOffset, this.plantCenter);
  }

  translate(positionOffset: Vector2): void {
    // If the plant is also being edited, then we don't need to change our offset
    if (this.isEditingPlant) {
      return;
    }

    this.target.setLabelOffset(Geometry.addPoint(this.labelOffset, positionOffset));
  }

  rotate(angle: number, pivot: Vector2, matrix: number[][]): void {
    // If the plant is also being edited, then the plant handles label position updates
    if (this.isEditingPlant) {
      return;
    }

    const targetPos = Matrix.multiplyVector(this.labelPosition, matrix);
    const diff = Geometry.getPointDelta(this.plantCenter, targetPos);
    this.target.setLabelOffset(diff);
  }

  // eslint-disable-next-line class-methods-use-this
  snapTranslate(): void {
    // Labels don't snap in the old renderer.
  }

  // eslint-disable-next-line class-methods-use-this
  end(): void {
    // Do nothing
  }
}

export default EditSimulatedPlantLabel;
