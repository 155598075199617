import React, { CSSProperties, ReactNode } from 'react';

import './selectable-item.scss';

export interface iSelectableItemTag {
  name: string;
  className?: string;
}

interface iProps {
  icon: ReactNode;
  name: ReactNode;
  amountText?: ReactNode;
  tags?: iSelectableItemTag[];
  buttons?: ReactNode[];
  statusIcons?: ReactNode[];
  expanded?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick: () => void;
  identifier?: string;
}

const ItemGroupHeading = ({
  icon,
  name,
  amountText,
  tags,
  buttons,
  statusIcons,
  expanded = false,
  className,
  style,
  onClick,
  identifier,
}: iProps): JSX.Element => {
  const classNames = ['mobile-drawing-tools-group-row'];
  if (expanded) {
    classNames.push('expanded');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} style={style} data-identifier={identifier}>
      {buttons ? <div className='buttons'>{buttons}</div> : null}
      <button className='main' type='button' onClick={() => onClick()} data-identifier={identifier ? `${identifier}-button` : undefined}>
        <div className='icon'>{icon}</div>
        <div className='content'>
          <span className='primary'>{name}</span>
          {amountText ? <span className='amount'>{amountText}</span> : null}
        </div>
        {statusIcons ? <div className='status-icons'>{statusIcons}</div> : null}
        <div className='dropdown-icon'>
          <i className='icon-down-open-1' />
        </div>
      </button>
      <div className='tags'>
        {tags?.map((tag, i) => (
          <span className={tag.className} key={i}>
            {tag.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ItemGroupHeading;
