import React, { useContext, useState } from 'react';
import { CropRotationHelpModal, LayerSelectorInfoModal, LayoutControlsInfoModal, MonthSelectorInfoModal } from '@gi/help-modals';
import { FeatureGate } from '@gi/features';
import { useHelpModalEventHook } from '@gi/garden-platform-events';

import MenuBar from '../common/menu-bar';
import MenuBarGroup from '../common/menu-bar-group';
import TimelineControls from './timeline-controls/timeline-controls';
import CropRotationControls from './crop-rotation-controls/crop-rotation-controls';
import LayerControls from './layer-controls/layer-controls';
import HelpControls from './help-controls/help-controls';
import PlanControls from './plan-controls';
import EditControls from './edit-controls';
import LayoutControls from './layout-controls';

import TemporaryTutorialEditorControls from './temporary-tutorial-controls/temporary-tutorial-editor-controls';
import TemporaryTutorialControls from './temporary-tutorial-controls/temporary-tutorial-controls';
import { PlannerCommandContext } from '../../../planner-command-provider';

interface iProps {
  hideFileControls?: boolean;
}

const DesktopPlannerMenu = ({ hideFileControls }: iProps): JSX.Element => {
  const { hasPlanHistory } = useContext(PlannerCommandContext);
  const [monthInfoModalOpen, setMonthInfoModalOpen] = useState(false);
  const [cropRotationModalOpen, setCropRotationModalOpen] = useState(false);
  const [layoutHelpModalOpen, setLayoutHelpModalOpen] = useState(false);
  const [layersHelpModalOpen, setLayersHelpModalOpen] = useState(false);

  useHelpModalEventHook('month selector', monthInfoModalOpen, 'desktop-planner-menu-help-button');
  useHelpModalEventHook('crop rotation', cropRotationModalOpen, 'desktop-planner-menu-help-button');
  useHelpModalEventHook('layout', layoutHelpModalOpen, 'desktop-planner-menu-help-button');
  useHelpModalEventHook('layers', layersHelpModalOpen, 'desktop-planner-menu-help-button');

  return (
    <>
      {monthInfoModalOpen ? (
        <MonthSelectorInfoModal
          closeModal={() => {
            setMonthInfoModalOpen(false);
          }}
        />
      ) : null}
      {cropRotationModalOpen ? (
        <CropRotationHelpModal
          closeModal={() => {
            setCropRotationModalOpen(false);
          }}
        />
      ) : null}
      {layoutHelpModalOpen ? (
        <LayoutControlsInfoModal
          closeModal={() => {
            setLayoutHelpModalOpen(false);
          }}
        />
      ) : null}
      {layersHelpModalOpen ? (
        <LayerSelectorInfoModal
          closeModal={() => {
            setLayersHelpModalOpen(false);
          }}
        />
      ) : null}
      <MenuBar>
        <MenuBarGroup title='Plan'>
          <PlanControls hideFileControls={hideFileControls} />
        </MenuBarGroup>
        <MenuBarGroup title='Edit'>
          <EditControls />
        </MenuBarGroup>
        <MenuBarGroup title='Layout' onHelp={() => setLayoutHelpModalOpen(true)}>
          <LayoutControls />
        </MenuBarGroup>
        <MenuBarGroup title='Layers' onHelp={() => setLayersHelpModalOpen(true)}>
          <LayerControls />
        </MenuBarGroup>
        <MenuBarGroup title='Timeline' onHelp={() => setMonthInfoModalOpen(true)}>
          <TimelineControls />
        </MenuBarGroup>
        {hasPlanHistory ? (
          <MenuBarGroup title='Crop Rotation' onHelp={() => setCropRotationModalOpen(true)}>
            <CropRotationControls />
          </MenuBarGroup>
        ) : null}
        <MenuBarGroup title='Help'>
          <HelpControls />
        </MenuBarGroup>
        <FeatureGate featureId='TUTORIAL_EDITOR'>
          <MenuBarGroup title='Tutorials'>
            <TemporaryTutorialControls />
          </MenuBarGroup>
          <MenuBarGroup title='Tutorial Editor'>
            <TemporaryTutorialEditorControls />
          </MenuBarGroup>
        </FeatureGate>
      </MenuBar>
    </>
  );
};

export default DesktopPlannerMenu;
