import React from 'react';

import { SidebarContent, SidebarHeaderContent } from '@gi/planner-sidebar';
import { PlannerControlsTab } from '@gi/constants';
import { ObjectivesPane } from '@gi/objectives';

const SidebarObjectivesTabContent = (): JSX.Element => {
  return (
    <SidebarContent tabId={PlannerControlsTab.OBJECTIVES} data-identifier='sidebar-learn-to-use'>
      <SidebarHeaderContent tabId={PlannerControlsTab.OBJECTIVES}>
        <h4>Learn to Use</h4>
      </SidebarHeaderContent>
      <ObjectivesPane />
    </SidebarContent>
  );
};

export default SidebarObjectivesTabContent;
