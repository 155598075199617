import { SimulatedGardenItem } from '../simulated-garden-item';
import { Edit } from './edit';

export abstract class EditSimulatedGardenItem<T extends SimulatedGardenItem> extends Edit {
  readonly target: T;

  constructor(target: T) {
    super();

    this.target = target;
  }

  startBatch(): void {
    this.target.startBatchUpdate();
  }

  endBatch(): void {
    this.target.endBatchUpdate();
  }
}
