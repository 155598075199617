import { TutorialUtils } from './tutorial';
import { TutorialDataUtils } from './tutorial-data';
import { TutorialDataJSONFormat } from './tutorial-data-json-format';
import { Tutorials, TutorialsUtils } from './tutorials';

export function parseTutorials(tutorialJSONData: TutorialDataJSONFormat[]): Tutorials {
  let tutorials = TutorialsUtils.create();

  tutorialJSONData.forEach((tutorialJSON) => {
    const tutorialData = TutorialDataUtils.createFromJSONFormat(tutorialJSON);
    const tutorial = TutorialUtils.createFromTutorialData(tutorialData);
    tutorials = TutorialsUtils.addTutorial(tutorials, tutorial);
  });

  return tutorials;
}
