import {
  APITutorials,
  TutorialContext,
  TutorialDataJSONFormat,
  TutorialDataUtils,
  TutorialUtils,
  Tutorials,
  TutorialsUtils,
  type Tutorial,
} from '@gi/tutorial';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TutorialParseError } from './tutorial-editor-types';
import { validateTutorialString } from './tutorial-json-validator';

export type TutorialEditorContextType = {
  editingTutorials: Tutorials;
  tutorialsJSON: APITutorials;
  selectedTutorial: Tutorial | null;
  setSelectedTutorial: (tutorial: Tutorial | null) => void;
  editingTutorial: Tutorial | null;
  setEditingTutorial: (tutorial: Tutorial | null) => void;
  editingTutorialJSON: TutorialDataJSONFormat | null;
  setEditingTutorials: (tutorials: Tutorials) => void;
  editingErrors: TutorialParseError[] | null;
  setEditingErrors: (tutorial: null | TutorialParseError[]) => void;
  saveTutorial: (objString: string) => void;
};

export const TutorialEditorContext = createContext<TutorialEditorContextType>({} as TutorialEditorContextType);

interface iProps {
  children: React.ReactNode;
}

export const TutorialEditorProvider = ({ children }: iProps): JSX.Element => {
  const { tutorials } = useContext(TutorialContext);
  const [editingTutorials, setEditingTutorials] = useState<Tutorials>(tutorials ? tutorials : TutorialsUtils.create());
  const [selectedTutorial, setSelectedTutorial] = useState<Tutorial | null>(null);
  const [editingTutorial, setEditingTutorial] = useState<Tutorial | null>(null);
  const [editingErrors, setEditingErrors] = useState<null | TutorialParseError[]>(null);

  const tutorialsJSON = useMemo(() => {
    return TutorialsUtils.getTutorialsJson(tutorials);
  }, [tutorials]);

  const editingTutorialJSON = useMemo(() => {
    if (editingTutorial === null) {
      return null;
    }

    return TutorialDataUtils.getTutorialDataJSONFormat(editingTutorial.data);
  }, [editingTutorial]);

  // Update tutorials when they're first loaded
  useEffect(() => {
    if (editingTutorials.uuids.length === 0 && tutorials !== null && tutorials.uuids.length > 0) {
      setEditingTutorials(tutorials);
    }
  }, [tutorials]);

  const saveTutorial = useCallback(
    (objString: string) => {
      const result = validateTutorialString(objString);

      if (result === true) {
        setEditingErrors(null);
        const tutorial = TutorialUtils.createFromTutorialData(TutorialDataUtils.createFromJSONFormat(JSON.parse(objString)));
        setEditingTutorials(TutorialsUtils.updateTutorial(editingTutorials, tutorial));
        setEditingTutorial(tutorial);
        return;
      }

      setEditingErrors(result);
    },
    [editingTutorials]
  );

  const value = useMemo(
    () => ({
      editingTutorials,
      tutorialsJSON,
      setEditingTutorials,
      selectedTutorial,
      setSelectedTutorial,
      editingTutorial,
      editingTutorialJSON,
      setEditingTutorial,
      editingErrors,
      setEditingErrors,
      saveTutorial,
    }),
    [editingTutorials, tutorialsJSON, selectedTutorial, editingTutorial, editingTutorialJSON, editingErrors, saveTutorial]
  );

  return <TutorialEditorContext.Provider value={value}>{children}</TutorialEditorContext.Provider>;
};
