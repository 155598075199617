import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Plant } from '@gi/plant';

import { Tooltip, TooltipContent, TooltipKey, TooltipTrigger } from '@gi/tooltip';
import { PlannerIconWithFamily } from '@gi/planner-icon';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { PlantInformationActionCreators } from '@gi/plant-information-modal';
import { FeaturesContext } from '@gi/features';

import { SearchDisplayMode } from '@gi/search-service';
import { CompanionIcon } from '@gi/plant-icon';
import { ResourceContext } from '@gi/resource-provider';

import { DrawingToolsContext } from '../drawing-tools-context';
import DragToDrawButton from '../drag-to-draw/drag-to-draw-button';

interface iProps {
  plant: Plant;
  style: React.CSSProperties;
}

const PlantListItem = ({ plant, style }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const { featureEnabled } = useContext(FeaturesContext);
  const { store } = useContext(ResourceContext);

  const showNew = useMemo(() => {
    return plant.isNew && !featureEnabled('HIDE_NEW_TAGS');
  }, [plant.isNew, featureEnabled]);

  const [hasClickedFavourite, setHasClickedFavourite] = useState<boolean>(false);
  const [userWasLoading, setUserWasLoading] = useState<boolean>(false);

  const { plantFilters, selectedPlantCode, favouritePlants, cancelDraw, onPlantSelected, toggleFavouritePlant, userLoading } = useContext(DrawingToolsContext);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);

  const { searchResults } = plantFilters.filters.search.inputs;
  const searchResult = searchResults.getResult(plant.code);

  const isFavourite = useMemo(() => {
    return favouritePlants.has(plant.code);
  }, [favouritePlants, plant]);

  const isSelected = selectedPlantCode === plant.code;

  const handleSelect = () => {
    if (isSelected) {
      cancelDraw();
    } else {
      onPlantSelected(plant);
    }
  };

  const handleDragStart = (e: PointerEvent) => {
    onPlantSelected(plant, e);
  };

  const handleInfoRequest = () => {
    dispatch(PlantInformationActionCreators.openPlantInformationModal({ plant }));
  };

  const handleToggleFave = () => {
    toggleFavouritePlant(plant);
  };

  const renderSFGIcon = () => {
    if (!sfgMode || !plant.canBeSquareFootPlant) {
      return null;
    }

    return <div className='extra-icon'>SFG</div>;
  };

  const newIcon = showNew ? <div className='extra-icon extra-icon-new'>New</div> : null;

  const renderPerennialIcon = () => {
    if (!plant.perennial) {
      return null;
    }

    return <div className='extra-icon'>Perennial</div>;
  };

  const companionFilterInputs = plantFilters.filters.companion.inputs;

  const renderCompanionIcon = () => {
    if (!companionFilterInputs.enabled) {
      return null;
    }

    const { companionPlantCodes, plantCodes } = companionFilterInputs;
    const isHelper = companionPlantCodes.has(plant.code);
    const isHelped = plant.companionPlantCodes.some((code) => plantCodes.has(code));
    let caption = '';
    if (isHelped && isHelper) {
      caption = 'Companion Planting: Mutual benefit';
    } else if (isHelped) {
      caption = 'Companion Planting: Helped by one of the selected plants';
    } else if (isHelper) {
      caption = 'Companion Planting: Helps one of the selected plants';
    }

    return <CompanionIcon isHelped={isHelped} isHelper={isHelper} caption={caption} />;
  };

  const shoppingEnabled = useMemo(() => {
    return featureEnabled('SHOPPING');
  }, [featureEnabled]);

  const cartIcon = useMemo(() => {
    if (!shoppingEnabled) {
      return null;
    }

    if (store && store.products.productsByCode[plant.code]) {
      return (
        <div className='extra-icon'>
          <i className='icon-shopping-cart' />
        </div>
      );
    }

    return null;
  }, [store, shoppingEnabled]);

  const renderName = () => {
    return (
      <div className='item-name'>
        <span className='item-name-primary'>{plant.name}</span>
      </div>
    );
  };

  const renderSearchedName = () => {
    if (!searchResult) {
      return null;
    }

    switch (searchResult.displayMode) {
      case SearchDisplayMode.Primary: {
        return (
          <div className='item-name'>
            <span className='item-name-primary'>{searchResult.getHtml()}</span>
          </div>
        );
      }
      case SearchDisplayMode.Secondary: {
        return (
          <div className='item-name'>
            <span className='item-name-primary'>{plant.name}</span>
            <span className='item-name-secondary'>
              <em title={searchResult.getText()}>{searchResult.getHtml()}</em>
            </span>
          </div>
        );
      }
      default: {
        return (
          <div className='item-name'>
            <span className='item-name-primary'>{plant.name}</span>
          </div>
        );
      }
    }
  };

  const className = `selectable-option ${isSelected ? 'selected' : ''}`;
  let faveClassName = 'faved-object';
  if (hasClickedFavourite) {
    faveClassName += ' icon-spinner animate-pulse is-highlighted';
  } else {
    if (userLoading) {
      faveClassName += ' disabled';
    }
    if (isFavourite) {
      faveClassName += ' icon-star is-highlighted';
    } else {
      faveClassName += ' icon-star-empty';
    }
  }

  const onFavouriteClick = () => {
    if (!userLoading) {
      setHasClickedFavourite(true);
      handleToggleFave();
    }
  };

  useEffect(() => {
    if (userWasLoading && !userLoading && hasClickedFavourite) {
      setHasClickedFavourite(false);
    }

    if (userWasLoading !== userLoading) {
      setUserWasLoading(userLoading);
    }
  }, [userLoading]);

  return (
    <li className={className} data-code={plant.code} data-identifier='selectable-plant' style={style}>
      <div className='selectable-option-subs'>
        <Tooltip placement='right' delayOpen={500}>
          <TooltipTrigger>
            <button
              type='button'
              disabled={userLoading}
              className='selectable-option-fave'
              onClick={onFavouriteClick}
              data-tooltip-id={`drawing-tools-favorite:${plant.code}`}
            >
              <i className={faveClassName} />
            </button>
          </TooltipTrigger>
          <TooltipContent id={isFavourite ? TooltipKey.UnfavouritePlant : TooltipKey.FavouritePlant} parameters={[plant.name]} />
        </Tooltip>
        <Tooltip placement='right' delayOpen={500}>
          <TooltipTrigger>
            <button type='button' className='selectable-option-info' onClick={handleInfoRequest} data-tooltip-id={`drawing-tools-plant-info:${plant.code}`}>
              <i className='icon-info-book' />
            </button>
          </TooltipTrigger>
          <TooltipContent id={TooltipKey.PlantInformation} />
        </Tooltip>
      </div>
      <DragToDrawButton className='selectable-option-item' onClick={handleSelect} onDrag={handleDragStart} data-identifier='selectable-plant-button'>
        <div className='icon-container'>
          <PlannerIconWithFamily code={plant.code} familyID={plant.familyID} />
        </div>
        {searchResult ? renderSearchedName() : renderName()}
        {renderCompanionIcon()}
        <div className='extra-icon-container'>
          {cartIcon}
          {renderPerennialIcon()}
          {renderSFGIcon()}
          {newIcon}
        </div>
      </DragToDrawButton>
    </li>
  );
};

export default PlantListItem;
