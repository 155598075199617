import EventEmitter from 'eventemitter3';
import { CropRotationModes, GardenItemType, LayerDisplayModes } from '@gi/constants';
import { SimulatedPlant } from './simulated-plant';
import { SimulatedText } from './simulated-text';
import { SimulatedShape } from './simulated-shape';
import { SimulatedGardenObject } from './simulated-garden-object';
import { SimulatedGardenItemEvent } from './simulated-garden-item';
import { SimulatedHistoricalPlanEvent, SimulatedHistoricalPlan } from './simulated-historical-plan';
import CollisionWorld from './collisions/collision-world';

export enum SimulatedPlanEvents {
  DidUpdate = 'DidUpdate',
  ItemAdded = 'ItemAdded',
  ItemRemoved = 'ItemRemoved',
  ItemUpdated = 'ItemUpdated',
  HistoricalPlanAdded = 'HistoricalPlanAdded',
  HistoricalPlanUpdated = 'HistoricalPlanUpdated',
  HistoricalPlanRemoved = 'HistoricalPlanRemoved',
  PushUpdates = 'PushUpdates',
}

export type SimulatedPlanEventActions = {
  [SimulatedPlanEvents.DidUpdate]: () => void;
  [SimulatedPlanEvents.ItemAdded]: (type: GardenItemType, id: number) => void;
  [SimulatedPlanEvents.ItemRemoved]: (type: GardenItemType, id: number) => void;
  [SimulatedPlanEvents.ItemUpdated]: (type: GardenItemType, id: number) => void;
  [SimulatedPlanEvents.HistoricalPlanAdded]: (id: number) => void;
  [SimulatedPlanEvents.HistoricalPlanUpdated]: (id: number) => void;
  [SimulatedPlanEvents.HistoricalPlanRemoved]: (id: number) => void;
  [SimulatedPlanEvents.PushUpdates]: () => void;
};

type GardenItemTypeMapping = {
  [GardenItemType.Plant]: SimulatedPlant;
  [GardenItemType.GardenObject]: SimulatedGardenObject;
  [GardenItemType.Shape]: SimulatedShape;
  [GardenItemType.Text]: SimulatedText;
};

export class SimulatedPlan extends EventEmitter<SimulatedPlanEventActions> {
  #id: number;
  get id() {
    return this.#id;
  }

  #width: number;
  get width() {
    return this.#width;
  }

  #height: number;
  get height() {
    return this.#height;
  }

  get dimensions(): Dimensions {
    return {
      width: this.width,
      height: this.height,
    };
  }

  #showGrid: boolean = true;
  get showGrid() {
    return this.#showGrid;
  }

  #showRulers: boolean = true;
  get showRulers() {
    return this.#showRulers;
  }

  #useMetric: boolean = true;
  get useMetric() {
    return this.#useMetric;
  }

  #layerDisplayMode: LayerDisplayModes = LayerDisplayModes.ALL;
  get layerDisplayMode() {
    return this.#layerDisplayMode;
  }

  #visibleMonth: number | null = null;
  get visibleMonth() {
    return this.#visibleMonth;
  }

  #cropRotationMode: CropRotationModes = CropRotationModes.AUTOMATIC;
  get cropRotationMode() {
    return this.#cropRotationMode;
  }

  #plants: Record<number, SimulatedPlant>;
  get plants(): Readonly<Record<number, SimulatedPlant>> {
    return this.#plants;
  }

  #plantIds: number[];
  get plantIds() {
    return this.#plantIds;
  }

  #gardenObjects: Record<number, SimulatedGardenObject>;
  get gardenObjects(): Readonly<Record<number, SimulatedGardenObject>> {
    return this.#gardenObjects;
  }

  #gardenObjectIds: number[];
  get gardenObjectIds() {
    return this.#gardenObjectIds;
  }

  #shapes: Record<number, SimulatedShape>;
  get shapes(): Readonly<Record<number, SimulatedShape>> {
    return this.#shapes;
  }

  #shapeIDs: number[];
  get shapeIDs() {
    return this.#shapeIDs;
  }

  #text: Record<number, SimulatedText>;
  get text(): Readonly<Record<number, SimulatedText>> {
    return this.#text;
  }

  #textIds: number[];
  get textIds() {
    return this.#textIds;
  }

  #maxItemId: number;
  get nextItemId() {
    return this.#maxItemId + 1;
  }

  /**
   * Map of item id to GardenItemType, allows for us to find the simulated
   * item by id without checking each type
   */
  #itemTypes: Record<number, GardenItemType>;
  get itemTypes() {
    return this.#itemTypes;
  }

  #historicalPlans: Record<number, SimulatedHistoricalPlan>;
  get historicalPlans() {
    return this.#historicalPlans;
  }

  #historicalPlanIds: number[];
  get historicalPlanIds() {
    return this.#historicalPlanIds;
  }

  #collisionWorld: CollisionWorld;

  constructor(id: number, width: number, height: number) {
    super();

    this.#id = id;
    this.#width = width;
    this.#height = height;

    this.#maxItemId = 0;

    this.#plants = {};
    this.#plantIds = [];

    this.#gardenObjects = {};
    this.#gardenObjectIds = [];

    this.#shapes = {};
    this.#shapeIDs = [];

    this.#text = {};
    this.#textIds = [];

    this.#itemTypes = {};

    this.#historicalPlans = {};
    this.#historicalPlanIds = [];

    this.#collisionWorld = new CollisionWorld(width, height);
  }

  signalPushUpdates() {
    this.emit(SimulatedPlanEvents.PushUpdates);
  }

  setDimensions(width: number, height: number) {
    this.#width = width;
    this.#height = height;
    this.#collisionWorld.setSize(width, height);
    this.emit(SimulatedPlanEvents.DidUpdate);
  }

  setShowGrid(showGrid: boolean) {
    if (showGrid !== this.showGrid) {
      this.#showGrid = showGrid;
      this.emit(SimulatedPlanEvents.DidUpdate);
    }
  }

  setShowRulers(showRulers: boolean) {
    if (showRulers !== this.showRulers) {
      this.#showRulers = showRulers;
      this.emit(SimulatedPlanEvents.DidUpdate);
    }
  }

  setMetricDistanceUnits(useMetric: boolean) {
    if (useMetric !== this.useMetric) {
      this.#useMetric = useMetric;
      this.emit(SimulatedPlanEvents.DidUpdate);
    }
  }

  setLayerDisplayMode(layerDisplayMode: LayerDisplayModes) {
    if (layerDisplayMode !== this.layerDisplayMode) {
      this.#layerDisplayMode = layerDisplayMode;
      this.emit(SimulatedPlanEvents.DidUpdate);
    }
  }

  setVisibleMonth(month: number | null) {
    if (month !== this.visibleMonth) {
      this.#visibleMonth = month;
      this.emit(SimulatedPlanEvents.DidUpdate);
    }
  }

  setCropRotationMode(mode: CropRotationModes) {
    if (mode !== this.cropRotationMode) {
      this.#cropRotationMode = mode;
      this.emit(SimulatedPlanEvents.DidUpdate);
    }
  }

  #itemDidUpdate = (type: GardenItemType, id: number) => {
    this.emit(SimulatedPlanEvents.ItemUpdated, type, id);
  };

  #historicalPlanDidUpdate = (planId: number) => {
    this.emit(SimulatedPlanEvents.HistoricalPlanUpdated, planId);
  };

  addPlant(plant: SimulatedPlant) {
    this.#plants[plant.id] = plant;
    this.#plantIds.push(plant.id);
    this.#itemTypes[plant.id] = GardenItemType.Plant;
    this.#maxItemId = Math.max(this.#maxItemId, plant.id);
    this.#collisionWorld.addCollider(plant.collider);

    plant.on(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
    this.emit(SimulatedPlanEvents.ItemAdded, GardenItemType.Plant, plant.id);
  }

  addGardenObject(gardenObject: SimulatedGardenObject) {
    this.#gardenObjects[gardenObject.id] = gardenObject;
    this.#gardenObjectIds.push(gardenObject.id);
    this.#itemTypes[gardenObject.id] = GardenItemType.GardenObject;
    this.#maxItemId = Math.max(this.#maxItemId, gardenObject.id);
    this.#collisionWorld.addCollider(gardenObject.collider);

    gardenObject.on(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
    this.emit(SimulatedPlanEvents.ItemAdded, GardenItemType.GardenObject, gardenObject.id);
  }

  addShape(shape: SimulatedShape) {
    this.#shapes[shape.id] = shape;
    this.#shapeIDs.push(shape.id);
    this.#itemTypes[shape.id] = GardenItemType.Shape;
    this.#maxItemId = Math.max(this.#maxItemId, shape.id);

    shape.on(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
    this.emit(SimulatedPlanEvents.ItemAdded, GardenItemType.Shape, shape.id);
  }

  addText(text: SimulatedText) {
    this.#text[text.id] = text;
    this.#textIds.push(text.id);
    this.#itemTypes[text.id] = GardenItemType.Text;
    this.#maxItemId = Math.max(this.#maxItemId, text.id);

    text.on(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
    this.emit(SimulatedPlanEvents.ItemAdded, GardenItemType.Text, text.id);
  }

  addHistoricalPlan(historicalPlan: SimulatedHistoricalPlan) {
    this.#historicalPlans[historicalPlan.id] = historicalPlan;
    this.#historicalPlanIds.push(historicalPlan.id);

    historicalPlan.on(SimulatedHistoricalPlanEvent.DidUpdate, () => this.#historicalPlanDidUpdate(historicalPlan.id));
    this.emit(SimulatedPlanEvents.HistoricalPlanAdded, historicalPlan.id);
  }

  removeHistoricalPlan(historicalPlanId: number) {
    if (this.#historicalPlans[historicalPlanId] === undefined) {
      console.error(`Attempted to remove plan history which doesn't exist: planID ${historicalPlanId}`);
      return;
    }

    delete this.#historicalPlans[historicalPlanId];
    const index = this.#historicalPlanIds.indexOf(historicalPlanId);
    if (index !== -1) {
      this.#historicalPlanIds.splice(index, 1);
    }

    this.emit(SimulatedPlanEvents.HistoricalPlanRemoved, historicalPlanId);
  }

  removeItem(itemId: number) {
    switch (this.itemTypes[itemId]) {
      case GardenItemType.Plant: {
        // this.#plants[itemId].destroy();
        this.#collisionWorld.removeCollider(this.#plants[itemId].collider);
        this.#plants[itemId].off(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
        delete this.#plants[itemId];
        delete this.#itemTypes[itemId];

        // Remove item ID from list of Plant Ids
        const index = this.plantIds.indexOf(itemId);
        if (index === -1) {
          throw new Error(`Plant ID not found ${itemId}`);
        }
        this.plantIds.splice(index, 1);

        this.emit(SimulatedPlanEvents.ItemRemoved, GardenItemType.Plant, itemId);
        break;
      }
      case GardenItemType.GardenObject: {
        // this.#plants[itemId].destroy();
        this.#collisionWorld.removeCollider(this.#gardenObjects[itemId].collider);
        this.#gardenObjects[itemId].off(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
        delete this.#gardenObjects[itemId];
        delete this.itemTypes[itemId];

        // Remove item ID from list of Garden object Ids
        const index = this.gardenObjectIds.indexOf(itemId);
        if (index === -1) {
          throw new Error(`Garden Object ID not found ${itemId}`);
        }
        this.gardenObjectIds.splice(index, 1);

        this.emit(SimulatedPlanEvents.ItemRemoved, GardenItemType.GardenObject, itemId);
        break;
      }
      case GardenItemType.Shape: {
        this.#shapes[itemId].off(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
        delete this.#shapes[itemId];
        delete this.itemTypes[itemId];

        // Remove item ID from list of Shape Ids
        const index = this.shapeIDs.indexOf(itemId);
        if (index === -1) {
          throw new Error(`Shape ID not found ${itemId}`);
        }
        this.shapeIDs.splice(index, 1);

        this.emit(SimulatedPlanEvents.ItemRemoved, GardenItemType.Shape, itemId);
        break;
      }
      case GardenItemType.Text: {
        this.#text[itemId].off(SimulatedGardenItemEvent.DidUpdate, this.#itemDidUpdate);
        delete this.#text[itemId];
        delete this.itemTypes[itemId];

        // Remove item ID from list of Text Ids
        const index = this.textIds.indexOf(itemId);
        if (index === -1) {
          throw new Error(`Text ID not found ${itemId}`);
        }
        this.textIds.splice(index, 1);

        this.emit(SimulatedPlanEvents.ItemRemoved, GardenItemType.Text, itemId);
        break;
      }
      case undefined:
        console.error(`Attempted to remove item from simulated plan which doesn't exist: planID: ${this.#id}, itemId: ${itemId}`);
        break;
      default:
        console.error(`Attempted to remove item from simulated plan which has type but doesn't exist: planID: ${this.#id}, itemId: ${itemId}`);
    }
  }

  getItem<T extends GardenItemType>(itemId: number, type: T): GardenItemTypeMapping[T] | null {
    if (this.itemTypes[itemId] !== type) {
      return null;
    }
    switch (type) {
      case GardenItemType.Plant:
        return this.plants[itemId] as GardenItemTypeMapping[T];
      case GardenItemType.GardenObject:
        return this.gardenObjects[itemId] as GardenItemTypeMapping[T];
      case GardenItemType.Shape:
        return this.shapes[itemId] as GardenItemTypeMapping[T];
      case GardenItemType.Text:
        return this.text[itemId] as GardenItemTypeMapping[T];
      default:
        return null;
    }
  }

  updateCollisions() {
    const updatedItemIds = this.#collisionWorld.update();
    for (let i = 0; i < updatedItemIds.length; i++) {
      const collider = this.#collisionWorld.getCollider(updatedItemIds[i]);
      if (collider) {
        this.#itemDidUpdate(collider.owner.type, collider.owner.id);
      }
    }
  }
}
