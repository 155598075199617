import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect, { MenuPlacement } from 'react-select';

import * as CanvasSelectors from '../../redux-components/canvas-selectors';
import * as CanvasActionCreators from '../../redux-components/canvas-action-creators';
import { getTopbarSelectStyles } from '../select-styles';

import './month-selector.scss';

type MonthSelectorOption = {
  value: null | number;
  label: string;
};

const MONTHS: MonthSelectorOption[] = [
  { label: 'All Months', value: null },
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

const SH_MONTHS: MonthSelectorOption[] = [
  { label: 'All Months', value: null },
  { label: 'July', value: 0 },
  { label: 'August', value: 1 },
  { label: 'September', value: 2 },
  { label: 'October', value: 3 },
  { label: 'November', value: 4 },
  { label: 'December', value: 5 },
  { label: 'January', value: 6 },
  { label: 'February', value: 7 },
  { label: 'March', value: 8 },
  { label: 'April', value: 9 },
  { label: 'May', value: 10 },
  { label: 'June', value: 11 },
];

const getMonthFromValue = (val, northernHemisphere) => {
  if (val === null) {
    return MONTHS[0].label;
  }

  if (northernHemisphere) {
    return MONTHS[val + 1].label;
  }

  return SH_MONTHS[val + 1].label;
};

const SELECT_STYLES = getTopbarSelectStyles(134);
const FULLWIDTH_SELECT_STYLES = {
  ...SELECT_STYLES,
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
};

interface iProps {
  disabled?: boolean;
  northernHemisphere: boolean;
  fullWidth?: boolean;
  menuPlacement?: MenuPlacement;
  attachToBody?: boolean;
  id?: string;
  identifier?: string;
}

const MonthSelector = ({ disabled = false, northernHemisphere, fullWidth, menuPlacement, attachToBody = false, id, identifier }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const plan = useSelector(CanvasSelectors.getActivePlan);

  // Get current set month
  const month = plan === null ? null : plan.plannerSettings.month;

  const setMonth = useCallback(
    (newMonth: null | number) => {
      if (plan !== null) {
        dispatch(CanvasActionCreators.setMonth(plan, newMonth));
      }
    },
    [plan]
  );

  const onNext = useCallback(() => {
    let nextMonth: number | null = month === null ? 0 : month + 1;
    if (nextMonth >= 12) {
      nextMonth = null;
    }
    setMonth(nextMonth);
  }, [setMonth, month]);

  const onPrevious = useCallback(() => {
    let nextMonth: number | null = month === null ? 0 : month - 1;
    if (nextMonth < 0) {
      nextMonth = null;
    }
    setMonth(nextMonth);
  }, [setMonth, month]);

  const monthValue = { label: getMonthFromValue(month, northernHemisphere), value: month };
  const months = northernHemisphere ? MONTHS : SH_MONTHS;

  const classNames = ['month-selector'];
  if (fullWidth) {
    classNames.push('full-width');
  }

  return (
    <div className={classNames.join(' ')} data-identifier={identifier}>
      <button
        type='button'
        disabled={disabled}
        className='month-button'
        onClick={onPrevious}
        data-identifier={identifier ? `${identifier}-previous` : undefined}
      >
        <i className='icon-previous' />
      </button>
      <ReactSelect<MonthSelectorOption>
        isDisabled={disabled}
        styles={fullWidth ? FULLWIDTH_SELECT_STYLES : SELECT_STYLES}
        options={months}
        value={monthValue}
        onChange={(e) => {
          if (e !== null) {
            setMonth(e.value);
          }
        }}
        menuPortalTarget={attachToBody ? document.body : undefined}
        menuPlacement={menuPlacement}
        inputId={id}
        isSearchable={false}
      />
      <button type='button' disabled={disabled} className='month-button' onClick={onNext} data-identifier={identifier ? `${identifier}-next` : undefined}>
        <i className='icon-next' />
      </button>
    </div>
  );
};

export default MonthSelector;
