import { StringTypeToType, Type } from './base-types';
import { ExpressionFunctions, FunctionReturnTypes } from './expression-functions';

export enum Operator {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanEqual = 'GreaterThanEqual',
  LessThan = 'LessThan',
  LessThanEqual = 'LessThanEqual',
  Or = 'Or',
  XOr = 'XOr',
  And = 'And',
  Add = 'Add',
  Subtract = 'Subtract',
  Multiply = 'Multiply',
  Divide = 'Divide',
  Power = 'Power',
  Modulo = 'Modulo',
  Function = 'Function',
}

const OPERATOR_LIST = [
  Operator.Equal,
  Operator.NotEqual,
  Operator.GreaterThan,
  Operator.GreaterThanEqual,
  Operator.LessThan,
  Operator.LessThanEqual,
  Operator.Or,
  Operator.XOr,
  Operator.And,
  Operator.Add,
  Operator.Subtract,
  Operator.Multiply,
  Operator.Divide,
  Operator.Power,
  Operator.Modulo,
  Operator.Function,
] as const;

const ALL_PRIMITIVE_TYPES: Type[] = [Type.Undefined, Type.Null, Type.String, Type.Number, Type.Boolean] as const;

// Valid operand types for operations, most have same arguments for each operation
// but can be split to lh and rh
const OperatorValidTypes: Record<Operator, Type[] | { lh: Type[]; rh: Type[] }> = {
  [Operator.Equal]: ALL_PRIMITIVE_TYPES,
  [Operator.NotEqual]: ALL_PRIMITIVE_TYPES,
  [Operator.GreaterThan]: [Type.Number],
  [Operator.GreaterThanEqual]: [Type.Number],
  [Operator.LessThan]: [Type.Number],
  [Operator.LessThanEqual]: [Type.Number],
  [Operator.Or]: ALL_PRIMITIVE_TYPES,
  [Operator.And]: ALL_PRIMITIVE_TYPES,
  [Operator.XOr]: ALL_PRIMITIVE_TYPES,
  [Operator.Add]: [Type.Number],
  [Operator.Subtract]: [Type.Number],
  [Operator.Multiply]: [Type.Number],
  [Operator.Divide]: [Type.Number],
  [Operator.Power]: [Type.Number],
  [Operator.Modulo]: [Type.Number],
  [Operator.Function]: { lh: [Type.String], rh: [Type.Array] },
} as const;

const OperatorReturnTypes: Omit<Record<Operator, Type>, Operator.Function> = {
  [Operator.Equal]: Type.Boolean,
  [Operator.NotEqual]: Type.Boolean,
  [Operator.GreaterThan]: Type.Boolean,
  [Operator.GreaterThanEqual]: Type.Boolean,
  [Operator.LessThan]: Type.Boolean,
  [Operator.LessThanEqual]: Type.Boolean,
  [Operator.Or]: Type.Boolean,
  [Operator.XOr]: Type.Boolean,
  [Operator.And]: Type.Boolean,
  [Operator.Add]: Type.Number,
  [Operator.Subtract]: Type.Number,
  [Operator.Multiply]: Type.Number,
  [Operator.Divide]: Type.Number,
  [Operator.Power]: Type.Number,
  [Operator.Modulo]: Type.Number,
};

// Strings that can be used in place of each operator enum string as shorthand
const OperatorShortcuts: Record<Operator, string[]> = {
  [Operator.Equal]: ['='],
  [Operator.GreaterThan]: ['>'],
  [Operator.GreaterThanEqual]: ['>='],
  [Operator.LessThan]: ['<'],
  [Operator.LessThanEqual]: ['<='],
  [Operator.Or]: ['||'],
  [Operator.And]: ['&&'],
  [Operator.NotEqual]: ['!='],
  [Operator.XOr]: [],
  [Operator.Add]: ['+'],
  [Operator.Subtract]: ['-'],
  [Operator.Multiply]: ['*'],
  [Operator.Divide]: ['/'],
  [Operator.Power]: ['^', '**'],
  [Operator.Modulo]: ['%'],
  [Operator.Function]: ['fn', 'function'],
};

// Map, string operator -> Operator
const OPERATOR_MAP: Record<string, Operator> = {};
OPERATOR_LIST.forEach((operator) => {
  OPERATOR_MAP[operator] = operator;
  OperatorShortcuts[operator].forEach((shortcut) => {
    OPERATOR_MAP[shortcut] = operator;
  });
});

/**
 * Expression value type causes issues with Immer when nesting infinitely
 */
export type BaseExpressionValue = number | string | boolean | undefined | null | InputExpression;
export type ExpressionValueD1 = BaseExpressionValue | BaseExpressionValue[];
export type ExpressionValueD2 = ExpressionValueD1 | ExpressionValueD1[];
export type ExpressionValueD3 = ExpressionValueD2 | ExpressionValueD2[];
export type ExpressionValue = ExpressionValueD3 | ExpressionValueD3[];

/**
 * This type doesn't work, see above
 */
// export type ExpressionValue = BaseExpressionValue | ExpressionValue[];

export interface Expression {
  op: Operator;
  lh: ExpressionValue;
  rh: ExpressionValue;
}

export type InputExpression = {
  op: string;
  lh: ExpressionValue;
  rh: ExpressionValue;
};

export type ExpectedExpression = unknown;

// Describes how variables too pull from the state are defined
const VARIABLE_REGEX = /^var:/;

function convertInputExpressionToExpression(inputExpression: InputExpression): Expression {
  if (!OPERATOR_MAP[inputExpression.op]) {
    throw new Error(`Invalid operator ${inputExpression.op}`);
  }

  return {
    ...inputExpression,
    op: OPERATOR_MAP[inputExpression.op],
  };
}

/**
 * Takes a value and returns it along with an associated type, if it's just a static value it will simply be returned
 * but this function will also evaluate expression values and resolve variable values with the provided state
 */
function parseValue(value: ExpressionValue, state: Record<string, any> = {}): [unknown, Type] {
  const type = typeof value;

  if (value === null) {
    return [value, Type.Null];
  }

  if (type === 'object') {
    if (Array.isArray(value)) {
      // Each individual value needs to be parsed in case it is an expression
      // that needs to be
      return [value.map((_value) => parseValue(_value, state)[0]), Type.Array];
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return ExpressionUtils.evaluateExpectedExpression(value, state);
  }

  if (type === 'number' || type === 'boolean' || type === 'undefined') {
    return [value, StringTypeToType[type] as Type];
  }

  if (type === 'string') {
    if (VARIABLE_REGEX.test(value as string)) {
      const stateKey = (value as string).substring(4);
      if (stateKey in state) {
        const variable = state[stateKey];
        const variableType = Array.isArray(variable) ? Type.Array : StringTypeToType[typeof variable];

        // Catch edge case which arises from type of null being object
        if (!variableType || (variableType === Type.Null && variable !== null)) {
          throw new Error(`Invalid variable type ${typeof variable}`);
        }

        return [variable, variableType];
      }
      throw new Error(`Invalid variable, property ${stateKey} not found`);
    }

    return [value, Type.String];
  }

  throw new Error(`Invalid type ${type}`);
}

/**
 * Checks that a value is of the given type and returns the value, otherwise throws an error
 */
function checkValueType<ValueType>([value, type]: [ValueType, Type], operator: Operator, side: 'lh' | 'rh'): ValueType {
  const opTypes = OperatorValidTypes[operator];
  if (Array.isArray(opTypes)) {
    if (opTypes.includes(type)) {
      return value;
    }
  } else if (opTypes[side].includes(type)) {
    return value;
  }

  throw new Error(`Type ${type} invalid for operator ${operator}`);
}

/**
 * Calls a function with the give name and arguments, returns the value the function returns along with the Type this function
 * returns (the type lookup comes from a map of the function name -> Type and is not based on parsing the return value type)
 */
function resolveFunctionExpression(functionName: string, functionArgs: unknown[]): [unknown, Type] {
  if (functionName in ExpressionFunctions) {
    const result = ExpressionFunctions[functionName](...functionArgs);
    const type = FunctionReturnTypes[functionName];
    return [result, type];
  }

  throw new Error('Invalid function, not recognised');
}

type ExpressionEvaluationSuccess = {
  success: true;
  result: [unknown, Type];
};

type ExpressionEvaluationFail = {
  success: false;
  error: string;
};

export type ExpressionResult = ExpressionEvaluationSuccess | ExpressionEvaluationFail;

export class ExpressionUtils {
  /**
   * Evaluates an expression, including any nested expressions and returns the result value and it's type
   */
  static evaluateExpression(expression: Expression, state: Record<string, any> = {}): [unknown, Type] {
    const { op, lh, rh } = expression;

    const lhValue = checkValueType(parseValue(lh, state), op, 'lh');
    const rhValue = checkValueType(parseValue(rh, state), op, 'rh');

    if (op === Operator.Function) {
      return resolveFunctionExpression(lhValue as string, rhValue as unknown[]);
    }

    const returnType = OperatorReturnTypes[op];

    switch (op) {
      case Operator.Equal:
        // TODO, evaluate == / === here
        return [lhValue === rhValue, returnType];
      case Operator.NotEqual:
        // TODO, evaluate != / !== here
        return [lhValue !== rhValue, returnType];
      case Operator.GreaterThan:
        return [(lhValue as number) > (rhValue as number), returnType];
      case Operator.GreaterThanEqual:
        return [(lhValue as number) >= (rhValue as number), returnType];
      case Operator.LessThan:
        return [(lhValue as number) < (rhValue as number), returnType];
      case Operator.LessThanEqual:
        return [(lhValue as number) <= (rhValue as number), returnType];
      case Operator.Or:
        return [!!lhValue || !!rhValue, returnType];
      case Operator.XOr:
        // TODO, check this case
        return [!!lhValue !== !!rhValue, returnType];
      case Operator.And:
        return [!!lhValue && !!rhValue, returnType];
      case Operator.Add:
        return [(lhValue as number) + (rhValue as number), returnType];
      case Operator.Subtract:
        return [(lhValue as number) - (rhValue as number), returnType];
      case Operator.Multiply:
        return [(lhValue as number) * (rhValue as number), returnType];
      case Operator.Divide:
        return [(lhValue as number) / (rhValue as number), returnType];
      case Operator.Power:
        return [(lhValue as number) ** (rhValue as number), returnType];
      case Operator.Modulo:
        return [(lhValue as number) % (rhValue as number), returnType];
      default:
        throw new Error(`Operator not recognised: ${op}`);
    }
  }

  /**
   * Validates the expression is an InputExpression
   *
   * Returns the expression if valid, throws an error otherwise
   */
  static convertExpectedExpressionToInputExpression(expression: ExpectedExpression): InputExpression {
    ExpressionUtils.validateIsInputExpression(expression);
    return expression;
  }

  /**
   * Throws an error if the provided expression does not match the InputExpression type
   */
  static validateIsInputExpression(expression: unknown): asserts expression is InputExpression {
    if (typeof expression !== 'object') {
      throw new Error(`Invalid expression type`);
    }

    if (expression === null) {
      throw new Error(`Invalid expression; is null`);
    }

    if (!('op' in expression) || !('lh' in expression) || !('rh' in expression)) {
      throw new Error(`Invalid expression structure`);
    }

    if (typeof expression.op !== 'string') {
      throw new Error(`Invalid operator type`);
    }

    if (!OPERATOR_MAP[expression.op]) {
      throw new Error(`Invalid operator ${expression.op}`);
    }
  }

  /**
   * Safer version of evaluateExpression which deals with unknown types
   */
  static evaluateExpectedExpression(expectedExpression: ExpectedExpression, state: Record<string, any> = {}): [unknown, Type] {
    ExpressionUtils.validateIsInputExpression(expectedExpression); // Makes sure ExpectedExpression is InputExpression
    const expression = convertInputExpressionToExpression(expectedExpression); // Converts InputExpression to Expression (converts string operator to Operator)

    return ExpressionUtils.evaluateExpression(expression, state);
  }

  /**
   * Evaluates an ExpectedExpression ({unknown}) and returns an result
   *
   * Catches errors thrown by expression parsing and adds them into the result on failure
   */
  static getExpressionResult(expression: ExpectedExpression, state: Record<string, any> = {}): ExpressionResult {
    try {
      return { success: true, result: ExpressionUtils.evaluateExpectedExpression(expression, state) };
    } catch (e: unknown) {
      if (e instanceof Error) {
        return {
          success: false,
          error: e.message,
        };
      }

      return {
        success: false,
        error: 'Unknown Error',
      };
    }
  }

  static getExpectedBooleanExpressionResult(expression: Readonly<ExpectedExpression>, state: Readonly<Record<string, any>> = {}): boolean {
    const result = ExpressionUtils.getExpressionResult(expression, state);

    if (result.success && result.result[1] === Type.Boolean) {
      return result.result[0] as boolean;
    }

    return false;
  }

  /**
   * Takes an array of expressions and joins them together into a single nested Expression, with either an AND or OR operator
   */
  static joinExpressions(expressions: InputExpression[], op: Operator.And | Operator.Or = Operator.And): InputExpression {
    return expressions.reduce((prev, curr) => {
      return { op, lh: prev, rh: curr };
    });
  }
}
