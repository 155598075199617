import React, { ReactNode } from 'react';

import './drawer-content.scss';

interface iProps {
  children: ReactNode;
  className?: string;
  padX?: boolean;
  padY?: boolean;
  padTop?: boolean;
  padBottom?: boolean;
  fillHeight?: boolean;
  overflowHidden?: boolean;
}

const DrawerContent = ({
  children,
  className,
  padX = false,
  padY = false,
  padTop = false,
  padBottom = false,
  fillHeight = false,
  overflowHidden = false,
  ...remainingProps
}: iProps): JSX.Element => {
  const classNames = ['drawer-content'];
  if (padX) {
    classNames.push('pad-x');
  }
  if (padY) {
    classNames.push('pad-y');
  }
  if (padTop) {
    classNames.push('pad-top');
  }
  if (padBottom) {
    classNames.push('pad-bottom');
  }
  if (fillHeight) {
    classNames.push('fill-height');
  }
  if (overflowHidden) {
    classNames.push('overflow-hidden');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...remainingProps}>
      {children}
    </div>
  );
};

export default DrawerContent;
