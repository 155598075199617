import { useCallback, useContext, useMemo } from 'react';

import { TutorialContext } from './tutorial-context';
import { TutorialsUtils } from './tutorials';

type UseTutorialProps = {
  uuid?: string;
  name?: string;
};

export const useTutorial = (props: UseTutorialProps) => {
  const { tutorials, runningTutorial, startTutorial } = useContext(TutorialContext);

  const tutorial = useMemo(() => {
    if (tutorials === null) {
      return null;
    }

    // Search by ID if ID is specified
    if ('uuid' in props && props.uuid !== undefined) {
      return TutorialsUtils.getTutorial(tutorials, props.uuid);
    }

    // Search by name a displayMode if name is specified
    if ('name' in props && props.name !== undefined) {
      return TutorialsUtils.getTutorialByName(tutorials, props.name);
    }

    // No way to match
    return null;
  }, [tutorials, props]);

  const start = useCallback(() => {
    if (tutorial && tutorial.enabled) {
      startTutorial(tutorial);
    }
  }, [startTutorial, tutorial]);

  const isRunning = useMemo(() => {
    if (tutorial === null || runningTutorial === null) {
      return false;
    }
    return runningTutorial.tutorial === tutorial;
  }, [tutorial, runningTutorial]);

  return useMemo(
    () => ({
      tutorial,
      start,
      isRunning,
    }),
    [tutorial, start, isRunning]
  );
};
