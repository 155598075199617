import React, { useContext } from 'react';

import { TooltipKey } from '@gi/tooltip';

import { PlannerCommandContext } from '../../../planner-command-provider';
import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuButton from '../common/menu-button';
import FileMenuButtonGroup from '../common/parts/file-menu-button-group';

interface iProps {
  hideFileControls?: boolean;
}

const PlantListControls = ({ hideFileControls }: iProps): JSX.Element => {
  const { printPlantList } = useContext(PlannerCommandContext);

  return (
    <ControlGroup>
      <ControlRow>
        {!hideFileControls ? <FileMenuButtonGroup /> : null}
        <ControlButtonGroup>
          <MenuButton id='print-plan' onClick={printPlantList} tooltipKey={TooltipKey.PrintPlantList}>
            <i className='icon-print' />
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default PlantListControls;
