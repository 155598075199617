import { useContext, useEffect, useMemo, useState } from 'react';
import { ExpectedExpression, ExpressionUtils } from '@gi/expression';
import { GardenPlatformState, GardenPlatformStateKeys } from './garden-platform-state';
import { GardenPlatformStateContext } from './garden-platform-state-context';
import {} from '@gi/expression/source/expression';

export function useWatchedPlatformState<K extends (keyof GardenPlatformState)[] = typeof GardenPlatformStateKeys>(
  props?: Readonly<K>
): Readonly<GardenPlatformState> {
  const { watch, query } = useContext(GardenPlatformStateContext);

  const [watchedState, setWatchedState] = useState(query(props));

  useEffect(() => {
    const removeListener = watch((state) => {
      setWatchedState({ ...state });
    }, props);

    return removeListener;
  }, [props]);

  return watchedState;
}

function getValidStateProps(expressionStateProperties: string[]): (keyof GardenPlatformState)[] {
  return expressionStateProperties.filter((prop) => GardenPlatformStateKeys.includes(prop as keyof GardenPlatformState)) as (keyof GardenPlatformState)[];
}

export function useWatchedExpression(expression: ExpectedExpression, expressionStateProperties?: string[]) {
  const stateKeys = expressionStateProperties ? getValidStateProps(expressionStateProperties) : undefined;

  const watchedState = useWatchedPlatformState(stateKeys);

  const result = useMemo(() => {
    return ExpressionUtils.getExpressionResult(expression, watchedState);
  }, [watchedState]);

  return result;
}
