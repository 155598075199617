import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GardenPlatformStateContext } from '@gi/garden-platform-state';

import { ObjectivesActionCreators, ObjectivesSelectors } from '../slice/objectives-slice';

const ObjectivesInitialiser = (): null => {
  const dispatch = useDispatch();
  const { watch, query } = useContext(GardenPlatformStateContext);

  // We only want the initial state here incase initialised is already true, the watch function will update it after that
  const globalState = useMemo(() => {
    return query();
  }, []);
  const [localInitialised, setLocalInitialised] = useState<boolean>(globalState.initialised);

  useEffect(() => {
    const removeWatcher = watch(
      ({ initialised }) => {
        setLocalInitialised(initialised);
      },
      ['initialised']
    );

    return removeWatcher;
  }, []);

  useEffect(() => {
    if (localInitialised) {
      dispatch(ObjectivesActionCreators.initialise(query()));
    }
  }, [localInitialised]);

  return null;
};

/**
 * Simple wrapper so once initialisation has been completed we don't need
 * the logic of the initialisation component anymore
 */
const ObjectivesInitialiserWrapper = (): JSX.Element | null => {
  const initialised = useSelector(ObjectivesSelectors.getInitialised);

  if (initialised) {
    return null;
  }

  return <ObjectivesInitialiser />;
};

export default ObjectivesInitialiserWrapper;
