import React, { ReactNode, useMemo } from 'react';

import styles from './mobile-plan-menu-button.module.css';

interface iProps {
  leftIcon?: string | ReactNode;
  rightIcon?: string | ReactNode;
  disabled?: boolean;
  primary?: boolean;
  loading?: boolean;
  onClick: () => void;
  children: ReactNode;
  className?: string;
  identifier?: string;
}

const MobilePlanMenuButton = ({ leftIcon, rightIcon, disabled, primary, loading, onClick, children, className, identifier }: iProps): JSX.Element => {
  const leftIconElem = useMemo(() => {
    if (!leftIcon) {
      return null;
    }
    if (loading) {
      return <i className='icon-spinner animate-pulse' />;
    }
    if (typeof leftIcon === 'string') {
      return <i className={leftIcon} />;
    }
    return <span className={styles.iconContainer}>{leftIcon}</span>;
  }, [leftIcon, loading]);

  const rightIconElem = useMemo(() => {
    if (!rightIcon) {
      return null;
    }
    if (loading) {
      return <i className='icon-spinner animate-pulse' />;
    }
    if (typeof rightIcon === 'string') {
      return <i className={rightIcon} />;
    }
    return <span className={styles.iconContainer}>{rightIcon}</span>;
  }, [rightIcon, loading]);

  const classNames = [styles.button];
  if (primary) {
    classNames.push(styles.primary);
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <button className={classNames.join(' ')} type='button' onClick={onClick} disabled={disabled} data-identifier={identifier}>
      {leftIconElem}
      <span>{children}</span>
      {rightIconElem}
    </button>
  );
};

export default MobilePlanMenuButton;
