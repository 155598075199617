import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';

import { attachGetTutorialsErrorClientMessage } from './tutorial-service-errors';
import { parseTutorials } from '../tutorials-parser';
import { APITutorials } from '../tutorial-data';
import { Tutorials } from '../tutorials';

class TutorialService {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async getTutorials(): Promise<Tutorials> {
    const url = this.endpoint;

    return networkService
      .get<APITutorials>(url, {}, RequestAuthMode.None)
      .then((response) => parseTutorials(response.body))
      .catch(networkErrorReporter('GET', 'tutorials'))
      .catch((requestError) => {
        attachGetTutorialsErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default TutorialService;
