import { DeviceDisplayMode, PlannerTabTypes } from '@gi/constants';
import { Application } from '@gi/garden-platform-navigation';

export type GardenPlatformState = {
  activePlanId: number | null;
  activePlanTab: PlannerTabTypes | null; // Currently active tab in the Garden Planner
  activeApplication: Application | null; // Currently active application (Garden Planner, Journal etc)
  displayMode: DeviceDisplayMode; // Current device display mode
  featureFlags: string[]; // Currently enabled feature flags
  initialised: boolean;
};

export const GardenPlatformStateKeys: (keyof GardenPlatformState)[] = [
  'activePlanId',
  'activePlanTab',
  'activeApplication',
  'displayMode',
  'featureFlags',
  'initialised',
] as const;
