import React from 'react';
import { GardenPlatformStateKeys, useWatchedPlatformState } from '@gi/garden-platform-state';
import { ModalPane, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';

const PlatformStateTab = (): JSX.Element => {
  const values = useWatchedPlatformState(GardenPlatformStateKeys);

  return (
    <ModalPaneContainer>
      <ModalPane>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <table>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(values).map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{values[key] === undefined ? 'undefined' : JSON.stringify(values[key])}</td>
                    <td>{typeof values[key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalPaneSectionContent>
        </ModalPaneSection>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default PlatformStateTab;
