import { ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import React from 'react';

interface iProps {
  error: string;
}

const ExpressionError = ({ error }: iProps): JSX.Element => {
  return (
    <ModalPaneSection>
      <ModalPaneSectionContent>
        <div>Error</div>
        <div>{error}</div>
      </ModalPaneSectionContent>
    </ModalPaneSection>
  );
};
export default ExpressionError;
