import React, { useContext } from 'react';
import { ModalPane, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { TutorialContext } from '@gi/tutorial';
import TutorialDetails from './tutorial-details';

const TutorialsTab = (): JSX.Element => {
  const { tutorials } = useContext(TutorialContext);

  return (
    <ModalPaneContainer>
      <ModalPane>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <table>
              <thead>
                <tr>
                  <th>UUID</th>
                  <th>Name</th>
                  <th>Controls</th>
                </tr>
              </thead>
              <tbody>
                {tutorials.uuids.map((uuid) => {
                  const tutorial = tutorials.byUuid[uuid];
                  return (
                    <tr key={uuid}>
                      <td>{uuid}</td>
                      <td>{tutorial.data.name}</td>
                      <td>
                        <TutorialDetails tutorial={tutorial} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </ModalPaneSectionContent>
        </ModalPaneSection>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default TutorialsTab;
