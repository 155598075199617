import React, { useContext, useMemo } from 'react';

import GardenObject, { GardenObjectGroup } from '@gi/garden-object';
import PlannerIcon from '@gi/planner-icon';
import { ResourceContext } from '@gi/resource-provider';

import { DrawingToolsContext } from '../drawing-tools-context';

import styles from './garden-object-group-list-item.module.css';

interface iProps {
  gardenObjectGroup: GardenObjectGroup;
  expanded?: boolean;
  gardenObjects: GardenObject[];
  style?: React.CSSProperties;
}

const GardenObjectGroupListItem = ({ gardenObjectGroup, gardenObjects, expanded = false, style }: iProps): JSX.Element => {
  const { toggleGardenObjectGroup } = useContext(DrawingToolsContext);
  const { getGardenObject, store } = useContext(ResourceContext);

  const extraIcons = useMemo(() => {
    const allObjects = gardenObjectGroup.objectCodes.map(getGardenObject);

    const isSeasonExtender = allObjects.every((object) => object !== null && object.plantModifier !== null);
    const isPurchasable = allObjects.every((object) => object && store && store.products.productsByCode[object.code]);

    return (
      <>
        {isSeasonExtender ? <div className='extra-icon'>Season Extender</div> : null}
        {isPurchasable ? (
          <div className='extra-icon'>
            <i className='icon-shopping-cart' />
          </div>
        ) : null}
      </>
    );
  }, [gardenObjectGroup.objectCodes]);

  const classNames: string[] = ['group-option', styles.groupItem];
  if (expanded) {
    classNames.push(styles.expanded);
  }

  return (
    <li className={classNames.join(' ')} style={style} data-group={gardenObjectGroup.displayName} data-identifier='garden-object-group'>
      <button
        type='button'
        className={styles.groupItemButton}
        onClick={() => toggleGardenObjectGroup(gardenObjectGroup.id)}
        data-identifier='selectable-garden-object-group-button'
      >
        <div className='icon-container'>
          <div className='selectable-garden-object-icon'>
            <PlannerIcon code={gardenObjectGroup.iconCode ?? gardenObjectGroup.objectCodes[0]} />
          </div>
        </div>
        <div className='item-name'>
          <span className='item-name-primary'>{gardenObjectGroup.displayName}</span>
          <span className='item-amount'>
            {gardenObjects.length} {gardenObjectGroup.variantText ?? 'variants'}
          </span>
        </div>
        <div className={styles.icon}>
          <i className='icon-down-open-1' />
        </div>
        <div className='extra-icon-container'>{extraIcons}</div>
      </button>
    </li>
  );
};

export default GardenObjectGroupListItem;
