import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import ModalType from './modal-types';

import './modal.scss';

const ESC_KEYCODE = 27;

const modalRoot = document.getElementById('modal-root');

interface iProps {
  children: React.ReactNode;
  type?: ModalType;
  className?: string;
  innerClassName?: string;
  closeRequest: () => void;
  style?: React.CSSProperties;
}

/**
 * Base Modal Component
 *
 * Will need to be used in some siturations to avoid dependency cycles (can't use a modal inside modal context)
 */
const ModalWithoutContext = ({ type = ModalType.Default, className = '', innerClassName, children, closeRequest, style = {} }: iProps): JSX.Element => {
  const [el] = useState<HTMLDivElement>(document.createElement('div'));

  // Create a ref for closeRequest as the keypress callback
  // doesn't get an updated version as it's only assigned on
  // component mount
  const closeRequestRef = useRef(closeRequest);
  closeRequestRef.current = closeRequest;

  const onKeypress = (event) => {
    // Close modal on escape keypress
    if (event.keyCode === ESC_KEYCODE) {
      closeRequestRef.current();
    }
  };

  const onOuterModalClick = (e) => {
    // Close modal on click in outside area
    // Make an exception when the user has text selected, as selecting text in the modal often results
    // in a mouseup outside the modal area which then annoyingly closes the modal
    // TODO: A better solution may be to track mousedown/mouseup and only attempt to close if both the mouseup and mousedown occured outside the modal area
    const selection = document.getSelection();
    if (e.target === e.currentTarget && selection !== null && selection.toString().length === 0) {
      closeRequest();
    }
  };

  useEffect(() => {
    if (modalRoot !== null) {
      modalRoot.appendChild(el);
    }
    document.addEventListener('keydown', onKeypress, false);

    return () => {
      if (modalRoot !== null) {
        modalRoot.removeChild(el);
      }
      document.removeEventListener('keydown', onKeypress, false);
    };
  }, []);

  let _className = `outer-modal ${className}`;

  if (type === ModalType.Warning) {
    _className += ' modal-warning';
  } else if (type === ModalType.Error) {
    _className += ' modal-error';
  }

  let _innerClassName = 'inner-modal';
  if (innerClassName) {
    _innerClassName += ` ${innerClassName}`;
  }

  const modalContent = (
    <div className={_className}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className='outer-modal-inner' onClick={onOuterModalClick}>
        <div className={_innerClassName} style={style}>
          {children}
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, el);
};

export default ModalWithoutContext;
