import React, { useContext, useEffect, useRef, useState } from 'react';

import { DrawingToolsContext, GardenObjectFilterComponents, PUNCTUATION_REGEX } from '@gi/drawing-tools';
import { updateFilters } from '@gi/filters';
import { ResourceContext } from '@gi/resource-provider';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import Searchbar from '../searchbar';

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  container: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    maxWidth: '50%',
    minWidth: 'unset',
  }),
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: 'unset',
    height: '100%',
    background: '#f2f2f2',
    border: 'none',
  }),
};

const TIMEOUT_TIME = 150;

const GardenObjectsDrawerSearchbar = (): JSX.Element => {
  const { gardenObjectSearchService } = useContext(ResourceContext);
  const { gardenObjectFilters, setGardenObjectFilters } = useContext(DrawingToolsContext);

  const [searchTerm, setSearchTerm] = useState<string>(gardenObjectFilters.filters.search.inputs.searchTerm);
  const [lastCommittedSearchTerm, setLastCommittedSearchTerm] = useState<string>(gardenObjectFilters.filters.search.inputs.searchTerm);

  const searchTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    // Check if the search term in the filters has updated without us asking to.
    // If so, update our copy of the search term to match (the filter was probably reset).
    const searchTermFromFilter = gardenObjectFilters.filters.search.inputs.searchTerm;
    if (searchTermFromFilter !== lastCommittedSearchTerm) {
      setSearchTerm(searchTermFromFilter);
      setLastCommittedSearchTerm(searchTermFromFilter);
    }
  }, [gardenObjectFilters.filters.search.inputs.searchTerm]);

  useEffect(() => {
    const searchUpdateTimeout = () => {
      setLastCommittedSearchTerm(searchTerm);
      setGardenObjectFilters(
        updateFilters(gardenObjectFilters, {
          search: {
            searchTerm,
            searchResults: gardenObjectSearchService.search(searchTerm.replaceAll(PUNCTUATION_REGEX, '')),
          },
          sort: {
            enabled: searchTerm.length <= 1,
          },
        })
      );
    };

    clearTimeout(searchTimeout.current!);
    searchTimeout.current = setTimeout(searchUpdateTimeout, TIMEOUT_TIME);

    return () => {
      clearTimeout(searchTimeout.current!);
    };
  }, [searchTerm]);

  const setTypeFilter = (type: string) => {
    setGardenObjectFilters(
      updateFilters(gardenObjectFilters, {
        type: { type },
      })
    );
  };

  return (
    <Searchbar
      value={searchTerm}
      onChange={(value) => setSearchTerm(value)}
      identifier='drawing-tools-garden-object-search-input'
      filterComponent={
        <GardenObjectFilterComponents.TypeFilterDropdown
          type={gardenObjectFilters.filters.type.inputs.type}
          onTypeChange={setTypeFilter}
          menuPlacement='auto'
          id='type'
          styles={SELECT_STYLES}
        />
      }
    />
  );
};

export default GardenObjectsDrawerSearchbar;
