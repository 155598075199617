import GardenObject from '@gi/garden-object';

import { SearchDisplayMode } from './search-result';
import { SearchService } from './search-service';

export class GardenObjectSearchService extends SearchService<GardenObject> {
  constructor(itemList: GardenObject[], options?: Fuzzysort.Options) {
    super(
      itemList,
      ['name', 'otherNames', 'searchNames'],
      {
        name: {
          weight: 1,
          processBrackets: true,
          processBracketsWeightFactor: 0.25,
        },
        otherNames: {
          weight: 0.5,
          displayMode: SearchDisplayMode.Secondary,
        },
        searchNames: {
          weight: 0.2,
          displayMode: SearchDisplayMode.Hidden,
        },
      },
      options
    );
  }
}
