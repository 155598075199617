import React, { useContext, useEffect, useState } from 'react';

import styles from './tutorial-editor.module.css';
import { TutorialEditorContext } from './tutorial-editor-context';
import EditorJsonInput from './editor-json-input/editor-json-input';
import EditorJsonInputControls from './editor-json-input/editor-json-input-controls';
import JSONInputErrors from './editor-json-input/json-input-errors';

interface iProps {
  className?: string;
}

const EditorEditor = ({ className = '' }: iProps): JSX.Element => {
  const { editingTutorialJSON } = useContext(TutorialEditorContext);
  const [jsonString, _setJsonString] = useState<string>('');
  const [jsonStringModified, setJsonStringModified] = useState(false);

  const setJsonString = (jsonStr: string) => {
    _setJsonString(jsonStr);
    setJsonStringModified(true);
  };

  useEffect(() => {
    _setJsonString(JSON.stringify(editingTutorialJSON, undefined, 2));
    setJsonStringModified(false);
  }, [editingTutorialJSON]);

  return (
    <div className={`${styles.editorEditor} ${styles.editorPane} ${className}`}>
      <EditorJsonInputControls jsonString={jsonString} jsonStringModified={jsonStringModified} />
      <EditorJsonInput jsonString={jsonString} setJsonString={setJsonString} />
      <JSONInputErrors />
    </div>
  );
};

export default EditorEditor;
