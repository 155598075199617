import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PlannerSelectors } from '@gi/app-planner-slice';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { PlannerTabTypes } from '@gi/constants';
import { NavigationContext } from '@gi/garden-platform-navigation';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { FeaturesContext } from '@gi/features';
import { GardenPlatformStateContext } from './garden-platform-state-context';

/**
 * Main component for keeping the PlatformStateProvider up-to-date with the
 * current individual states of different platform systems
 */
const PlatformStateUpdater = (): null => {
  const { set } = useContext(GardenPlatformStateContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const activePlanId = useSelector(CanvasSelectors.getActivePlanID);
  const openPlanStates = useSelector(PlannerSelectors.getOpenPlanStates);
  const displayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const { featureIds } = useContext(FeaturesContext);

  const { activeApplication } = useContext(NavigationContext);

  const activePlanTab = useMemo<PlannerTabTypes | null>(() => {
    if (activePlanId === null || activePlan === null) {
      return null;
    }

    if (!openPlanStates.planStates[activePlanId]) {
      return null;
    }

    return openPlanStates.planStates[activePlanId].activePlannerTab;
  }, [activePlan?.id, openPlanStates]);

  useEffect(() => {
    set({ activePlanTab, activePlanId, activeApplication, displayMode, featureFlags: featureIds });
  }, [activePlanTab, activePlanId, activeApplication, displayMode, featureIds]);

  useEffect(() => {
    set({ initialised: true });
  }, []);

  return null;
};

export default PlatformStateUpdater;
