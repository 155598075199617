import React, { useCallback, useEffect, useState } from 'react';
import { ModalPane, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { randomUUID } from '@gi/utils';

/**
 * Debug Modal Tab for providing a simple interface to get a random UUID
 */
const UUIDTab = (): JSX.Element => {
  const [uuid, setUuid] = useState(randomUUID());

  const [uuidSupported, setUuidSupported] = useState(true);

  useEffect(() => {
    try {
      crypto.randomUUID();
    } catch (e) {
      setUuidSupported(false);
    }
  }, []);

  const generateUuid = useCallback(() => {
    setUuid(randomUUID());
  }, []);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(uuid);
  }, [uuid]);

  return (
    <ModalPaneContainer>
      <ModalPane>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <div>
              <div>
                <button type='button' onClick={generateUuid}>
                  Generate
                </button>
              </div>
              <div>{uuid}</div>
              <button type='button' onClick={copyToClipboard}>
                Copy To Clipboard
              </button>
            </div>
            {!uuidSupported ? <div>Warning, Crypto randomUUID might not be supported in this browser</div> : null}
          </ModalPaneSectionContent>
        </ModalPaneSection>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default UUIDTab;
