import { createContext } from 'react';
import { GardenPlatformState } from './garden-platform-state';

export type QueryState<State extends object> = <Keys extends (keyof State)[]>(keys?: Readonly<Keys>) => Readonly<State>;
export type SetState<State extends object> = (values: Partial<State>) => void;
export type WatchState<State extends object> = <Keys extends (keyof State)[]>(callback: (values: Readonly<State>) => void, keys?: Readonly<Keys>) => () => void;

export type QueryPlatformState = QueryState<GardenPlatformState>;
export type SetPlatformState = SetState<GardenPlatformState>;
export type WatchPlatformState = WatchState<GardenPlatformState>;

export type GardenPlatformStateContextType = {
  query: QueryPlatformState;
  set: SetPlatformState;
  watch: WatchPlatformState;
};

export const GardenPlatformStateContext = createContext<GardenPlatformStateContextType>({} as GardenPlatformStateContextType);
