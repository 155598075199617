import React, { useContext } from 'react';
import { List, AutoSizer } from 'react-virtualized';

import { Anchor, Drawer, DrawerContent, DrawerHeader, DrawerSticky } from '@gi/mobile-components';
import { DrawingToolsContext, PlantListComponents } from '@gi/drawing-tools';

import SelectablePlantRow from './selectable-plant';
import PlantsDrawerSearchbar from './plants-drawer-searchbar';
import PlantsDrawerCompanions from './plants-drawer-companions';

interface iProps {
  open: boolean;
  onClose: () => void;
  anchor?: Anchor;
}

const PlantsDrawer = ({ open, onClose, anchor = 'BOTTOM' }: iProps): JSX.Element => {
  const { filteredPlants, plantFilters } = useContext(DrawingToolsContext);

  const companionsMode = plantFilters.filters.companion?.inputs?.enabled;

  const rowRenderer = ({ key, index, style }) => {
    const plant = filteredPlants[index];
    return <SelectablePlantRow key={key} plant={plant} style={style} onClick={() => onClose()} />;
  };

  return (
    <Drawer open={open} anchor={anchor} onClose={onClose} width={500}>
      <DrawerHeader as='h2' onClose={onClose}>
        {companionsMode ? 'Companions' : 'Add a Plant'}
      </DrawerHeader>
      <DrawerSticky padX padY border>
        {companionsMode ? <PlantsDrawerCompanions anchor={anchor} /> : <PlantsDrawerSearchbar anchor={anchor} />}
      </DrawerSticky>
      {filteredPlants.length > 0 ? (
        <DrawerContent fillHeight overflowHidden data-identifier='drawing-tools-plant-list'>
          <AutoSizer>
            {({ width, height }) => (
              <List scrollToIndex={0} width={width} height={height} rowCount={filteredPlants.length} rowHeight={60} rowRenderer={rowRenderer} />
            )}
          </AutoSizer>
        </DrawerContent>
      ) : (
        <DrawerContent fillHeight padX padY data-identifier='drawing-tools-plant-list'>
          <PlantListComponents.NoResults />
        </DrawerContent>
      )}
    </Drawer>
  );
};

export default PlantsDrawer;
