import React, { useContext } from 'react';
import { FeatureGate } from '@gi/features';
import { StatusBarSection } from '@gi/status-bar';

import { DebugModalContext } from '../debug-modal-context';
import FooterExpressionDisplay from './footer-expression-display';

const DebugModalLauncher = (): JSX.Element => {
  const { setOpen } = useContext(DebugModalContext);

  return (
    <FeatureGate featureId='DEBUG_MODAL'>
      <FooterExpressionDisplay />
      <StatusBarSection>
        <button type='button' onClick={() => setOpen(true)}>
          Debug
        </button>
      </StatusBarSection>
    </FeatureGate>
  );
};

export default DebugModalLauncher;
