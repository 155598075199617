import { Plan } from '../plan';
import { PlanGardenObject } from '../plan-garden-object';
import { PlanPlant } from '../plan-plant';
import { PlanShape } from '../plan-shape';
import { PlanText } from '../plan-text';
import {
  DEFAULT_PLAN_OPTIONS,
  DEFAULT_PLAN_PLANT_OPTIONS,
  OptionsFieldParser,
  PlanGardenItemOptions,
  PlanGardenObjectOptions,
  PlanOptions,
  PlanPlantOptions,
  PlanShapeOptions,
  PlanTextOptions,
  StringifiedOptionsField,
  prependSchemaVersion,
} from './plan-options-parser';

/** Version number of this schema */
const SCHEMA_VERSION = 1;

/**
 * Deserializes the `options` field content part, returning `{}` on error
 * @param optionsString The string in the `options` field from the API
 * @returns The deserialized JSON, or an empty object if error
 */
function deserializeOptionsString(optionsString: string) {
  try {
    const json = JSON.parse(optionsString);
    if (typeof json === 'object') {
      return json;
    }
    return {};
  } catch (e) {
    console.error(`Failed to deserialize options field: "${optionsString}"`);
    return {};
  }
}

/** Record of typeguards to check a JSON property is of a given type */
const PropertyTypeGuards = {
  string: (value: any): value is string => typeof value === 'string',
  number: (value: any): value is number => typeof value === 'number',
  boolean: (value: any): value is boolean => typeof value === 'boolean',
} as const satisfies Record<string, (value: any) => value is any>;

type GuardedType<T> = T extends (value: any) => value is infer R ? R : never;

/**
 * Gets a property from a JSON object, ensuring it is the expected type. Returns null if not found or wrong type.
 * @param json The json to extract from
 * @param property The property name to get
 * @param type The expected type of the property
 * @returns The property if found and valid, else null
 */
function getProperty<T extends keyof typeof PropertyTypeGuards>(
  json: Record<string, any>,
  property: string,
  type: T
): GuardedType<(typeof PropertyTypeGuards)[T]> | null {
  const value = json[property];
  const typeGuard = PropertyTypeGuards[type];
  if (value !== undefined && typeGuard(value)) {
    return value as GuardedType<(typeof PropertyTypeGuards)[T]>;
  }
  return null;
}

/**
 * Extracts common properties for all garden items from an options string
 * @param optionsString The content of hte options field (minus the version info)
 * @returns Common properties to all garden items
 */
function parseGardenItemOptions(optionsString: string): PlanGardenItemOptions {
  const options = deserializeOptionsString(optionsString);

  return {
    locked: getProperty(options, 'locked', 'boolean') ?? DEFAULT_PLAN_PLANT_OPTIONS.locked,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function parsePlanOptions(optionsString: string): PlanOptions {
  return DEFAULT_PLAN_OPTIONS;
}

function parsePlantOptions(optionsString: string): PlanPlantOptions {
  return parseGardenItemOptions(optionsString);
}

function parseGardenObjectOptions(optionsString: string): PlanGardenObjectOptions {
  return parseGardenItemOptions(optionsString);
}

function parseShapeOptions(optionsString: string): PlanShapeOptions {
  return parseGardenItemOptions(optionsString);
}

function parseTextOptions(optionsString: string): PlanTextOptions {
  return parseGardenItemOptions(optionsString);
}

/**
 * Converts properties from the given item into a saveable `options` string
 * @param item The item to convert properties of
 * @returns A stringified `options` value
 */
function stringifyGardenItemOptions(item: PlanPlant | PlanGardenObject | PlanShape | PlanText): StringifiedOptionsField {
  const options = {
    locked: item.locked,
  };

  const stringified = JSON.stringify(options);
  return prependSchemaVersion(SCHEMA_VERSION, stringified);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function stringifyPlanOptions(plan: Plan): StringifiedOptionsField {
  return prependSchemaVersion(SCHEMA_VERSION, ''); // Nothing to store yet
}

function stringifyPlantOptions(planPlant: PlanPlant): StringifiedOptionsField {
  return stringifyGardenItemOptions(planPlant);
}

function stringifyGardenObjectOptions(planGardenObject: PlanGardenObject): StringifiedOptionsField {
  return stringifyGardenItemOptions(planGardenObject);
}

function stringifyShapeOptions(planShape: PlanShape): StringifiedOptionsField {
  return stringifyGardenItemOptions(planShape);
}

function stringifyTextOptions(planText: PlanText): StringifiedOptionsField {
  return stringifyGardenItemOptions(planText);
}

/** Version 1 Options schema */
export const PlanOptionsParserV1: OptionsFieldParser = {
  version: SCHEMA_VERSION,
  parsePlanOptions,
  parsePlantOptions,
  parseGardenObjectOptions,
  parseShapeOptions,
  parseTextOptions,
  stringifyPlanOptions,
  stringifyPlantOptions,
  stringifyGardenObjectOptions,
  stringifyShapeOptions,
  stringifyTextOptions,
};
