import React, { useContext } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import { LayerDisplayModes } from '@gi/constants';

import { getTopbarSelectLockedStyles, getTopbarSelectStyles } from '../../common/select-styles';
import { PlannerCommandContext } from '../../../../planner-command-provider';

import styles from './layer-selector.module.css';

type LayerOption = {
  label: string;
  value: LayerDisplayModes;
};

const SELECT_STYLES = getTopbarSelectStyles(134);
const LOCKED_SELECT_STYLES = getTopbarSelectLockedStyles(134);

const LAYER_OPTIONS: LayerOption[] = [
  { label: 'Edit All', value: LayerDisplayModes.ALL },
  { label: 'View All', value: LayerDisplayModes.VIEW_ONLY },
  { label: 'Structures', value: LayerDisplayModes.STRUCTURES },
  { label: 'Plants', value: LayerDisplayModes.PLANTS },
  { label: 'Text', value: LayerDisplayModes.TEXT },
  { label: 'Irrigation', value: LayerDisplayModes.IRRIGATION },
  { label: 'Layout', value: LayerDisplayModes.LAYOUT },
  { label: 'Locked Items', value: LayerDisplayModes.LOCKED_ITEMS },
];

function getLabelForValue(value: LayerDisplayModes): string {
  for (let i = 0; i < LAYER_OPTIONS.length; i++) {
    if (LAYER_OPTIONS[i].value === value) {
      return LAYER_OPTIONS[i].label;
    }
  }

  console.warn('Text for invalid label value requested');

  return '';
}

function getLayout(layer: LayerDisplayModes | null): LayerOption {
  if (layer) {
    return {
      label: getLabelForValue(layer),
      value: layer,
    };
  }

  return { label: getLabelForValue(LAYER_OPTIONS[0].value), value: LAYER_OPTIONS[0].value };
}

interface iFormatLayerMenuOptionLabelProps {
  label: string;
  value: string;
}

const FormatLayerMenuOptionLabel = ({ label, value }: iFormatLayerMenuOptionLabelProps): JSX.Element | string => {
  if (value === LayerDisplayModes.VIEW_ONLY) {
    return (
      <>
        <i className='icon-lock' /> {label}
      </>
    );
  }

  if (value === LayerDisplayModes.ALL) {
    return (
      <>
        <i className='icon-lock-open' /> {label}
      </>
    );
  }

  return label;
};

interface iProps {
  hideLabel?: boolean;
  menuPlacement?: MenuPlacement;
  attachToBody?: boolean;
  id?: string;
  disabled?: boolean;
  identifier?: string;
}

const LayerSelector = ({ hideLabel, menuPlacement, attachToBody = false, id, disabled, identifier }: iProps): JSX.Element | null => {
  const { layer, setLayer } = useContext(PlannerCommandContext);

  const onChange = (v) => {
    if (setLayer) {
      setLayer(v.value);
    }
  };

  let selectStyles = SELECT_STYLES;
  if (layer === LayerDisplayModes.VIEW_ONLY) {
    selectStyles = LOCKED_SELECT_STYLES;
  }

  const selectComponent = (
    <ReactSelect<iFormatLayerMenuOptionLabelProps>
      isDisabled={disabled}
      styles={selectStyles}
      options={LAYER_OPTIONS}
      value={getLayout(layer)}
      onChange={onChange}
      formatOptionLabel={FormatLayerMenuOptionLabel}
      menuPortalTarget={attachToBody ? document.body : undefined}
      menuPlacement={menuPlacement}
      inputId={id}
      isSearchable={false}
    />
  );

  if (hideLabel) {
    return selectComponent;
  }

  return (
    <div className={styles.layerSelector} data-identifier={identifier}>
      <div>{selectComponent}</div>
    </div>
  );
};

export default LayerSelector;
