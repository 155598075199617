import { OVERWRITE_DOCUMENT_VERSION, ShapeType, GardenItemType, ModifierType } from '@gi/constants';

import type { APIPlan, APIPlanDocument, APIPlanGardenObject, APIPlanPlant, APIPlanShape, APIPlanText, APIPlanPlantListNote } from './plan-api-types';
import type { Plan } from './plan';
import type { PlanPlant } from './plan-plant';
import type { PlanGardenObject } from './plan-garden-object';
import type { PlanText } from './plan-text';
import type { PlanShape } from './plan-shape';
import { setPlantNote, type PlanPlantNotes, getPlanNoteRecordIdKey } from './plan-plant-notes';
import { convertToParseList } from './convert-to-parse-list';
import { PlanOptionsParser } from './plan-options/plan-options';
import {
  ModifierMap,
  ShapeTypeFromAPI,
  checkShapesForPlanDimensions,
  dateToAPI,
  filterShapesForPlanFillsAndDimensions,
  midpointFromAPI,
  midpointToAPI,
  rotationFromAPI,
  rotationToAPI,
  timeStringToUnix,
  ReverseModifierMap,
} from './plan-parser-utilts';
import { DEFAULT_PLANNER_SETTINGS } from './planner-settings';

function updateMinMaxZIndex(plan: Plan, zIndex: number) {
  if (zIndex > plan.maxZIndex) {
    plan.maxZIndex = zIndex;
  }
  if (zIndex < plan.minZIndex) {
    plan.minZIndex = zIndex;
  }
}

function getModifierFromName(name?: string | null): ModifierType | null {
  if (name === undefined || name === null || name === '') {
    return null;
  }
  const modifier = ModifierMap[name.trim()];
  if (!modifier) {
    return null;
  }
  return modifier;
}

function getModifierName(modifier?: ModifierType | null): string | null {
  if (modifier === undefined || modifier === null) {
    return null;
  }
  const modifierName = ReverseModifierMap[modifier.trim()];
  if (!modifierName) {
    return null;
  }
  return modifierName;
}

function plantFromAPI(plantObj: APIPlanPlant): [PlanPlant, number] {
  const options = PlanOptionsParser.parsePlantOptions(plantObj.options);
  return [
    {
      id: plantObj.objectID,
      plantCode: plantObj.plantCode,
      rowStart: { x: plantObj.startX, y: plantObj.startY },
      rowEnd: { x: plantObj.endX, y: plantObj.endY },
      height: plantObj.plantBlockHeight,
      rotation: rotationFromAPI(plantObj.rotation),
      showLabel: plantObj.showLabel,
      labelOffset: { x: plantObj.labelXOffset, y: plantObj.labelYOffset },
      labelText: plantObj.text,
      isSquareFoot: plantObj.isSquareFootPlant,
      variety: plantObj.variety,
      inGroundStart: plantObj.inGroundStart,
      inGroundEnd: plantObj.inGroundEnd,
      inGroundAll: plantObj.inGroundAll,
      modifiers: plantObj.varietyModifiers
        .split(' ')
        .map(getModifierFromName)
        .filter((modifier): modifier is ModifierType => modifier !== null),
      locked: options.locked,
      zIndex: plantObj.zIndex,
    },
    plantObj.recordID,
  ];
}

function gardenObjectFromAPI(gardenObjectObj: APIPlanGardenObject): [PlanGardenObject, number] {
  const options = PlanOptionsParser.parseGardenObjectOptions(gardenObjectObj.options);
  return [
    {
      id: gardenObjectObj.objectID,
      code: gardenObjectObj.code,
      start: { x: gardenObjectObj.startX, y: gardenObjectObj.startY },
      mid: midpointFromAPI(
        gardenObjectObj.startX,
        gardenObjectObj.startY,
        gardenObjectObj.midX,
        gardenObjectObj.midY,
        gardenObjectObj.endX,
        gardenObjectObj.endY
      ),
      end: { x: gardenObjectObj.endX, y: gardenObjectObj.endY },
      rotation: rotationFromAPI(gardenObjectObj.rotation),
      locked: options.locked,
      zIndex: gardenObjectObj.zIndex,
    },
    gardenObjectObj.recordID,
  ];
}

function shapeTypeFromAPI(shapeType: string): ShapeType | null {
  switch (shapeType) {
    case ShapeTypeFromAPI.RECTANGLE:
      return ShapeType.RECTANGLE;
    case ShapeTypeFromAPI.ELLIPSE:
      return ShapeType.ELLIPSE;
    case ShapeTypeFromAPI.TRIANGLE:
      return ShapeType.TRIANGLE;
    case ShapeTypeFromAPI.LINE:
      return ShapeType.LINE;
    case ShapeTypeFromAPI.FILL:
      return ShapeType.FILL;
    case ShapeTypeFromAPI.PLAN_DIMENSIONS:
      return ShapeType.PLAN_DIMENSIONS;
    default:
      return null;
  }
}

function shapeFromAPI(shapeObj: APIPlanShape): [PlanShape, number] {
  const shapeType = shapeTypeFromAPI(shapeObj.objectType);
  if (shapeType === null) {
    throw new Error(`Unrecognised shape type: ${shapeObj.objectType}`);
  }

  const options = PlanOptionsParser.parseShapeOptions(shapeObj.options);
  return [
    {
      id: shapeObj.objectID,
      type: shapeType,
      closed: true,
      point1: { x: shapeObj.startX, y: shapeObj.startY },
      point2: midpointFromAPI(shapeObj.startX, shapeObj.startY, shapeObj.midX, shapeObj.midY, shapeObj.endX, shapeObj.endY, shapeType === ShapeType.TRIANGLE),
      point3: { x: shapeObj.endX, y: shapeObj.endY },
      rotation: rotationFromAPI(shapeObj.rotation),
      fill: shapeObj.filled ? shapeObj.colour : null,
      stroke: shapeObj.colour,
      strokeWidth: shapeObj.width, // ?
      texture: shapeObj.objectSubType !== '' ? shapeObj.objectSubType : null,
      locked: options.locked,
      zIndex: shapeObj.zIndex,
    },
    shapeObj.recordID,
  ];
}

function textFromAPI(textObj: APIPlanText): [PlanText, number] {
  const options = PlanOptionsParser.parseShapeOptions(textObj.options);
  return [
    {
      id: textObj.objectID,
      text: textObj.text,
      fill: textObj.colour,
      fontSize: textObj.size,
      start: { x: textObj.startX, y: textObj.startY },
      end: { x: textObj.endX, y: textObj.endY },
      rotation: rotationFromAPI(textObj.rotation),
      locked: options.locked,
      zIndex: textObj.zIndex,
    },
    textObj.recordID,
  ];
}

export const plantNotesFromAPI = (apiPlanNotes: APIPlanPlantListNote[]): [PlanPlantNotes, Record<string, number>] => {
  let plantNotes: PlanPlantNotes = {
    plantCodes: [],
    varietiesByCode: {},
    count: 0,
  };

  const plantNotesRecordIds = {};

  for (let i = 0; i < apiPlanNotes.length; i++) {
    const { plantCode, varietyName, text, recordID } = apiPlanNotes[i];
    plantNotes = setPlantNote(plantNotes, plantCode, varietyName, text);
    plantNotesRecordIds[getPlanNoteRecordIdKey(plantCode, varietyName)] = recordID;
  }

  return [plantNotes, plantNotesRecordIds];
};

export function planFromAPI(planDocument: APIPlanDocument): Plan {
  let width = planDocument.plan.canvasWidth;
  let height = planDocument.plan.canvasHeight;

  // Old plans have plan dimensions in a shape object as endX and endY with objectType 'V'
  const planDimensions = checkShapesForPlanDimensions(planDocument.planShapes);
  if (planDimensions !== null) {
    width = planDimensions.width;
    height = planDimensions.height;
  }

  const [plantNotes, plantNotesRecordIds] = plantNotesFromAPI(planDocument.plantListNotes);

  // `options` field is currently unused on plans
  // const options = PlanOptionsParser.parsePlanOptions(planDocument.plan.options);

  const plan: Plan = {
    id: planDocument.plan.planID,
    userId: planDocument.plan.userID,
    name: planDocument.plan.planName,
    year: planDocument.plan.planYear + 2000,
    created: planDocument.plan.created.length === 0 ? null : timeStringToUnix(planDocument.plan.created), // TODO timezone check
    modified: planDocument.plan.modified.length === 0 ? null : timeStringToUnix(planDocument.plan.modified),
    deleted: planDocument.plan.deleted.length === 0 ? null : timeStringToUnix(planDocument.plan.deleted),
    documentVersion: planDocument.plan.documentVersion,
    lastSaveDevice: planDocument.plan.lastSaveDevice,
    shared: planDocument.plan.shared,
    width,
    height,
    orientationSet: planDocument.plan.orientationSet,
    orientation: planDocument.plan.orientation,
    history: [
      planDocument.plan.historyID1,
      planDocument.plan.historyID2,
      planDocument.plan.historyID3,
      planDocument.plan.historyID4,
      planDocument.plan.historyID5,
    ].filter((historyId) => historyId !== 0),
    templatePlanId: planDocument.plan.templatePlanID,
    notes: planDocument.plan.notes,
    published: planDocument.plan.planPublished,
    publishLocation: planDocument.plan.planLocation,
    publishDescription: planDocument.plan.planDescription,
    publishMap: planDocument.plan.publishMap,
    publishFindOnMap: planDocument.plan.findOnMap,
    publishPlantList: planDocument.plan.publishPlantList,
    publishNotes: planDocument.plan.publishNotes,
    type: planDocument.plan.planType,
    layout: planDocument.plan.planLayout,
    sun: planDocument.plan.planSun,
    soil: planDocument.plan.planSoil,
    plannerSettings: {
      ...DEFAULT_PLANNER_SETTINGS,
      metric: planDocument.plan.metric,
      showGrid: planDocument.plan.grid,
    },
    maxItemId: 0,
    itemTypes: {},
    plants: {},
    plantIds: [],
    gardenObjects: {},
    gardenObjectIds: [],
    shapes: {},
    shapeIds: [],
    text: {},
    textIds: [],
    plantNotes,
    itemRecordIds: {},
    plantNotesRecordIds,
    minZIndex: 0,
    maxZIndex: 0,
  };

  const plantParseList = convertToParseList(planDocument.planPlants);

  for (let i = 0; i < plantParseList.length; i++) {
    const plantObj = plantParseList[i];
    plan.itemTypes[plantObj.objectID] = GardenItemType.Plant;
    const [planPlant, recordId] = plantFromAPI(plantObj);
    plan.plants[plantObj.objectID] = planPlant;
    plan.plantIds.push(plantObj.objectID);
    plan.itemRecordIds[planPlant.id] = recordId;

    if (plan.maxItemId < plantObj.objectID) {
      plan.maxItemId = plantObj.objectID;
    }
    updateMinMaxZIndex(plan, planPlant.zIndex);
  }

  const gardenObjectParseList = convertToParseList(planDocument.planGardenObjects);

  for (let i = 0; i < gardenObjectParseList.length; i++) {
    const gardenObjectObj = gardenObjectParseList[i];
    plan.itemTypes[gardenObjectObj.objectID] = GardenItemType.GardenObject;
    const [planGardenObject, recordId] = gardenObjectFromAPI(gardenObjectObj);
    plan.gardenObjects[gardenObjectObj.objectID] = planGardenObject;
    plan.gardenObjectIds.push(gardenObjectObj.objectID);
    plan.itemRecordIds[planGardenObject.id] = recordId;

    if (plan.maxItemId < gardenObjectObj.objectID) {
      plan.maxItemId = gardenObjectObj.objectID;
    }
    updateMinMaxZIndex(plan, planGardenObject.zIndex);
  }

  const shapeParseList = convertToParseList(filterShapesForPlanFillsAndDimensions(planDocument.planShapes));

  for (let i = 0; i < shapeParseList.length; i++) {
    const shapeObj = shapeParseList[i];
    plan.itemTypes[shapeObj.objectID] = GardenItemType.Shape;
    const [planShape, recordId] = shapeFromAPI(shapeObj);
    plan.shapes[shapeObj.objectID] = planShape;
    plan.shapeIds.push(shapeObj.objectID);
    plan.itemRecordIds[planShape.id] = recordId;

    if (plan.maxItemId < shapeObj.objectID) {
      plan.maxItemId = shapeObj.objectID;
    }
    updateMinMaxZIndex(plan, planShape.zIndex);
  }

  const textParseList = convertToParseList(planDocument.planText);

  for (let i = 0; i < textParseList.length; i++) {
    const textObj = textParseList[i];
    plan.itemTypes[textObj.objectID] = GardenItemType.Text;
    const [planText, recordId] = textFromAPI(textObj);
    plan.text[textObj.objectID] = planText;
    plan.textIds.push(textObj.objectID);
    plan.itemRecordIds[planText.id] = recordId;

    if (plan.maxItemId < textObj.objectID) {
      plan.maxItemId = textObj.objectID;
    }
    updateMinMaxZIndex(plan, planText.zIndex);
  }

  return plan;
}

function getPlanHistoryIndex(plan: Plan, historyIndex: number) {
  if (plan.history.length <= historyIndex) {
    return 0;
  }

  return plan.history[historyIndex];
}

function planPropertiesToAPI(plan: Plan): APIPlan {
  return {
    planID: plan.id,
    userID: plan.userId,
    planName: plan.name,
    planYear: plan.year - 2000,
    created: dateToAPI(plan.created),
    modified: dateToAPI(plan.modified),
    deleted: dateToAPI(plan.deleted),
    documentVersion: OVERWRITE_DOCUMENT_VERSION,
    lastSaveDevice: 'WEB',
    shared: plan.shared,
    metric: plan.plannerSettings.metric,
    grid: plan.plannerSettings.showGrid,
    canvasWidth: plan.width,
    canvasHeight: plan.height,
    orientationSet: plan.orientationSet,
    orientation: plan.orientation,
    historyID1: getPlanHistoryIndex(plan, 0),
    historyID2: getPlanHistoryIndex(plan, 1),
    historyID3: getPlanHistoryIndex(plan, 2),
    historyID4: getPlanHistoryIndex(plan, 3),
    historyID5: getPlanHistoryIndex(plan, 4),
    templatePlanID: plan.templatePlanId,
    showGhosts: false, // Not used
    notes: plan.notes,
    planPublished: plan.published,
    planLocation: plan.publishLocation,
    planDescription: plan.publishDescription,
    publishMap: plan.publishMap,
    findOnMap: plan.publishFindOnMap,
    publishPlantList: plan.publishPlantList,
    publishNotes: plan.publishNotes,
    planType: plan.type,
    planLayout: plan.layout,
    planSun: plan.sun,
    planSoil: plan.soil,
    options: PlanOptionsParser.stringifyPlanOptions(plan),
  };
}

export function plantToAPI(itemRecordIds: Record<number, number>): (planPlant: PlanPlant) => APIPlanPlant {
  return (planPlant: PlanPlant): APIPlanPlant => {
    const recordId = itemRecordIds[planPlant.id] ? itemRecordIds[planPlant.id] : 0;
    return {
      recordID: recordId,
      action: 'C',
      objectID: planPlant.id,
      text: planPlant.labelText,
      plantCode: planPlant.plantCode,
      variety: planPlant.variety,
      plantBlockHeight: planPlant.height,
      startX: planPlant.rowStart.x,
      startY: planPlant.rowStart.y,
      endX: planPlant.rowEnd.x,
      endY: planPlant.rowEnd.y,
      showLabel: planPlant.showLabel,
      labelXOffset: planPlant.labelOffset.x,
      labelYOffset: planPlant.labelOffset.y,
      rotation: rotationToAPI(planPlant.rotation),
      inGroundAll: planPlant.inGroundAll,
      inGroundStart: planPlant.inGroundStart,
      inGroundEnd: planPlant.inGroundEnd,
      isSquareFootPlant: planPlant.isSquareFoot,
      varietyModifiers: planPlant.modifiers
        .map(getModifierName)
        .filter((modifierName): modifierName is string => modifierName !== null)
        .join(' '),
      midX: 0, // TODO: Implement in the future. Unused currently.
      midY: 0, // TODO: Implement in the future. Unused currently.
      zIndex: planPlant.zIndex,
      options: PlanOptionsParser.stringifyPlantOptions(planPlant),
    };
  };
}

export function gardenObjectToAPI(itemRecordIds: Record<number, number>): (planGardenObject: PlanGardenObject) => APIPlanGardenObject {
  return (planGardenObject: PlanGardenObject): APIPlanGardenObject => {
    const recordId = itemRecordIds[planGardenObject.id] ? itemRecordIds[planGardenObject.id] : 0;
    const [midX, midY] = midpointToAPI(planGardenObject.start, planGardenObject.mid, planGardenObject.end);
    return {
      recordID: recordId,
      action: 'C',
      objectID: planGardenObject.id,
      code: planGardenObject.code,
      endX: planGardenObject.end.x,
      endY: planGardenObject.end.y,
      midX,
      midY,
      rotation: rotationToAPI(planGardenObject.rotation),
      startX: planGardenObject.start.x,
      startY: planGardenObject.start.y,
      inGroundStart: 0, // TODO: Implement in the future. Unused currently.
      inGroundEnd: 11, // TODO: Implement in the future. Unused currently.
      inGroundAll: true, // TODO: Implement in the future. Unused currently.
      zIndex: planGardenObject.zIndex,
      options: PlanOptionsParser.stringifyGardenObjectOptions(planGardenObject),
    };
  };
}

function shapeTypeToAPI(shapeType: ShapeType): ShapeTypeFromAPI {
  switch (shapeType) {
    case ShapeType.RECTANGLE:
      return ShapeTypeFromAPI.RECTANGLE;
    case ShapeType.ELLIPSE:
      return ShapeTypeFromAPI.ELLIPSE;
    case ShapeType.TRIANGLE:
      return ShapeTypeFromAPI.TRIANGLE;
    case ShapeType.LINE:
      return ShapeTypeFromAPI.LINE;
    case ShapeType.FILL:
      return ShapeTypeFromAPI.FILL;
    case ShapeType.PLAN_DIMENSIONS:
      return ShapeTypeFromAPI.PLAN_DIMENSIONS;
    default:
      throw new Error(`Unknown shape type ${shapeType}`);
  }
}

export function shapeToAPI(itemRecordIds: Record<number, number>): (planShape: PlanShape) => APIPlanShape {
  return (planShape: PlanShape): APIPlanShape => {
    const recordId = itemRecordIds[planShape.id] ? itemRecordIds[planShape.id] : 0;
    const [midX, midY] = midpointToAPI(planShape.point1, planShape.point2, planShape.point3);
    return {
      recordID: recordId,
      action: 'C',
      objectID: planShape.id,
      objectType: shapeTypeToAPI(planShape.type)!,
      objectSubType: planShape.texture !== null ? planShape.texture : '',
      startX: planShape.point1.x,
      startY: planShape.point1.y,
      midX,
      midY,
      endX: planShape.point3.x,
      endY: planShape.point3.y,
      rotation: rotationToAPI(planShape.rotation),
      width: planShape.strokeWidth,
      filled: planShape.fill !== null,
      colour: (planShape.fill !== null ? planShape.fill : planShape.stroke) ?? 0,
      zIndex: planShape.zIndex,
      options: PlanOptionsParser.stringifyShapeOptions(planShape),
    };
  };
}

export function textToAPI(itemRecordIds: Record<number, number>): (planShape: PlanText) => APIPlanText {
  return (planText: PlanText): APIPlanText => {
    const recordId = itemRecordIds[planText.id] ? itemRecordIds[planText.id] : 0;
    return {
      recordID: recordId,
      action: 'C',
      objectID: planText.id,
      text: planText.text,
      colour: planText.fill,
      size: planText.fontSize,
      startX: planText.start.x,
      startY: planText.start.y,
      endX: planText.end.x,
      endY: planText.end.y,
      rotation: rotationToAPI(planText.rotation),
      zIndex: planText.zIndex,
      options: PlanOptionsParser.stringifyTextOptions(planText),
    };
  };
}

export const plantNotesToAPI = (planPlantNotes: PlanPlantNotes, plantNotesRecordIds: Record<string, number>): APIPlanPlantListNote[] => {
  const plantNotesList: APIPlanPlantListNote[] = [];

  for (let i = 0; i < planPlantNotes.plantCodes.length; i++) {
    const plantCode = planPlantNotes.plantCodes[i];
    const { varieties, byVariety } = planPlantNotes.varietiesByCode[plantCode];

    for (let j = 0; j < varieties.length; j++) {
      const varietyName = varieties[j];

      const plantNotesRecordIdKey = getPlanNoteRecordIdKey(plantCode, varietyName);
      const recordId = plantNotesRecordIds[plantNotesRecordIdKey] ? plantNotesRecordIds[plantNotesRecordIdKey] : 0;

      plantNotesList.push({
        plantCode,
        varietyName,
        text: byVariety[varietyName].text,
        recordID: recordId,
      });
    }
  }

  return plantNotesList;
};

export function planToAPI(plan: Plan): APIPlanDocument {
  return {
    plan: planPropertiesToAPI(plan),
    planPlants: plan.plantIds.map((plantId) => plan.plants[plantId]).map(plantToAPI(plan.itemRecordIds)),
    planGardenObjects: plan.gardenObjectIds.map((gardenObjectId) => plan.gardenObjects[gardenObjectId]).map(gardenObjectToAPI(plan.itemRecordIds)),
    planShapes: plan.shapeIds.map((shapeId) => plan.shapes[shapeId]).map(shapeToAPI(plan.itemRecordIds)),
    planText: plan.textIds.map((textId) => plan.text[textId]).map(textToAPI(plan.itemRecordIds)),
    plantListNotes: plantNotesToAPI(plan.plantNotes, plan.plantNotesRecordIds),
  };
}
