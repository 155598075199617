import { Dispatch } from 'redux';
import { batchActions } from 'redux-batched-actions';

import { RequestActionCreators } from '@gi/react-requests';
import { generateNotificationID, NotificationTypes, NotificationActionCreators } from '@gi/notifications';
import { CanvasActionCreators } from '@gi/react-garden-canvas';
import { errorReporterInstance } from '@gi/errors';
import { SessionActionCreators } from '@gi/react-session';
import Plan, { PlanService } from '@gi/plan';
import { GardenTypes, LayoutTypes, SoilTypes, SunTypes } from '@gi/constants';
import { User } from '@gi/user';
import { GardenCanvas, RulersMode } from '@gi/plan-simulation';
import { generateImagePromise } from '@gi/core-renderer';

import PublishActionTypes from './publish-action-types';
import PublishPlanStages from './publish-plan-stages';

import { canvasToPreview, canvasToThumbnail } from './utils';

import { PUBLISH_PLAN } from './constants';

// const DEFAULT_CANVAS_SIZE = 2000;
const LOW_MEMORY_CANVAS_SIZE = 500;

export interface PublishPlanProperties {
  description: string;
  location: string;
  soil: SoilTypes;
  sun: SunTypes;
  layout: LayoutTypes;
  type: GardenTypes;
  publishMap: boolean;
  publishNotes: boolean;
  publishPlantList: boolean;
  findOnMap: boolean;
}

/**
 * Returns a plan with updated values, to be used after a plan is published
 */
const updatePlanWithPublishedProperties = (plan: Plan, properties: PublishPlanProperties): Plan => {
  return {
    ...plan,
    published: true,
    publishLocation: properties.location,
    publishDescription: properties.description,
    publishMap: properties.publishMap,
    publishFindOnMap: properties.findOnMap,
    publishPlantList: properties.publishPlantList,
    publishNotes: properties.publishNotes,
    type: properties.type,
    layout: properties.layout,
    sun: properties.sun,
    soil: properties.soil,
  };
};

const setPublishProgress = (progress: number) => {
  return {
    type: PublishActionTypes.SET_PROGRESS,
    progress,
  };
};

const setPublishStage = (stage: PublishPlanStages) => {
  return {
    type: PublishActionTypes.SET_STAGE,
    stage,
  };
};

const setErrorReason = (reason: string | null) => {
  return {
    type: PublishActionTypes.SET_ERROR_REASON,
    errorReason: reason,
  };
};

export const resetPublishPlan = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      batchActions([
        // TODO - Turn this into a single action? Maybe
        setPublishStage(PublishPlanStages.NOT_IN_PROGRESS),
        setPublishProgress(0),
        setErrorReason(null),
      ])
    );
  };
};

const publishPlanFailure = (reason: string) => {
  return {
    type: PublishActionTypes.PUBLISH_FAILURE,
    reason,
  };
};

const publishPlanSuccess = (planID: number, properties: PublishPlanProperties) => {
  return {
    type: PublishActionTypes.PUBLISH_SUCCESS,
    planID,
    properties,
  };
};

export const publishPlan = (gardenCanvas: GardenCanvas, user: User, plan: Plan, properties: PublishPlanProperties, delay: number = 0) => {
  return (dispatch: Dispatch, getState, { services }: { services: { planService: PlanService } }) => {
    dispatch(setPublishStage(PublishPlanStages.GENERATING_CANVAS));

    const run = () => {
      const activePlan = gardenCanvas.getActivePlan();
      if (!activePlan) {
        console.error('Could not generate preview image: No active plan on Garden Canvas');
        dispatch(publishPlanFailure('Error creating preview image: No active plan'));
        return;
      }

      const { dimensions } = activePlan.simulatedPlan;
      const rulerSize = activePlan.canvasPlan.rulersNode.state.values.thickness;

      // Canvas size tells the generator what size segments to use on the hidden canvas
      // To capture images, smaller canvas sizes will more likely be tolerated by
      // Browsers with access to less memory (in theory)
      const generateImageOptions = {
        canvasWidth: LOW_MEMORY_CANVAS_SIZE,
        canvasHeight: LOW_MEMORY_CANVAS_SIZE,
        frame: { left: 0, top: 0, right: dimensions.width, bottom: dimensions.height },
        padding: rulerSize - 1,
        onProgress: (progress) => {
          dispatch(setPublishProgress(progress));
        },
      };

      activePlan.canvasPlan.rulersNode.setMode(RulersMode.SHOW_ALL);

      generateImagePromise(gardenCanvas.engine, generateImageOptions)
        .then((canvas) => {
          dispatch(setPublishStage(PublishPlanStages.CONVERTING_TO_IMAGE));
          // Create image from canvas
          let image: string | null = null;
          try {
            image = canvas.toDataURL('image/jpeg', 1).replace(/^data:image\/[a-z]+;base64,/, '');
          } catch (e) {
            console.error('Error converting canvas image to image');
            console.error(e);
            errorReporterInstance.notify(e);
            dispatch(batchActions([publishPlanFailure('Error converting canvas image to image'), setPublishStage(PublishPlanStages.FAILED)]));
            return;
          }

          // Create preview image
          dispatch(setPublishStage(PublishPlanStages.GENERATING_PREVIEW_IMAGE));
          let previewImage;
          try {
            previewImage = canvasToPreview(canvas, ['image/jpeg', 1]).replace(/^data:image\/[a-z]+;base64,/, '');
          } catch (e) {
            console.error('Error creating preview image');
            console.error(e);
            errorReporterInstance.notify(e);
            dispatch(batchActions([publishPlanFailure('Error creating preview image'), setPublishStage(PublishPlanStages.FAILED)]));
            return;
          }

          // Create thumbnail
          dispatch(setPublishStage(PublishPlanStages.GENERATING_THUMBNAIL_IMAGE));
          let thumbnailImage;
          try {
            thumbnailImage = canvasToThumbnail(canvas, { top: 20, left: 20, right: 20, bottom: 20 }, ['image/jpeg', 1]).replace(
              /^data:image\/[a-z]+;base64,/,
              ''
            );
          } catch (e) {
            console.error('Error creating thumbnail image');
            console.error(e);
            errorReporterInstance.notify(e);
            dispatch(batchActions([publishPlanFailure('Error creating thumbnail image'), setPublishStage(PublishPlanStages.FAILED)]));
            return;
          }

          const notificationID = generateNotificationID();
          const notificationTitle = 'Saving Published Plan';
          const notificationSuccessTitle = 'Plan Published';
          const notificationErrorTitle = 'Plan Publish Failed';

          // Upload plan
          dispatch(
            batchActions([
              setPublishStage(PublishPlanStages.UPLOADING),
              RequestActionCreators.requestStart(PUBLISH_PLAN),
              NotificationActionCreators.createDefaultNotification({
                title: notificationTitle,
                icon: 'icon-floppy',
                ID: notificationID,
                type: NotificationTypes.INFO,
                inProgress: true,
              }),
            ])
          );

          services.planService
            .publishPlan(plan.id, image, previewImage, thumbnailImage, properties)
            .then(() => {
              dispatch(
                batchActions([
                  RequestActionCreators.requestComplete(PUBLISH_PLAN),
                  setPublishStage(PublishPlanStages.COMPLETE),
                  publishPlanSuccess(plan.id, properties),
                  NotificationActionCreators.updateNotificationByID({
                    notificationID,
                    update: {
                      title: notificationSuccessTitle,
                      icon: 'icon-ok',
                      type: NotificationTypes.SUCCESS,
                      canTimeout: true,
                    },
                  }),
                  CanvasActionCreators.updatePlan(updatePlanWithPublishedProperties(plan, properties)),
                ])
              );
            })
            .catch((e) => {
              console.error('Error submitting plan');
              console.error(e);
              errorReporterInstance.notify(e);
              dispatch(
                batchActions([
                  RequestActionCreators.requestFail(PUBLISH_PLAN, e),
                  setPublishStage(PublishPlanStages.FAILED),
                  publishPlanFailure('Error submitting published plan'),
                  NotificationActionCreators.updateNotificationByID({
                    notificationID,
                    update: {
                      title: notificationErrorTitle,
                      icon: 'icon-attention-alt',
                      type: NotificationTypes.ERROR,
                      canTimeout: false,
                      inProgress: false,
                    },
                  }),
                ])
              );
            });
        })
        .catch((e) => {
          console.error('Error creating preview image');
          console.error(e);
          errorReporterInstance.notify(e);
          dispatch(publishPlanFailure('Error creating preview image'));
        })
        .finally(() => {
          activePlan.canvasPlan.rulersNode.setMode(RulersMode.DEFAULT);
        });
    };

    setTimeout(run, delay);
  };
};

export const sharePlans = () => {
  return (dispatch, getState) => {
    const { user } = getState().session;

    if (user === null) {
      const notificationID = generateNotificationID();
      // fail
      dispatch(
        NotificationActionCreators.createDefaultNotification({
          title: 'Profile Update Failed',
          icon: 'icon-attention-alt',
          ID: notificationID,
          type: NotificationTypes.ERROR,
          canTimeout: false,
          inProgress: false,
        })
      );
      return new Promise((resolve, reject) => {
        reject(new Error('Unauthorised'));
      }); // TODO - better error
    }

    const updatedUser = {
      ...user,
      settings: {
        ...user.settings,
        plansPublic: true,
      },
    };
    return dispatch(SessionActionCreators.saveUser(updatedUser));
  };
};
