import React, { ReactNode } from 'react';

import './icon-button.scss';

export enum IconButtonType {
  SMALL = 'SMALL',
  TAB = 'TAB',
}

interface iProps {
  children?: ReactNode;
  className?: string;
  icon: string | ReactNode;
  type?: IconButtonType;
  active?: boolean;
  onClick?: () => void;
  shareSpace?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  identifier?: string;
}

const IconButton = ({
  children,
  className,
  icon,
  type = IconButtonType.SMALL,
  active = false,
  onClick,
  shareSpace = false,
  disabled = false,
  ariaLabel,
  identifier,
}: iProps): JSX.Element => {
  const classNames = ['icon-button', type.toLowerCase()];
  if (active) {
    classNames.push('active');
  }
  if (shareSpace) {
    classNames.push('flex');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <button
      className={classNames.join(' ')}
      type='button'
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled}
      aria-label={ariaLabel}
      data-identifier={identifier}
    >
      <div className='icon-button-icon'>{typeof icon === 'string' ? <i className={icon} /> : icon}</div>
      {children ? <span className='icon-button-text'>{children}</span> : null}
    </button>
  );
};

export default IconButton;
