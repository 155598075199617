import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { usePrevious } from '@gi/react-utils';
import { Application, ApplicationNames, NavigationContext, getAbsoluteApplicationLink } from '@gi/garden-platform-navigation';
import { FeaturesContext } from '@gi/features';
import { TooltipKey } from '@gi/tooltip';

import { SidebarActionCreators, SidebarSelectors } from '../sidebar-slice';

import { SidebarItemGroup, SidebarItem, SidebarItemContent, SidebarPaneContent } from './sidebar-item';
import SidebarItemTitle from './sidebar-item-title';
import SidebarIconContainer from './sidebar-icons/sidebar-icon-container';
import SidebarItemTitleText from './sidebar-item-title-text';

import LogoBar from './logo-bar/logo-bar';

import DashboardIcon from './sidebar-icons/dashboard-icon';
import GardenPlannerIcon from './sidebar-icons/garden-planner-icon';
import JournalIcon from './sidebar-icons/journal-icon';
import AccountIcon from './sidebar-icons/account-icon';
import HelpIcon from './sidebar-icons/help-icon';
import CloseIcon from './sidebar-icons/close-icon';

import SidebarPosition from '../sidebar-position';

import './sidebar.scss';
import GardenGuruIcon from './sidebar-icons/garden-guru-icon';
import NewTag from './new-tag';

const sidebarStyle = {
  transition: 'box-shadow 500ms',
};

const sidebarTransitionStyles = {
  default: { boxShadow: '-12px 2px 8px 1px rgba(0, 0, 0, 0.75)' },
  entering: { boxShadow: '-1px 2px 8px 1px rgba(0, 0, 0, 0.75)' },
  entered: { boxShadow: '-1px 2px 8px 1px rgba(0, 0, 0, 0.75)' },
  exiting: { boxShadow: '-12px 2px 8px 1px rgba(0, 0, 0, 0.75)' },
  exited: { boxShadow: '-12px 2px 8px 1px rgba(0, 0, 0, 0.75)' },
};

const SidebarClass = {
  [SidebarPosition.Open]: 'sidebar sidebar-open',
  [SidebarPosition.Minimised]: 'sidebar sidebar-minimised',
  [SidebarPosition.Closed]: 'sidebar sidebar-closed',
};

const applicationTooltipKeys: Record<Application, TooltipKey> = {
  [Application.Overview]: TooltipKey.SidebarOverview,
  [Application.GardenPlanner]: TooltipKey.SidebarGardenPlanner,
  [Application.Journal]: TooltipKey.SidebarJournal,
  [Application.Guru]: TooltipKey.SidebarGuru,
  [Application.Help]: TooltipKey.SidebarHelp,
  [Application.Account]: TooltipKey.SidebarAccount,
};

const applicationIdentifiers: Record<Application, string> = {
  [Application.Overview]: 'app-button-overview',
  [Application.GardenPlanner]: 'app-button-garden-planner',
  [Application.Journal]: 'app-button-journal',
  [Application.Guru]: 'app-button-guru',
  [Application.Help]: 'app-button-help',
  [Application.Account]: 'app-button-account',
};

interface iSidebarApplicationLinkProps {
  application: Application;
  isNew?: boolean;
  children: React.ReactNode;
}

const SidebarApplicationLink = ({ application, isNew = false, children }: iSidebarApplicationLinkProps): JSX.Element => {
  const navigate = useNavigate();
  const { activeApplication } = useContext(NavigationContext);
  const { featureEnabled } = useContext(FeaturesContext);
  const sidebarPosition = useSelector(SidebarSelectors.getSidebarPosition);

  const active = activeApplication === application;

  const showNew = useMemo(() => {
    return isNew && !featureEnabled('HIDE_NEW_TAGS');
  }, [isNew, featureEnabled]);

  return (
    <SidebarItemGroup active={active} className='dashboard-item-group'>
      <SidebarItem
        className='main-sidebar-item'
        active={active}
        onClick={() => navigate(getAbsoluteApplicationLink(application))}
        identifier={applicationIdentifiers[application]}
      >
        <SidebarPaneContent>
          <SidebarIconContainer tooltipKey={applicationTooltipKeys[application]} showTooltip={sidebarPosition === SidebarPosition.Minimised}>
            {showNew ? <NewTag /> : null}
            {children}
          </SidebarIconContainer>
        </SidebarPaneContent>
        <SidebarItemContent className='item-title-container' sidebarState={sidebarPosition}>
          <SidebarItemTitle>
            <SidebarItemTitleText>{ApplicationNames[application]}</SidebarItemTitleText>
          </SidebarItemTitle>
        </SidebarItemContent>
      </SidebarItem>
    </SidebarItemGroup>
  );
};

const Sidebar = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const setSidebarPosition = (pos: SidebarPosition) => {
    dispatch(SidebarActionCreators.setSidebarPosition(pos));
  };

  const openSidebar = () => {
    setSidebarPosition(SidebarPosition.Open);
  };

  const closeSidebar = () => {
    setSidebarPosition(SidebarPosition.Minimised);
  };

  const sidebarPosition = useSelector(SidebarSelectors.getSidebarPosition);

  const { pathname } = useLocation();

  // Close the nav bar when we change URL
  const previousLocation = usePrevious(pathname);
  useEffect(() => {
    if (previousLocation && pathname !== previousLocation && sidebarPosition === SidebarPosition.Open) {
      closeSidebar();
    }
  }, [pathname]);

  const { activeApplication } = useContext(NavigationContext);

  if (sidebarPosition === SidebarPosition.Closed) {
    return null;
  }

  return (
    <Transition in={sidebarPosition === SidebarPosition.Open} timeout={500}>
      {(state) => (
        <div
          className={SidebarClass[sidebarPosition]}
          style={{
            ...sidebarStyle,
            ...sidebarTransitionStyles[state],
          }}
        >
          <div className='sidebar-pane-background' />
          <div className='sidebar-items'>
            <LogoBar openSidebar={openSidebar} closeSidebar={closeSidebar} sidebarState={sidebarPosition} />
            <SidebarApplicationLink application={Application.Overview}>
              <DashboardIcon active={activeApplication === Application.Overview} />
            </SidebarApplicationLink>
            <SidebarApplicationLink application={Application.GardenPlanner}>
              <GardenPlannerIcon active={activeApplication === Application.GardenPlanner} />
            </SidebarApplicationLink>
            <SidebarApplicationLink application={Application.Journal}>
              <JournalIcon active={activeApplication === Application.Journal} />
            </SidebarApplicationLink>
            <SidebarApplicationLink application={Application.Guru}>
              <GardenGuruIcon active={activeApplication === Application.Guru} />
            </SidebarApplicationLink>
          </div>
          <div className='sidebar-spacer'>
            <div className='sidebar-spacer-pane' />
            <div className='sidebar-spacer-content' />
          </div>
          <div className='sidebar-items'>
            <SidebarApplicationLink application={Application.Help}>
              <HelpIcon active={activeApplication === Application.Help} />
            </SidebarApplicationLink>
            <SidebarApplicationLink application={Application.Account}>
              <AccountIcon active={activeApplication === Application.Account} />
            </SidebarApplicationLink>
            <SidebarItemGroup className='dashboard-item-group'>
              <SidebarItem onClick={() => setSidebarPosition(SidebarPosition.Closed)}>
                <SidebarPaneContent small>
                  <SidebarIconContainer tooltipKey={TooltipKey.CloseSidebar} showTooltip={sidebarPosition === SidebarPosition.Minimised}>
                    <CloseIcon />
                  </SidebarIconContainer>
                </SidebarPaneContent>
                <SidebarItemContent className='item-title-container' sidebarState={sidebarPosition}>
                  <SidebarItemTitle small>
                    <SidebarItemTitleText>Hide Sidebar</SidebarItemTitleText>
                  </SidebarItemTitle>
                </SidebarItemContent>
              </SidebarItem>
            </SidebarItemGroup>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default Sidebar;
