import React, { useCallback, useContext } from 'react';
import { TutorialContext, TutorialUtils } from '@gi/tutorial';
import { TutorialEditorContext } from './tutorial-editor-context';

import styles from './tutorial-editor.module.css';

const EditorControlsTop = (): JSX.Element => {
  const { editingTutorials, setEditingTutorial, selectedTutorial } = useContext(TutorialEditorContext);
  const { startTutorial, setTutorials } = useContext(TutorialContext);

  const editNew = useCallback(() => {
    setEditingTutorial(TutorialUtils.create());
  }, []);

  const editSelected = useCallback(() => {
    if (selectedTutorial !== null) {
      setEditingTutorial(selectedTutorial);
    }
  }, [selectedTutorial]);

  const startSelected = useCallback(() => {
    if (selectedTutorial !== null) {
      startTutorial(selectedTutorial);
    }
  }, [selectedTutorial]);

  const syncOut = useCallback(() => {
    setTutorials(editingTutorials);
  }, [editingTutorials]);

  return (
    <div className={styles.buttonRow}>
      <div className={styles.buttonRowInner}>
        <button type='button' className={styles.editorButton} onClick={editNew}>
          New
        </button>
        <button disabled={selectedTutorial === null} type='button' className={styles.editorButton} onClick={editSelected}>
          Edit
        </button>
        <button disabled={selectedTutorial === null} type='button' className={`${styles.editorButton} ${styles.altEditorButton}`} onClick={startSelected}>
          Start
        </button>
      </div>
      <div className={styles.buttonRowInner}>
        <button type='button' className={styles.editorButton} onClick={syncOut}>
          Sync Out
        </button>
      </div>
    </div>
  );
};

export default EditorControlsTop;
