import React, { useCallback, useContext, useMemo } from 'react';
import { TutorialContext } from '@gi/tutorial';
import { TutorialEditorContext } from '../tutorial-editor-context';

import styles from '../tutorial-editor.module.css';

interface iProps {
  jsonString: string;
  jsonStringModified: boolean;
}

const EditorJsonInputControls = ({ jsonString, jsonStringModified }: iProps): JSX.Element => {
  const { startTutorial } = useContext(TutorialContext);
  const { setEditingTutorial, saveTutorial, editingTutorial } = useContext(TutorialEditorContext);

  const validJson = useMemo<boolean>(() => {
    if (jsonString === '') {
      return false;
    }

    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }, [jsonString]);

  const save = useCallback(() => {
    saveTutorial(jsonString);
  }, [jsonString]);

  const run = useCallback(() => {
    if (editingTutorial && !jsonStringModified) {
      startTutorial(editingTutorial);
    }
  }, [startTutorial, editingTutorial, jsonStringModified]);

  const close = useCallback(() => {
    setEditingTutorial(null);
  }, []);

  return (
    <div className={styles.buttonRow}>
      <div className={styles.buttonRowInner}>
        <button disabled={!validJson} className={styles.editorButton} onClick={save} type='button'>
          Save
        </button>
        <button disabled={!validJson || jsonStringModified} className={`${styles.editorButton} ${styles.altEditorButton}`} onClick={run} type='button'>
          Run
        </button>
      </div>
      <div className={styles.buttonRowInner}>
        <button className={`${styles.editorButton} ${styles.negativeEditorButton}`} onClick={close} type='button'>
          Close
        </button>
      </div>
    </div>
  );
};

export default EditorJsonInputControls;
