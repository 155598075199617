import { Container, Sprite, Texture } from 'pixi.js-new';

import { State, StateDef } from '@gi/state';

import GraphicNode from '../../graphics-node';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { hasOwner } from '../../utils/asserts';
import { bindState } from '../../utils/state-utils';

export type ScalingSpriteComponentState = StateDef<{
  width: number;
  height: number;
  rotation: number;
}>;

const DEFAULT_STATE: ScalingSpriteComponentState['state'] = {
  width: 100,
  height: 100,
  rotation: 0,
};

/**
 * Scaling Sprite component
 * Creates a resizeable sprite
 */
class ScalingSpriteComponent extends NodeComponent<GraphicNode> {
  type = 'ScalingSpriteComponent';

  state: State<ScalingSpriteComponentState>;

  #container: Container;
  #texture: Texture | null = null;
  #sprite: Sprite | null = null;

  constructor(initialState: Partial<ScalingSpriteComponentState['state']> = {}) {
    super();

    this.#container = new Container();

    this.state = new State<ScalingSpriteComponentState>({
      ...DEFAULT_STATE,
      ...initialState,
    });
    bindState(this.state, this);
    this.state.addWatcher(() => this.#update());

    // Create sprite when we have a parent
    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
    this.eventBus.on(NodeComponentEvent.Destroyed, this.#onDestroy);
  }

  getContainer() {
    return this.#container;
  }

  #onBind = () => {
    hasOwner(this);
    this.#sprite = new Sprite();
    this.#sprite.anchor.set(0.5);
    if (this.#texture) {
      this.#sprite.texture = this.#texture;
    }
    this.#container.addChild(this.#sprite);
    this.#update();
  };

  #onBeforeUnbind = () => {
    hasOwner(this);
    if (this.#sprite) {
      this.#sprite.destroy();
      this.#sprite = null;
    }
  };

  #onDestroy = () => {
    this.#container.destroy();
  };

  #update() {
    if (!this.#sprite) {
      return;
    }

    const { width, height, rotation } = this.state.values;

    this.#sprite.width = width;
    this.#sprite.height = height;
    this.#sprite.rotation = rotation;
  }

  set texture(texture: Texture) {
    this.#texture = texture;
    if (this.#sprite) {
      this.#sprite.texture = texture;
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default ScalingSpriteComponent;
