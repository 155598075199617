import React, { useContext } from 'react';

import { PlannerControlsTab } from '@gi/constants';

import { PlannerControlsContext } from '../planner-controls-context';
import ObjectivesDrawer from './drawers/objectives-drawer';

const MobilePlannerControls = (): JSX.Element => {
  const { activeDrawer, setActiveDrawer } = useContext(PlannerControlsContext);

  // Add other drawers here as-needed.

  return <ObjectivesDrawer open={activeDrawer === PlannerControlsTab.OBJECTIVES} onClose={() => setActiveDrawer(null)} />;
};

export default MobilePlannerControls;
