import React, { useContext } from 'react';

import { TooltipKey } from '@gi/tooltip';

import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuButton from '../common/menu-button';
import { PlannerCommandContext } from '../../../planner-command-provider';
import FileMenuButtonGroup from '../common/parts/file-menu-button-group';

interface iProps {
  hideFileControls?: boolean;
}

const PartsListControls = ({ hideFileControls }: iProps): JSX.Element => {
  const { printPartsList } = useContext(PlannerCommandContext);

  return (
    <ControlGroup>
      <ControlRow>
        {!hideFileControls ? <FileMenuButtonGroup /> : null}
        <ControlButtonGroup>
          <MenuButton id='print-parts-list' onClick={printPartsList} tooltipKey={TooltipKey.PrintPartsList}>
            <i className='icon-print' />
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default PartsListControls;
