import React from 'react';
import { useDispatch } from 'react-redux';

import { MobileNavActionCreators } from '../../store/mobile-nav-slice';

import './mobile-topbar-menu-button.scss';

interface iProps {
  className?: string;
}

const MobileTopbarMenuButton = ({ className }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const openMenu = () => {
    dispatch(MobileNavActionCreators.setIsOpen(true));
  };

  const classNames = ['mobile-topbar-menu-button'];
  if (className) {
    classNames.push(className);
  }

  return (
    <button type='button' className={classNames.join(' ')} aria-label='Open App Navigation Menu' onClick={openMenu} data-identifier='mobile-topbar-menu-button'>
      <i className='icon-menu' />
    </button>
  );
};

export default MobileTopbarMenuButton;
