import { StatefulContextComponent } from '@gi/core-renderer';
import { DEFAULT_PLANNER_SETTINGS, PlannerSettings } from '@gi/plan';
import { StateDef } from '@gi/state';
import { imperialDistanceUnits, metricDistanceUnits } from '@gi/units';

export type PlanSettingsContextState = StateDef<PlannerSettings>;

class PlanSettingsContext extends StatefulContextComponent<PlanSettingsContextState> {
  type = 'PlanSettingsContext';

  constructor(initialValue?: Partial<PlannerSettings>) {
    super({ ...DEFAULT_PLANNER_SETTINGS, ...initialValue });
  }

  getDistanceUnits() {
    // Return metric by default (if true or undefined)
    if (this.state.values.metric !== false) {
      return metricDistanceUnits;
    }
    return imperialDistanceUnits;
  }
}

export default PlanSettingsContext;
