import React from 'react';

import styles from './tutorial-display.module.css';

interface iProps {
  children: React.ReactNode;
}

const PausedTutorialWindow = ({ children }: iProps): JSX.Element => {
  return (
    <div className={`${styles.baseTutorialWindow} ${styles.pausedTutorialWindow}`} style={{}}>
      {children}
    </div>
  );
};

export default PausedTutorialWindow;
