import { ExpressionUtils, InputExpression } from '@gi/expression';
import { randomUUID } from '@gi/utils';
import { TutorialDataJSONFormat } from './tutorial-data-json-format';
import { TutorialAttachment, TutorialStepContent } from './tutorial-base';

export type APITutorials = TutorialDataJSONFormat[];

export type TutorialEnabledCondition = {
  expression: InputExpression;
  stateVariables: string[];
};

export type TutorialRequirementCondition = {
  expression: InputExpression;
  stateVariables: string[];
  message: string;
};

export type TutorialData = {
  uuid: string;
  name: string;
  steps: TutorialStep[];
  enabled?: TutorialEnabledCondition[];
  requirements?: TutorialRequirementCondition[];
};

export type TutorialStepRequirement = {
  expression: InputExpression;
  failureMessage: string;
};

export type TutorialStep = {
  attachment?: TutorialAttachment;
  content: TutorialStepContent[];
  width?: number;
  requirements?: TutorialStepRequirement[];
  // skip?: ExpectedExpression; // If expression resolves to true, step will be skipped
  // required?: { expression: ExpectedExpression; failureMessage: string }[]; // For each expression, if expression resolves to false, step will show message content until the condition is true
};

export class TutorialDataUtils {
  static createEmpty(): TutorialData {
    return {
      uuid: randomUUID(),
      name: '',
      steps: [],
    };
  }

  static createFromJSONFormat(input: TutorialDataJSONFormat): TutorialData {
    const data: TutorialData = {
      uuid: input.uuid,
      name: input.name,
      steps: input.steps,
    };

    const enabled: TutorialEnabledCondition[] = input.enabled ? TutorialDataUtils.parseEnabledExpressions(input.enabled) : [];

    // Change displayMode property to an expression
    if (input.displayMode) {
      enabled.push({ expression: { op: '=', lh: input.displayMode, rh: 'var:displayMode' }, stateVariables: ['displayMode'] });
    }

    data.enabled = enabled;

    const requirements: TutorialRequirementCondition[] = input.requirements ? TutorialDataUtils.parseRequirementsExpressions(input.requirements) : [];
    data.requirements = requirements;

    return data;
  }

  static parseEnabledExpressions(enabled: unknown): TutorialEnabledCondition[] {
    const result: TutorialEnabledCondition[] = [];

    if (!Array.isArray(enabled)) {
      throw new Error('Invalid tutorial enabled expressions, expected array');
    }

    for (let i = 0; i < enabled.length; i++) {
      if (typeof enabled[i] !== 'object' || enabled[i] === null) {
        throw new Error('Invalid tutorial enabled expressions, expected object');
      }

      if (!('expression' in enabled[i]) || !('stateVariables' in enabled[i])) {
        throw new Error("Invalid tutorial enabled expressions, doesn't have expression or stateVariables properties");
      }

      if (!Array.isArray(enabled[i].stateVariables)) {
        throw new Error('Invalid tutorial enabled expressions, stateVariables not an array');
      }

      const watchProps = enabled[i].stateVariables;

      watchProps.forEach((item) => {
        if (typeof item !== 'string') {
          throw new Error('Invalid tutorial enabled expressions, invalided watch property type, expected string');
        }
      });

      const expectedExpression = enabled[i].expression;
      ExpressionUtils.validateIsInputExpression(expectedExpression);

      result.push({ expression: expectedExpression, stateVariables: watchProps });
    }

    return result;
  }

  static parseRequirementsExpressions(requirements: unknown): TutorialRequirementCondition[] {
    const result: TutorialRequirementCondition[] = [];

    if (!Array.isArray(requirements)) {
      throw new Error('Invalid tutorial requirements expressions, expected array');
    }

    for (let i = 0; i < requirements.length; i++) {
      if (typeof requirements[i] !== 'object' || requirements[i] === null) {
        throw new Error('Invalid tutorial enabled expressions, expected object');
      }

      if (!('expression' in requirements[i]) || !('stateVariables' in requirements[i]) || !('message' in requirements[i])) {
        throw new Error("Invalid tutorial requirements expressions, doesn't have expression, stateVariables or message properties");
      }

      if (!Array.isArray(requirements[i].stateVariables)) {
        throw new Error('Invalid tutorial requirements expressions, stateVariables not an array');
      }

      const watchProps = requirements[i].stateVariables;

      watchProps.forEach((item) => {
        if (typeof item !== 'string') {
          throw new Error('Invalid tutorial requirements expressions, invalided watch property type, expected string');
        }
      });

      if (typeof requirements[i].message !== 'string') {
        throw new Error('Invalid tutorial requirements expressions, invalided message property type, expected string');
      }

      const expectedExpression = requirements[i].expression;
      ExpressionUtils.validateIsInputExpression(expectedExpression);

      result.push({ expression: expectedExpression, stateVariables: watchProps, message: requirements[i].message });
    }

    return result;
  }

  static getTutorialDataJSONFormat(tutorialData: TutorialData): TutorialDataJSONFormat {
    return {
      ...tutorialData,
    };
  }
}
