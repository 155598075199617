import { GardenPlatformEvent } from '@gi/garden-platform-events';
import { LoadingState } from '@gi/constants';
import { ExpressionUtils } from '@gi/expression';

import {
  CompletedObjectiveFromAPI,
  ObjectiveConditionFromAPI,
  ObjectiveDataFromAPI,
  ObjectiveFromAPI,
  ObjectiveGroupFromAPI,
  ObjectiveSettingsFromAPI,
  Objective,
  ObjectiveCompletionData,
  ObjectiveCondition,
  ObjectiveGroup,
  ObjectiveSettings,
} from './objective-types';

const EVENT_NAME_STRINGS = Object.values(GardenPlatformEvent);

function convertCondition(conditionFromAPI: ObjectiveConditionFromAPI, objectiveId: string, index: number): ObjectiveCondition<GardenPlatformEvent> {
  if (!EVENT_NAME_STRINGS.includes(conditionFromAPI.eventName as GardenPlatformEvent)) {
    console.error(`Unrecognised objective event type: ${conditionFromAPI.eventName}`);
  }
  return {
    id: `${objectiveId}[${index}]`, // Conditions get a unique composite ID based on their owning objective
    objectiveId,
    eventName: conditionFromAPI.eventName as GardenPlatformEvent,
    parameters: conditionFromAPI.parameters,
    quantityRequired: conditionFromAPI.quantityRequired,
    quantityTracked: 0,
    completed: false,
  };
}

function convertObjective(objectiveFromAPI: ObjectiveFromAPI): { objective: Objective; conditions: ObjectiveCondition<GardenPlatformEvent>[] } {
  const { id } = objectiveFromAPI;
  const conditions = objectiveFromAPI.conditions.map((conditionFromAPI, i) => convertCondition(conditionFromAPI, id, i));
  const objective: Objective = {
    id,
    conditionIds: conditions.map((condition) => condition.id),
    title: objectiveFromAPI.title,
    description: objectiveFromAPI.description,
    icon: objectiveFromAPI.icon,
    tutorialName: objectiveFromAPI.tutorialName,
    enabledConditions: objectiveFromAPI.enabledConditions?.map(ExpressionUtils.convertExpectedExpressionToInputExpression),
    enabled: false,
  };

  // Validate enabled conditions are correct type and add to objective if present
  if (objectiveFromAPI.enabledConditions) {
    const enabledConditions = objectiveFromAPI.enabledConditions.map((expectedExpression) => {
      ExpressionUtils.validateIsInputExpression(expectedExpression);
      return expectedExpression;
    });
    objective.enabledConditions = enabledConditions;
  }

  return { objective, conditions };
}
function convertGroup(groupFromAPI: ObjectiveGroupFromAPI): ObjectiveGroup {
  return {
    id: groupFromAPI.id,
    title: groupFromAPI.title,
    description: groupFromAPI.description,
    icon: groupFromAPI.icon,
    objectiveIds: groupFromAPI.objectiveIds,
    isAdvanced: groupFromAPI.isAdvanced,
  };
}

function convertSettings(objectiveSettings: ObjectiveSettingsFromAPI): ObjectiveSettings {
  return {
    showOverallProgress: objectiveSettings.showOverallProgress,
    overallProgressTitle: objectiveSettings.overallProgressTitle,
    overallProgressDescription: objectiveSettings.overallProgressDescription,
    showHelpSection: objectiveSettings.showHelpSection,
    showHelpSectionLiveChatButton: objectiveSettings.showHelpSectionLiveChatButton,
    showHelpSectionVideoGuideButton: objectiveSettings.showHelpSectionVideoGuideButton,
    videoGuideButtonText: objectiveSettings.videoGuideButtonText,
    videoGuideButtonTutorialName: objectiveSettings.videoGuideButtonTutorialName,
    helpSectionTitle: objectiveSettings.helpSectionTitle,
    helpSectionDescription: objectiveSettings.helpSectionDescription,
  };
}

function evaluateObjectiveEnableConditions(objective: Readonly<Objective>, state: Readonly<Record<string, any>>) {
  let enabled = true;
  if (objective.enabledConditions) {
    for (let i = 0; i < objective.enabledConditions.length && enabled; i++) {
      enabled = ExpressionUtils.getExpectedBooleanExpressionResult(objective.enabledConditions[i], state);

      if (!enabled) {
        return false;
      }
    }
  }

  return true;
}

export function mutEvaluateObjectivesEnableConditions(objectivesMap: Record<string, Objective>, state: Record<string, any>): void {
  const keys = Object.keys(objectivesMap);

  for (let i = 0; i < keys.length; i++) {
    objectivesMap[keys[i]].enabled = evaluateObjectiveEnableConditions(objectivesMap[keys[i]], state);
  }
}

/**
 * Parses a list of completed objectives from the API
 * @param completedObjectiveFromAPI The list of completed objectives from the API
 * @returns A list of parsed completed objectives
 */
export function convertCompletedObjectiveFromAPI(completedObjectiveFromAPI: CompletedObjectiveFromAPI): ObjectiveCompletionData {
  return {
    id: completedObjectiveFromAPI.id,
    date: Date.parse(completedObjectiveFromAPI.date),
    syncState: LoadingState.SUCCESS,
  };
}

/**
 * Parses the objectives JSON from the API so we can track objectives
 * @param objectivesDataFromAPI The objectives JSON data
 * @returns An object containing the objectives, groups and settings, ready to use in the store
 */
export function convertObjectivesFromAPI(objectivesDataFromAPI: ObjectiveDataFromAPI) {
  const objectivesMap: Record<string, Objective> = {};
  const conditionsMap: Record<string, ObjectiveCondition<GardenPlatformEvent>> = {};
  const conditionEventMap: Partial<Record<GardenPlatformEvent, string[]>> = {};
  const groupsMap: Record<string, ObjectiveGroup> = {};
  const groupsOrder: string[] = [];

  for (let i = 0; i < objectivesDataFromAPI.objectives.length; i++) {
    const { objective, conditions } = convertObjective(objectivesDataFromAPI.objectives[i]);
    objectivesMap[objective.id] = objective;
    conditions.forEach((condition) => {
      conditionsMap[condition.id] = condition;
      conditionEventMap[condition.eventName] ??= [];
      conditionEventMap[condition.eventName]!.push(condition.id);
    });
  }

  for (let i = 0; i < objectivesDataFromAPI.groups.length; i++) {
    const group = convertGroup(objectivesDataFromAPI.groups[i]);
    groupsMap[group.id] = group;
    groupsOrder.push(group.id); // No sorting for now, use the order the groups are defined.
  }

  const settings = convertSettings(objectivesDataFromAPI.settings);

  return {
    objectivesMap,
    conditionsMap,
    conditionEventMap,
    groupsMap,
    groupsOrder,
    settings,
  };
}

/**
 * Converts a completed objective to a format the API accepts.
 * @param completedObjective The completed objective data
 * @returns An API-safe version of the completed objective
 */
export function completedObjectiveToAPI(completedObjective: ObjectiveCompletionData): CompletedObjectiveFromAPI {
  return {
    id: completedObjective.id,
    date: new Date(completedObjective.date).toISOString(),
  };
}

/**
 * Deep-checks if the given item contains all the same properties as `mustContain`. Will pass if mustContain is undefined/empty
 * @param item The item to check
 * @param mustContain The properties the item must contains
 * @returns Returns true if all properties are found (or mustContain is empty)
 */
export function checkEventParameters<T>(item: T, mustContain?: Partial<T>): boolean {
  if (mustContain === undefined) {
    return true;
  }
  if (typeof item === 'object' && item !== null) {
    const keys = Object.keys(mustContain);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      // Item doesn't contain required key
      if (!(key in item)) {
        return false;
      }
      if (typeof item[key] === 'object') {
        // Deep-check event parameters, and fail if this key fails
        if (!checkEventParameters(item[key], mustContain[key])) {
          return false;
        }
      } else if (item[key] !== mustContain[key]) {
        // Simple comparison for non-objects
        return false;
      }
    }
    return true;
  }
  return item === mustContain;
}

/**
 * Utility function to create a list of objective status update tuples
 * @param ids The list of ids to update the status of
 * @param status The new status to use
 * @returns A list of tuples to pass to the store action creator
 */
export function getObjectiveUpdateTuples(ids: string[], status: LoadingState): { id: string; status: LoadingState }[] {
  return ids.map((id) => ({ id, status }));
}
