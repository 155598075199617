import React from 'react';
import { useDispatch } from 'react-redux';
import Plan from '@gi/plan';
import { PlannerTabTypes } from '@gi/constants';
import { PlannerActionCreators } from '@gi/app-planner-slice';

import TopMenuButton from '../components/top-menu-button';

import styles from './desktop-planner-tabs.module.css';

interface iProps {
  children: React.ReactNode;
  active: boolean;
  activePlan: Plan | null;
  tab: PlannerTabTypes;
}

const DesktopPlannerTab = ({ children, active, activePlan, tab }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const onClick = () => {
    if (activePlan !== null && !active) {
      dispatch(PlannerActionCreators.setActivePlannerTab({ planID: activePlan.id, tabID: tab }));
    }
  };

  return (
    <div className={`${styles.desktopPlannerTab} ${active ? styles.activeDesktopPlannerTab : ''}`}>
      <TopMenuButton type='button' onClick={onClick} data-identifier={`tab-${tab.toLowerCase().replaceAll('_', '-')}-button`}>
        {children}
      </TopMenuButton>
    </div>
  );
};

export default DesktopPlannerTab;
