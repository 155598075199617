import React, { ReactNode } from 'react';

import './overlay-toolbar-button.scss';

interface iProps {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  identifier?: string;
}

const OverlayToolbarButton = ({ children, onClick, disabled = false, className, identifier }: iProps): JSX.Element => {
  const classNames = ['mobile-overlay-toolbar-button'];
  if (className) {
    classNames.push(className);
  }

  return (
    <button className={classNames.join(' ')} type='button' onClick={onClick} disabled={disabled} data-identifier={identifier}>
      {children}
    </button>
  );
};

export default OverlayToolbarButton;
