import React, { CSSProperties, ReactNode } from 'react';

import './selectable-item.scss';

export interface iSelectableItemTag {
  name: string;
  className?: string;
}

interface iProps {
  icon: ReactNode;
  name: ReactNode;
  otherName?: ReactNode;
  tags?: iSelectableItemTag[];
  buttons?: ReactNode[];
  statusIcons?: ReactNode[];
  selected?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick: () => void;
  isInGroup?: boolean;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  identifier?: string;
}

const SelectableItem = ({
  icon,
  name,
  otherName,
  tags,
  buttons,
  statusIcons,
  selected = false,
  className,
  style,
  onClick,
  isInGroup,
  isFirstInGroup,
  isLastInGroup,
  identifier,
}: iProps): JSX.Element => {
  const classNames = ['mobile-drawing-tools-row'];
  if (selected) {
    classNames.push('selected');
  }
  if (isInGroup) {
    classNames.push('is-in-group');
  }
  if (isFirstInGroup) {
    classNames.push('is-first');
  }
  if (isLastInGroup) {
    classNames.push('is-last');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} style={style} data-identifier={identifier}>
      {buttons ? <div className='buttons'>{buttons}</div> : null}
      <button className='main' type='button' onClick={() => onClick()} data-identifier={identifier ? `${identifier}-button` : undefined}>
        <div className='icon'>{icon}</div>
        <div className='content'>
          <span className='primary'>{name}</span>
          {otherName ? <span className='secondary'>{otherName}</span> : null}
        </div>
        {statusIcons ? <div className='status-icons'>{statusIcons}</div> : null}
      </button>
      <div className='tags'>
        {tags?.map((tag, i) => (
          <span className={tag.className} key={i}>
            {tag.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default SelectableItem;
