import { ExpressionUtils, Type } from '@gi/expression';
import { TutorialData, TutorialDataUtils, TutorialEnabledCondition } from './tutorial-data';
import { TutorialDataJSONFormat } from './tutorial-data-json-format';

export type Tutorial = {
  data: TutorialData;
  enabled: boolean;
};

export class TutorialUtils {
  static create(enabled: boolean = false) {
    return {
      data: TutorialDataUtils.createEmpty(),
      enabled,
    };
  }

  /**
   * Creates a Tutorial from TutorialData
   *
   * Note: Does not evaluate any enabled expressions, defaults to not enabled
   */
  static createFromTutorialData(tutorialData: TutorialData): Tutorial {
    return {
      data: tutorialData,
      enabled: false,
    };
  }

  static evaluateTutorialEnabled(tutorial: Tutorial, state: Record<string, any>): Tutorial {
    if (!('enabled' in tutorial.data || tutorial.data.enabled === undefined)) {
      return {
        ...tutorial,
        enabled: true,
      };
    }

    const enabledExpressions = tutorial.data.enabled as TutorialEnabledCondition[];
    // Tutorials without enabled expressions are always enabled
    if (enabledExpressions.length === 0) {
      return {
        ...tutorial,
        enabled: true,
      };
    }

    // Go through each condition, all conditions need to evaluate to true for this tutorial to be enabled
    for (let i = 0; i < enabledExpressions.length; i++) {
      const expressionResult = ExpressionUtils.getExpressionResult(enabledExpressions[i].expression, state);
      if (!expressionResult.success) {
        console.error('Failed to parse expression: ', expressionResult.error);
        return {
          ...tutorial,
          enabled: false,
        };
      }

      // Make sure expressions result in a boolean, if they do not they are not valid as enabled expressions
      if (expressionResult.result[1] !== Type.Boolean) {
        console.error('Tutorial enabled expression returned incorrect type (expected boolean): ', expressionResult.result[1]);
        return {
          ...tutorial,
          enabled: false,
        };
      }

      // This expression evaluated as false
      if (!expressionResult.result[0]) {
        return {
          ...tutorial,
          enabled: false,
        };
      }
    }

    return {
      ...tutorial,
      enabled: true,
    };
  }

  static getTutorialData(tutorial: Tutorial): TutorialData {
    return tutorial.data;
  }

  static getTutorialJSONFormat(tutorial: Tutorial): TutorialDataJSONFormat {
    return structuredClone(tutorial.data);
  }
}
