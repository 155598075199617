const DEFAULT_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '34px',
    minWidth: '180px',
    boxShadow: 'none',
    outline: state?.isFocused ? '#2684ff solid 1px' : 'none',
    borderColor: `var(--input-border-colour, ${provided.borderColor})`,
    '&:hover': {
      borderColor: `var(--input-border-colour, ${provided.borderColor})`,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px',
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
    margin: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
};

const MONTH_SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    borderRadius: '3px',
    minHeight: '34px',
    minWidth: '126px',
  }),
};

const INLINE_SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  container: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    display: 'inline-block',
  }),
};

const SMALL_SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    borderRadius: '3px',
    minHeight: '34px',
    minWidth: '80px',
    width: '80px',
  }),
};

export default DEFAULT_SELECT_STYLES;

export { DEFAULT_SELECT_STYLES, MONTH_SELECT_STYLES, INLINE_SELECT_STYLES, SMALL_SELECT_STYLES };
