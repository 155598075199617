import React from 'react';
import MenuBar from '../common/menu-bar';
import MenuBarGroup from '../common/menu-bar-group';

import PartsListControls from './parts-list-controls';

interface iProps {
  hideFileControls?: boolean;
}

const DesktopPartsListMenu = ({ hideFileControls }: iProps): JSX.Element => {
  return (
    <MenuBar>
      <MenuBarGroup title='Parts List'>
        <PartsListControls hideFileControls={hideFileControls} />
      </MenuBarGroup>
    </MenuBar>
  );
};

export default DesktopPartsListMenu;
