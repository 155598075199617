import React, { useContext } from 'react';
import { ModalPane, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { FeaturesContext } from '@gi/features';

/**
 * Debug Modal Tab for providing a simple interface to get a random UUID
 */
const FeatureFlagsTab = (): JSX.Element => {
  const { featureIds } = useContext(FeaturesContext);
  return (
    <ModalPaneContainer>
      <ModalPane>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <div>
              {featureIds.map((feature) => {
                return <div key={feature}>{feature}</div>;
              })}
            </div>
          </ModalPaneSectionContent>
        </ModalPaneSection>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default FeatureFlagsTab;
