import { GardenPlatformState } from '@gi/garden-platform-state';
import { Tutorial, TutorialUtils } from './tutorial';
import { APITutorials, TutorialDataUtils } from './tutorial-data';

export type Tutorials = {
  byUuid: Record<string, Tutorial>;
  uuids: string[];
  byName: Record<string, string[]>; // name -> uuid[]
  names: string[];
  allEnabledWatchedProps: string[];
};

export class TutorialsUtils {
  static create(): Tutorials {
    return {
      byUuid: {},
      uuids: [],
      byName: {},
      names: [],
      allEnabledWatchedProps: [],
    };
  }

  static hasTutorial(tutorials: Tutorials, uuid: string): boolean {
    return tutorials.uuids.includes(uuid);
  }

  static getTutorial(tutorials: Tutorials, uuid: string): Tutorial | null {
    if (!TutorialsUtils.hasTutorial(tutorials, uuid)) {
      return null;
    }

    return tutorials.byUuid[uuid];
  }

  /**
   * Note: will not update allEnabledWatchedProps
   *
   * TODO: Update name
   */
  static updateTutorial(tutorials: Tutorials, tutorial: Tutorial): Tutorials {
    if (!TutorialsUtils.hasTutorial(tutorials, tutorial.data.uuid)) {
      throw new Error("Cannot update tutorial  which isn't present");
    }

    return {
      ...tutorials,
      byUuid: {
        ...tutorials.byUuid,
        [tutorial.data.uuid]: tutorial,
      },
    };
  }

  static addTutorial(tutorials: Tutorials, tutorial: Tutorial): Tutorials {
    if (TutorialsUtils.hasTutorial(tutorials, tutorial.data.uuid)) {
      throw new Error(`A tutorial with this UUID already exists: ${tutorial.data.uuid}`);
    }

    const nameRecord = { ...tutorials.byName };
    const names = [...tutorials.names];

    if (!nameRecord[tutorial.data.name]) {
      names.push(tutorial.data.name);
      nameRecord[tutorial.data.name] = [];
    }

    nameRecord[tutorial.data.name].push(tutorial.data.uuid);

    return {
      ...tutorials,
      uuids: [...tutorials.uuids, tutorial.data.uuid],
      byUuid: {
        ...tutorials.byUuid,
        [tutorial.data.uuid]: tutorial,
      },
      byName: nameRecord,
      names,
    };
  }

  static getTutorialByName(tutorials: Tutorials, name: string): Tutorial | null {
    if (!tutorials.byName[name]) {
      console.error("Attmpted to get tutorial by name which doesn't exist");
      return null;
    }

    const enabledTutorials = tutorials.byName[name].map((uuid) => tutorials.byUuid[uuid]).filter((tutorial) => tutorial.enabled);

    if (enabledTutorials.length === 0) {
      console.debug('Found no enabled tutorial with given name');
      return null;
    }

    if (enabledTutorials.length > 1) {
      console.warn('Found more than one enabled tutorial of the given name');
    }

    return enabledTutorials[0];
  }

  static updateTutorialsEnabledConditions(tutorials: Tutorials, state: GardenPlatformState): Tutorials {
    let updatedTutorials = tutorials;
    tutorials.uuids
      .map((uuid) => tutorials.byUuid[uuid])
      .forEach((tutorial) => {
        const updatedTutorial = TutorialUtils.evaluateTutorialEnabled(tutorial, state);

        if (updatedTutorial.enabled !== tutorial.enabled) {
          updatedTutorials = TutorialsUtils.updateTutorial(updatedTutorials, updatedTutorial);
        }
      });

    return updatedTutorials;
  }

  static getTutorialsJson(tutorials: Tutorials): APITutorials {
    return tutorials.uuids.map((uuid) => tutorials.byUuid[uuid]).map((tutorial) => TutorialDataUtils.getTutorialDataJSONFormat(tutorial.data));
  }
}
