import { createContext } from 'react';

export type DebugModalContextType = {
  expressionText: string;
  setExpressionText: (text: string) => void;
  showExpressionResult: boolean;
  setShowExpressionResult: (showExpressionResult: boolean) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const DebugModalContext = createContext<DebugModalContextType>({} as DebugModalContextType);
