import React from 'react';

import { TooltipKey } from '@gi/tooltip';
import { SidebarButton, SidebarButtonIcon, SidebarButtons } from '@gi/planner-sidebar';

import { TAB_CATEGORIES, TAB_ICONS } from './constants';

const TooltipText: Record<keyof typeof TAB_CATEGORIES, TooltipKey> = {
  [TAB_CATEGORIES.PLANTS]: TooltipKey.DrawingToolsPlants,
  [TAB_CATEGORIES.GARDEN_OBJECTS]: TooltipKey.DrawingToolsGardenObjects,
  [TAB_CATEGORIES.SHAPES]: TooltipKey.DrawingToolsShapes,
  [TAB_CATEGORIES.TEXT]: TooltipKey.DrawingToolsText,
};

interface iProps {
  tabCategory: string;
  identifier?: string;
}

const DrawingToolTab = ({ tabCategory, identifier }: iProps) => {
  return (
    <SidebarButton tabId={tabCategory} tooltipKey={TooltipText[tabCategory]} data-identifier={identifier}>
      <SidebarButtonIcon icon={TAB_ICONS[tabCategory]} />
    </SidebarButton>
  );
};

const DrawingToolsTabs = () => {
  return (
    <SidebarButtons>
      <DrawingToolTab identifier='plants-drawing-tools-button' tabCategory={TAB_CATEGORIES.PLANTS} />
      <DrawingToolTab identifier='garden-object-drawing-tools-button' tabCategory={TAB_CATEGORIES.GARDEN_OBJECTS} />
      <DrawingToolTab identifier='shapes-drawing-tools-button' tabCategory={TAB_CATEGORIES.SHAPES} />
      <DrawingToolTab identifier='text-drawing-tools-button' tabCategory={TAB_CATEGORIES.TEXT} />
    </SidebarButtons>
  );
};

export default DrawingToolsTabs;
