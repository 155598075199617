import React, { useContext } from 'react';

import { Sidebar, SidebarButton, SidebarButtonIcon, SidebarButtons, SidebarHeader, SidebarHeaderToggleButton } from '@gi/planner-sidebar';
import { Direction, PlannerControlsTab } from '@gi/constants';
import { TooltipKey } from '@gi/tooltip';

import SidebarNotesTabContent from './content/notes-tab-content';
import { PlannerControlsContext } from '../planner-controls-context';
import SidebarObjectivesTabContent from './content/objectives-tab-content';

import styles from './desktop-planner-controls.module.css';

const DesktopPlannerControls = (): JSX.Element => {
  const { activeTab, setActiveTab, minimised, setMinimised } = useContext(PlannerControlsContext);

  return (
    <Sidebar
      minimised={minimised}
      setMinimised={setMinimised}
      initiallyActiveTab={PlannerControlsTab.NOTES}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      orientation={Direction.RIGHT}
      className={styles.container}
      identifier='sidebar'
    >
      {/* Header */}
      <SidebarHeader>
        <SidebarHeaderToggleButton />
      </SidebarHeader>
      {/* Tab buttons */}
      <SidebarButtons>
        <SidebarButton tabId={PlannerControlsTab.OBJECTIVES} tooltipKey={TooltipKey.RightSidebarObjectives} data-identifier='learn-to-use-sidebar-button'>
          <SidebarButtonIcon icon='icon-mortar-board' />
        </SidebarButton>
        <SidebarButton tabId={PlannerControlsTab.NOTES} tooltipKey={TooltipKey.RightSidebarNotes} data-identifier='notes-sidebar-button'>
          <SidebarButtonIcon icon='icon-edit-notes' />
        </SidebarButton>
      </SidebarButtons>
      {/* Content */}
      <SidebarObjectivesTabContent />
      <SidebarNotesTabContent />
    </Sidebar>
  );
};

export default DesktopPlannerControls;
