import React from 'react';

import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import FileMenuButtonGroup from '../common/parts/file-menu-button-group';

interface iProps {
  hideFileControls?: boolean;
}

const ShoppingControls = ({ hideFileControls }: iProps): JSX.Element => {
  return (
    <ControlGroup>
      <ControlRow>{!hideFileControls ? <FileMenuButtonGroup /> : null}</ControlRow>
    </ControlGroup>
  );
};

export default ShoppingControls;
