import React, { useContext, useMemo } from 'react';
import { useWatchedExpression } from '@gi/garden-platform-state';
import { StatusBarSection } from '@gi/status-bar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@gi/tooltip';

import styles from './footer-expression.module.css';
import { DebugModalContext } from '../debug-modal-context';

const FooterExpressionResult = (): JSX.Element => {
  const { expressionText } = useContext(DebugModalContext);

  const expression = useMemo(() => {
    try {
      return JSON.parse(expressionText);
    } catch (e) {
      return {};
    }
  }, [expressionText]);

  const result = useWatchedExpression(expression);

  return (
    <StatusBarSection>
      <Tooltip placement='top'>
        <TooltipTrigger asWrapper>
          <div className={styles.footerExpression}>
            {result.success ? (
              <>
                <div className={styles.indicatorContainer}>
                  <div className={`${styles.indicator} ${styles.successIndicator}`} />
                </div>
                <div className={styles.expressionResult}>
                  <div className={styles.expressionValue}>{JSON.stringify(result.result[0])}</div>
                  <div className={styles.expressionValueType}>{JSON.stringify(result.result[1])}</div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.indicatorContainer}>
                  <div className={`${styles.indicator} ${styles.failIndicator}`} />
                </div>
                <div>{result.error}</div>
              </>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent>{expressionText}</TooltipContent>
      </Tooltip>
    </StatusBarSection>
  );
};

export default FooterExpressionResult;
