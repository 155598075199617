import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DeviceDisplayMode } from '@gi/constants';

import {
  GardenPlatformStateContext,
  GardenPlatformStateContextType,
  QueryPlatformState,
  SetPlatformState,
  WatchPlatformState,
} from './garden-platform-state-context';
import { GardenPlatformState } from './garden-platform-state';

type StateValueWatchers = {
  [Key in keyof GardenPlatformState]: (() => void)[];
};

const DEFAULT_WATCHERS: StateValueWatchers = {
  activePlanId: [],
  activePlanTab: [],
  activeApplication: [],
  displayMode: [],
  featureFlags: [],
  initialised: [],
};

const DEFAULT_STATE: GardenPlatformState = {
  activePlanId: null,
  activePlanTab: null,
  activeApplication: null,
  displayMode: DeviceDisplayMode.DESKTOP,
  featureFlags: [],
  initialised: false,
};

interface iProps {
  children: React.ReactNode;
}

export const GardenPlatformStateProvider = ({ children }: iProps): JSX.Element => {
  const state = useRef<GardenPlatformState>({ ...DEFAULT_STATE });
  // Watchers are an array of callbacks, mapped by the properties they watch
  const watchers = useRef<StateValueWatchers>({ ...DEFAULT_WATCHERS });
  // Global watchers are an array of callbacks to be executed when any state values are changed
  const globalWatchers = useRef<(() => void)[]>([]);

  const frameRequested = useRef<boolean>(false);
  const changedProps = useRef<Set<string>>(new Set());

  const broadcastChanges = useCallback(() => {
    // Go through all changed props and get a set of callbacks that need to be called
    const callbacks: Set<() => void> = new Set();
    const changedPropsList = [...changedProps.current];

    if (changedPropsList.length === 0) {
      // No changes, not sure if this can happen
      return;
    }

    for (let i = 0; i < changedPropsList.length; i++) {
      const prop = changedPropsList[i];
      const callbacksForProp = watchers.current[prop];
      if (callbacksForProp) {
        for (let j = 0; j < callbacksForProp.length; j++) {
          callbacks.add(callbacksForProp[j]);
        }
      }
    }

    // Execute all callbacks
    const callbackList = [...callbacks, ...globalWatchers.current];
    for (let i = 0; i < callbackList.length; i++) {
      callbackList[i]();
    }

    // Reset values for next changeset
    changedProps.current = new Set();
    frameRequested.current = false;
  }, []);

  const onPropChange = useCallback((prop: string) => {
    if (!frameRequested.current) {
      requestAnimationFrame(broadcastChanges);
      frameRequested.current = true;
    }

    changedProps.current.add(prop);
  }, []);

  useEffect(() => {
    const handler = {
      set(obj, prop, value) {
        if (obj[prop] !== value) {
          onPropChange(prop);
        }
        return Reflect.set(obj, prop, value);
      },
    };

    state.current = new Proxy(state.current, handler);
  }, []);

  const query: QueryPlatformState = useCallback(() => {
    return state.current;
  }, []);

  // Updates platform state values
  const set: SetPlatformState = useCallback((values) => {
    Object.keys(values).forEach((key) => {
      state.current[key] = values[key];
    });
  }, []);

  const watch: WatchPlatformState = useCallback((callback, keys) => {
    const wrappedCallback = () => {
      callback(state.current); // TODO
    };

    if (!keys || keys.length === 0) {
      globalWatchers.current.push(wrappedCallback);

      return () => {
        const index = globalWatchers.current.indexOf(wrappedCallback);
        if (index !== -1) {
          globalWatchers.current.slice(index, 1);
        }
      };
    }

    keys.forEach((key) => {
      watchers.current[key].push(wrappedCallback);
    });

    // Return a callback which removes the callback from the list of watchers
    return () => {
      keys.forEach((key) => {
        const index = watchers.current[key].indexOf(wrappedCallback);
        if (index !== -1) {
          watchers.current[key].slice(index, 1);
        }
      });
    };
  }, []);

  const value = useMemo<GardenPlatformStateContextType>(
    () =>
      ({
        query,
        set,
        watch,
      }) as unknown as GardenPlatformStateContextType,
    []
  );

  return <GardenPlatformStateContext.Provider value={value}>{children}</GardenPlatformStateContext.Provider>;
};
