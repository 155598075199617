import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ModalPane, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { GardenPlatformStateKeys, useWatchedPlatformState } from '@gi/garden-platform-state';
import { ExpressionUtils } from '@gi/expression';
import ExpressionResult from './expression-result';
import ExpressionError from './expression-error';

import styles from './expression-tab.module.css';
import { DebugModalContext } from '../../debug-modal-context';

const ExpressionTab = (): JSX.Element => {
  const { expressionText, setExpressionText, showExpressionResult, setShowExpressionResult } = useContext(DebugModalContext);
  const [expressionError, setExpressionError] = useState<string>('');
  // The result of an expression might be null in future so we can't use null as
  // a non-op
  const [expressionHasResult, setExpressionHasResult] = useState<boolean>(false);
  const [expressionResult, setExpressionResult] = useState<any>(undefined);

  const state = useWatchedPlatformState(GardenPlatformStateKeys);

  const runExpression = useCallback(() => {
    setExpressionResult(null);
    setExpressionError('');
    setExpressionHasResult(false);
    try {
      const expression = JSON.parse(expressionText);
      const [result] = ExpressionUtils.evaluateExpectedExpression(expression, state);
      setExpressionResult(result);
      setExpressionHasResult(true);
    } catch (e) {
      setExpressionError(e.message);
    }
  }, [expressionText, state]);

  // On tab open, run expression if it is not blank
  useEffect(() => {
    if (expressionText.trim().length > 0) {
      runExpression();
    }
  }, []);

  /**
   * Changes an object in JavaScript form into JSON form as when testing objects are frequently copied from
   * JS Code but the expression has to be JSON
   */
  const JSONify = useCallback(() => {
    try {
      // eslint-disable-next-line no-eval
      setExpressionText(eval(`JSON.stringify(${expressionText})`));
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Failed to JSONify');
    }
  }, [expressionText]);

  return (
    <ModalPaneContainer>
      <ModalPane>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <div className={styles.expressionInput}>
              <textarea value={expressionText} onChange={(e) => setExpressionText(e.target.value)} />
            </div>
            <div className={styles.expressionControls}>
              <div>
                <button type='button' onClick={runExpression}>
                  Run Expression
                </button>
                <button type='button' onClick={JSONify}>
                  JSONify
                </button>
              </div>
              <div>
                <label>
                  <input type='checkbox' checked={showExpressionResult} onChange={(e) => setShowExpressionResult(e.target.checked)} /> Pin Expression To Footer
                </label>
              </div>
            </div>
          </ModalPaneSectionContent>
        </ModalPaneSection>
        <div className={styles.expressionResultContainer}>
          {expressionHasResult ? <ExpressionResult result={expressionResult} /> : null}
          {expressionError ? <ExpressionError error={expressionError} /> : null}
        </div>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default ExpressionTab;
