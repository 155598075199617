import React from 'react';

import FiltersButton from './filters-button';
import Searchbar from './searchbar';

interface iProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onFiltersClick: () => void;
  activeFilterCount?: number;
  identifier?: string;
}

const SearchbarWithFilters = ({ placeholder, value, onChange, onFiltersClick, activeFilterCount, identifier }: iProps): JSX.Element => {
  return (
    <Searchbar
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      filterComponent={
        <FiltersButton onClick={onFiltersClick} activeFilterCount={activeFilterCount} identifier={identifier ? `${identifier}-filters` : undefined} />
      }
      identifier={identifier}
    />
  );
};

export default SearchbarWithFilters;
