import { State, StateDef } from '@gi/state';
import Bitmask, { BitmaskComparisonMode, BitmaskType } from '@gi/bitmask';

import { bindState } from '../../utils/state-utils';
import NodeComponent from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';

export enum DisplayModeFlag {
  UNLOCKED = 1,
  LOCKED = 2,
  LAYER_PLANTS = 3,
  LAYER_STRUCTURES = 4,
  LAYER_IRRIGATION = 5,
  LAYER_TEXT = 6,
  LAYER_LAYOUT = 7,
  LAYER_PLANT_LABELS = 8,
}

export type DisplayModeComponentContextState = StateDef<{
  /** Components with ANY of these flags will be displayed as view-only */
  viewOnlyFlags: BitmaskType<DisplayModeFlag>;
  viewOnlyFlagsComparisonMode: BitmaskComparisonMode;
  /** Components with ANY of these flags will be displayed as disabled */
  disabledFlags: BitmaskType<DisplayModeFlag>;
  disabledFlagsComparisonMode: BitmaskComparisonMode;
}>;

const DEFAULT_STATE: DisplayModeComponentContextState['state'] = {
  viewOnlyFlags: Bitmask.NONE.value,
  viewOnlyFlagsComparisonMode: BitmaskComparisonMode.ContainsAny,
  disabledFlags: Bitmask.NONE.value,
  disabledFlagsComparisonMode: BitmaskComparisonMode.ContainsAny,
};

/**
 * Context for controlling the display mode of any nodes with a DisplayModeComponent
 *
 * @example
 * // Disable all items not marked as plants
 * const context = new DisplayModeContext();
 * context.state.disabledFlags = Bitmask.Create(DisplayModeFlag.LAYER_PLANTS);
 * context.state.disabledFlagsComparisonMode = BitmaskComparisonMode.NotContainsAny;
 *
 * @example
 * // Disable all locked structures
 * const context = new DisplayModeContext();
 * context.state.disabledFlags = Bitmask.Create(DisplayModeFlag.LAYER_STRUCTURES, DisplayModeFlag.LOCKED);
 * context.state.disabledFlagsComparisonMode = BitmaskComparisonMode.ContainsAll;
 */
class DisplayModeComponentContext extends NodeComponent {
  type = 'DisplayModeComponentContext';

  readonly state: State<DisplayModeComponentContextState>;

  constructor() {
    super();

    this.state = new State({ ...DEFAULT_STATE });
    bindState(this.state, this);
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default DisplayModeComponentContext;
