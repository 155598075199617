import React, { ReactNode } from 'react';

import './filters-button.scss';

interface iProps {
  children?: ReactNode;
  activeFilterCount?: number;
  onClick: () => void;
  identifier?: string;
}

const FiltersButton = ({ children, activeFilterCount, onClick, identifier }: iProps): JSX.Element => {
  return (
    <button type='button' className='filters-button' onClick={onClick} data-identifier={identifier}>
      {children ? children : 'Filters '}
      {activeFilterCount ? `(${activeFilterCount})` : null}
    </button>
  );
};

export default FiltersButton;
