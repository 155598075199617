import { Geometry, Matrix } from '@gi/math';
import { GardenObjectType } from '@gi/constants';
import { EditSimulatedGardenItem } from './edit-simulated-garden-item';
import { SimulatedGardenObject } from '../simulated-garden-object';
import { GardenObjectSnapUtils } from '../snap-utils';

class EditSimulatedGardenObject extends EditSimulatedGardenItem<SimulatedGardenObject> {
  startPos: Vector2;
  midPos: Vector2 | null;
  endPos: Vector2;
  rotation: number;
  centerPos: Vector2;

  start(): void {
    this.startPos = { ...this.target.start };
    this.midPos = this.target.mid !== null ? { ...this.target.mid } : null;
    this.endPos = { ...this.target.end };
    this.rotation = this.target.rotation;
    this.centerPos = { ...this.target.center };
  }

  translate(positionOffset: Vector2): void {
    this.target.setPosition(
      Geometry.addPoint(this.startPos, positionOffset),
      this.midPos !== null ? Geometry.addPoint(this.midPos, positionOffset) : null,
      Geometry.addPoint(this.endPos, positionOffset),
      this.rotation
    );
  }

  rotate(angle: number, pivot: Vector2, matrix: number[][]): void {
    if (this.target.gardenObject.shape.type === GardenObjectType.PATH) {
      // Lines don't support rotation, so we need to bake it into the 3 points.
      const point1 = Matrix.multiplyVector(this.startPos, matrix);
      const point2 = this.midPos !== null ? Matrix.multiplyVector(this.midPos, matrix) : null;
      const point3 = Matrix.multiplyVector(this.endPos, matrix);

      this.target.setPosition(point1, point2, point3, 0);
    } else {
      const center = Matrix.multiplyVector(this.centerPos, matrix);
      const point1 = Geometry.addPoint(Geometry.getPointDelta(this.centerPos, this.startPos), center);
      const point2 = this.midPos !== null ? Geometry.addPoint(Geometry.getPointDelta(this.centerPos, this.midPos), center) : null;
      const point3 = Geometry.addPoint(Geometry.getPointDelta(this.centerPos, this.endPos), center);

      this.target.setPosition(point1, point2, point3, this.rotation + angle);
    }
  }

  manipulate(start: Vector2, mid: Vector2 | null, end: Vector2, rotation: number = 0) {
    this.target.setPosition(start, mid, end, rotation);
  }

  transform(center: Vector2, dimensions: Dimensions, rotation: number): void {
    const start: Vector2 = {
      x: center.x - dimensions.width / 2,
      y: center.y - dimensions.height / 2,
    };
    const end: Vector2 = {
      x: center.x + dimensions.width / 2,
      y: center.y + dimensions.height / 2,
    };
    this.target.setPosition(start, null, end, rotation);
  }

  snapTranslate(snapDistance: number): void {
    const { start, mid, end } = GardenObjectSnapUtils.snapTranslate(this.target.start, this.target.mid, this.target.end, snapDistance);

    this.target.setPosition(start, mid, end, this.target.rotation);
  }

  snapTransform(snapDistance: number): void {
    const { start, mid, end } = GardenObjectSnapUtils.snapTransform(this.target.start, this.target.mid, this.target.end, snapDistance);

    this.target.setPosition(start, mid, end, this.target.rotation);
  }

  // eslint-disable-next-line class-methods-use-this
  end(): void {
    // Do nothing
  }
}

export default EditSimulatedGardenObject;
