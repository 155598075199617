import { TS_TYPE_TYPE, Type } from './base-types';

function validateArgs<U>(args: unknown[], type: TS_TYPE_TYPE | TS_TYPE_TYPE[], length?: number): asserts args is U extends [] ? U : U[] {
  if (length !== undefined) {
    if (args.length !== length) {
      throw new Error('Invalid arg count');
    }
  }

  if (Array.isArray(type)) {
    if (type.length === 0) {
      throw new Error('Invalid type');
    }
    // Assert all args match type at same index, if the length of the
    // args exceeds the length of the type it uses the last item of the
    // type array. So ['number', 'string'] can be used to validate, [1, 'str', 'str', 'str']
    for (let i = 0; i < args.length; i++) {
      // eslint-disable-next-line valid-typeof
      if (typeof args[i] !== type[Math.min(i, type.length - 1)]) {
        throw new Error('Invalid arg type');
      }
    }
  } else {
    // Assert all args have type
    for (let i = 0; i < args.length; i++) {
      // eslint-disable-next-line valid-typeof
      if (typeof args[i] !== type) {
        throw new Error('Invalid arg type');
      }
    }
  }
}

export const ExpressionFunctions = {
  MathMin: (...args: unknown[]): number => {
    validateArgs<number>(args, 'number');
    return Math.min(...args);
  },
  MathMax: (...args: unknown[]): number => {
    validateArgs<number>(args, 'number');
    return Math.max(...args);
  },
  ArrayContains: (item: unknown, array: unknown[]) => {
    return array.includes(item);
  },
} as const;

export const FunctionReturnTypes: Record<keyof typeof ExpressionFunctions, Type> = {
  MathMin: Type.Number,
  MathMax: Type.Number,
  ArrayContains: Type.Boolean,
};
