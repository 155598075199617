/**
 * Generates a CSV string containing the given list of objects as rows
 * @see {@link https://datatracker.ietf.org/doc/html/rfc4180#section-2} for the "spec"
 * @param objects The list of objects to convert into rows
 * @param fields The list of fields from each of those objects to include in the rows
 * @param headers An optional object of headers to include on the first line. Omit to have no headings.
 * @returns A CSV
 */
export default function toCSV<T extends { [key: string]: string | number }>(objects: T[], fields: (keyof T)[], headers?: Record<keyof T, string>) {
  const rows = headers ? [headers, ...objects] : objects;
  const outputStrings = rows.map((obj) => {
    return fields
      .map((fieldName) => {
        const field = obj[fieldName];
        // Don't wrap numbers in "". I think this is pointless, but it could help some programs differentiate strings and numbers during import
        if (typeof field === 'number') {
          return field.toString();
        }
        const escapedString = field.replaceAll('"', '""');
        return `"${escapedString}"`;
      })
      .join(',');
  });
  return outputStrings.join('\n');
}
