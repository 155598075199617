import React, { useCallback, useContext, useState } from 'react';
import { TutorialContext, TutorialDataJSONFormat, TutorialsUtils } from '@gi/tutorial';
import { parseTutorials } from '@gi/tutorial/source/tutorials-parser';
import { fileContentsDownload } from '@gi/utils';
import { TutorialEditorContext } from './tutorial-editor-context';

import styles from './tutorial-editor.module.css';
import { TutorialParseError } from './tutorial-editor-types';
import { validateTutorialsString } from './tutorial-json-validator';
import ImportErrorsModal from './editor-json-input/import-errors-modal';

async function fileToString(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
        return;
      }

      throw new Error('Invalid type');
    };
  });
}

const EditorControlsBottom = (): JSX.Element => {
  const { editingTutorials, setEditingTutorials, selectedTutorial } = useContext(TutorialEditorContext);
  const { tutorials, setTutorials } = useContext(TutorialContext);

  const [importErrors, setImportErrors] = useState<null | TutorialParseError[]>(null);

  const exportAll = useCallback(() => {
    if (tutorials !== null) {
      const fileStr = JSON.stringify(TutorialsUtils.getTutorialsJson(tutorials), undefined, 2);
      fileContentsDownload(fileStr, 'application/json', 'tutorials.json');
    }
  }, [tutorials]);

  const importAll = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      if (e.target.files.length === 1) {
        fileToString(e.target.files[0]).then((fileStr) => {
          const result = validateTutorialsString(fileStr);
          if (result === true) {
            setImportErrors(null);
            const newTutorialList = JSON.parse(fileStr) as TutorialDataJSONFormat[];
            setEditingTutorials(parseTutorials(newTutorialList));
          } else {
            setImportErrors(result);
          }
        });
      }
    }
  }, []);

  const exportSelected = useCallback(() => {
    if (selectedTutorial !== null) {
      const fileStr = JSON.stringify(selectedTutorial, undefined, 2);
      fileContentsDownload(fileStr, 'application/json', 'tutorial.json');
    }
  }, [selectedTutorial]);

  const syncOut = useCallback(() => {
    setTutorials(editingTutorials);
  }, [editingTutorials]);

  return (
    <div className={styles.buttonRow}>
      {importErrors ? <ImportErrorsModal errors={importErrors} onClose={() => setImportErrors(null)} /> : null}
      <div className={styles.buttonRowInner}>
        <div>
          <input type='file' onChange={importAll} />
        </div>
      </div>
      <div className={styles.buttonRowInner}>
        <button type='button' className={styles.editorButton} onClick={exportAll}>
          Export All
        </button>
        <button disabled={selectedTutorial === null} type='button' className={styles.editorButton} onClick={exportSelected}>
          Export Selected
        </button>
        <button type='button' className={styles.editorButton} onClick={syncOut}>
          Sync Out
        </button>
      </div>
    </div>
  );
};

export default EditorControlsBottom;
