import React from 'react';
import { useSelector } from 'react-redux';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import CropRotationSelector from './crop-rotation-selector';
import ControlGroup from '../../common/control-group';
import ControlRow from '../../common/control-row';

const CropRotationControls = (): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  return (
    <ControlGroup>
      <ControlRow>
        <CropRotationSelector attachToBody disabled={activePlan === null} identifier='toolbar-crop-rotation' />
      </ControlRow>
    </ControlGroup>
  );
};

export default CropRotationControls;
