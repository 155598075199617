import { GardenItemType } from '@gi/constants';
import { CameraNode, ContentRootNode, LimitsMode, NodeEvent, SelectableComponentContext } from '@gi/core-renderer';
import { PlannerSettings } from '@gi/plan';
import { SimulatedPlan, SimulatedPlanEvents } from '../simulation/simulated-plan';
import PlanRootNode from './nodes/plan-root-node';
import CanvasLayers from './canvas-layers';
import PlanNode from './nodes/plan-node';
import CanvasPlant from './canvas-plant';
import GridNode from './nodes/hud/grid-node';
import PlanSettingsContext from './nodes/plan-settings-context';
import RulersNode from './nodes/hud/rulers-node';
import CanvasInteractionInterface from '../canvas-interface/canvas-interaction-interface';
import GardenItemDragMiddleware from './components/garden-item-drag-middleware';
import GardenItemSelectionMiddleware from './components/garden-item-selection-middleware';
import CanvasText from './canvas-text';
import CanvasShape from './canvas-shape';
import CanvasGardenObject from './canvas-garden-object';
import ToolContext from './nodes/tools/tool-context';
import { SimulationFactory } from '../synced-plan/simulation-factory';
import KeybindsNode from './nodes/tools/keybinds';
import CanvasHistoricalPlan from './canvas-historical-plan';
import SettingsContext from './nodes/settings-context';
import { GardenCanvasSettings } from '../garden-canvas-settings';
import CanvasLayersController from './components/canvas-layer-controller';
import GardenItemClickMiddleware from './components/garden-item-click-middleware';
import CropRotationContext from './nodes/crop-rotation/crop-rotation-context';

const VIEWPORT_PLAN_PADDING = 40;

/** The minimum ratio of plan to screen size.
 *  Ast 1/3, the camera's min magnification will show the plan as 1/3 the smallest dimension of the viewport.
 */
const CAMERA_MIN_MAGNIFICATION_RATIO = 1 / 3;

// PlanRoot
// - Camera
// - Plan
// - - layoutLayer
// - - selectedLayoutLayer
// - - irrigationLayer
// - - selectedIrrigationLayer
// - - plantsLayer
// - - selectedPlantsLayer
// - - structuresLayer
// - - selectedStructuresLayer
// - - textLayer
// - - selectedTextLayer
// - - plantLabelLayer
// - - selectedPlantLabelLayer
// - - cropRotationHistoryLayer
// - - drawingPreviewLayer

/**
 * Keeps a Simulated plan (and items) in sync with nodes
 */
export class CanvasPlan {
  readonly simulatedPlan: SimulatedPlan;
  readonly interactionInterface: CanvasInteractionInterface;

  camera: CameraNode;
  planRootNode: PlanRootNode;
  planNode: PlanNode;

  plants: Record<number, CanvasPlant>; // CanvasPlanPlant
  gardenObjects: Record<number, CanvasGardenObject>;
  shapes: Record<number, CanvasShape>;
  text: Record<number, CanvasText>;
  itemTypes: Record<number, GardenItemType>;

  historicalPlans: Record<number, CanvasHistoricalPlan>;

  canvasLayers: CanvasLayers;
  gridNode: GridNode;
  rulersNode: RulersNode;

  selectionContext: SelectableComponentContext;
  settingsContext: SettingsContext;
  planSettingsContext: PlanSettingsContext;
  toolContext: ToolContext;
  cropRotationContext: CropRotationContext;
  keybinds: KeybindsNode;

  canvasLayersController: CanvasLayersController;
  #visibleMonth: number | null = null;

  dragMiddleware: GardenItemDragMiddleware;
  selectionMiddleware: GardenItemSelectionMiddleware;
  clickMiddleware: GardenItemClickMiddleware;

  constructor(simulatedPlan: SimulatedPlan, simulationFactory: SimulationFactory) {
    this.simulatedPlan = simulatedPlan;
    this.interactionInterface = new CanvasInteractionInterface(simulatedPlan, simulationFactory);

    this.plants = {};
    this.gardenObjects = {};
    this.shapes = {};
    this.text = {};
    this.itemTypes = {};

    this.historicalPlans = {};

    this.#setup();
  }

  #setup() {
    this.planRootNode = new PlanRootNode();
    this.selectionContext = this.planRootNode.selectionContext;

    this.keybinds = new KeybindsNode();

    this.camera = new CameraNode({
      limitsMode: LimitsMode.ContainView,
      limits: {
        top: 0,
        left: 0,
        right: this.simulatedPlan.width,
        bottom: this.simulatedPlan.height,
      },
    });

    this.rulersNode = new RulersNode({
      dimensions: { width: this.simulatedPlan.width, height: this.simulatedPlan.height },
      visible: this.simulatedPlan.showRulers,
      metric: this.simulatedPlan.useMetric,
    });
    this.gridNode = new GridNode({
      dimensions: { width: this.simulatedPlan.width, height: this.simulatedPlan.height },
      visible: this.simulatedPlan.showGrid,
      metric: this.simulatedPlan.useMetric,
    });
    this.camera.addChildren(this.rulersNode);
    this.camera.state.addUpdater(
      () => {
        this.#updateCameraMinMagnification();
      },
      { properties: ['viewportSize'] }
    );

    this.planNode = new PlanNode(this.simulatedPlan.width, this.simulatedPlan.height);

    this.canvasLayers = new CanvasLayers();
    this.canvasLayers.setup(this.planNode);

    // Attach the grid to the world instead of the camera, and after the canvas layers,
    // to allow for tooltips/handles to appear on top
    this.planNode.addChildren(this.gridNode);

    // Settings context
    this.settingsContext = this.planRootNode.contexts.add(new SettingsContext(this.planRootNode as ContentRootNode, this.camera));
    // Plan settings context
    this.planSettingsContext = this.planRootNode.contexts.add(
      new PlanSettingsContext({
        layer: this.simulatedPlan.layerDisplayMode,
        metric: this.simulatedPlan.useMetric,
        month: this.simulatedPlan.visibleMonth,
        showGrid: this.simulatedPlan.showGrid,
        showRulers: this.simulatedPlan.showRulers,
        cropRotationMode: this.simulatedPlan.cropRotationMode,
      })
    );
    // Tool context
    this.toolContext = this.planRootNode.contexts.add(new ToolContext(this.canvasLayers));

    // Canvas layer controller (context)
    this.canvasLayersController = this.planRootNode.contexts.add(new CanvasLayersController(this.canvasLayers, this.simulatedPlan.layerDisplayMode));
    // Crop rotation controller
    this.cropRotationContext = this.planRootNode.contexts.add(new CropRotationContext());
    this.cropRotationContext.setCropRotationMode(this.simulatedPlan.cropRotationMode);

    this.dragMiddleware = this.planRootNode.contexts.add(new GardenItemDragMiddleware(this.interactionInterface));
    this.selectionMiddleware = this.planRootNode.contexts.add(new GardenItemSelectionMiddleware(this.interactionInterface, this.canvasLayers));
    this.clickMiddleware = this.planRootNode.contexts.add(new GardenItemClickMiddleware(this.interactionInterface));

    this.planRootNode.addChildren(this.camera, this.planNode, this.keybinds);

    // Attach listeners to simulated plan
    this.simulatedPlan.on(SimulatedPlanEvents.DidUpdate, this.#onSimulatedPlanUpdate);
    this.simulatedPlan.on(SimulatedPlanEvents.ItemAdded, this.#onSimulatedItemAdded);
    this.simulatedPlan.on(SimulatedPlanEvents.ItemRemoved, this.#onSimulatedGardenItemRemoved);
    // this.#simulatedPlan.on(SimulatedPlanEvents.ItemUpdated, this.#onSimulatedGardenItemUpdated);
    this.simulatedPlan.on(SimulatedPlanEvents.HistoricalPlanAdded, this.#onSimulatedHistoricalPlanAdded);
    this.simulatedPlan.on(SimulatedPlanEvents.HistoricalPlanRemoved, this.#onSimulatedHistoricalPlanRemoved);

    // Create CanvasPlanPlants, CanvasPlanGardenObjects, CanvasPlanShapes and CanvasPlanTexts
    this.simulatedPlan.plantIds.forEach((plantId) => {
      this.#addItem(GardenItemType.Plant, plantId);
    });

    this.simulatedPlan.gardenObjectIds.forEach((gardenObjectId) => {
      this.#addItem(GardenItemType.GardenObject, gardenObjectId);
    });

    this.simulatedPlan.shapeIDs.forEach((shapeId) => {
      this.#addItem(GardenItemType.Shape, shapeId);
    });

    this.simulatedPlan.textIds.forEach((textId) => {
      this.#addItem(GardenItemType.Text, textId);
    });

    this.simulatedPlan.historicalPlanIds.forEach((planId) => {
      this.#addHistoricalPlan(planId);
    });

    this.camera.eventBus.once(NodeEvent.DidBind, this.#onceCameraBind);
  }

  #teardown() {
    this.simulatedPlan.off(SimulatedPlanEvents.DidUpdate, this.#onSimulatedPlanUpdate);
    this.simulatedPlan.off(SimulatedPlanEvents.ItemAdded, this.#onSimulatedItemAdded);
    this.simulatedPlan.off(SimulatedPlanEvents.ItemRemoved, this.#onSimulatedGardenItemRemoved);
    this.camera.eventBus.off(NodeEvent.DidBind, this.#onceCameraBind);
    this.planRootNode.destroy();
  }

  #onceCameraBind = () => {
    // this.fitPlanToCamera();
    this.centreCameraToPlan();
    this.#updateCameraMinMagnification();
  };

  #updateCameraMinMagnification() {
    if (this.planRootNode.isPrinting) {
      return;
    }

    const { viewportSize } = this.camera.state.values;

    const wRatio = viewportSize.width / this.simulatedPlan.width;
    const hRatio = viewportSize.height / this.simulatedPlan.height;

    this.camera.state.values.minMagnification = Math.min(wRatio, hRatio) * CAMERA_MIN_MAGNIFICATION_RATIO;
  }

  destroy() {
    this.#teardown();
  }

  /**
   *
   * @param plantId
   */
  #addItem(itemType: GardenItemType, itemId: number) {
    switch (itemType) {
      case GardenItemType.Plant:
        this.plants[itemId] = new CanvasPlant(this.simulatedPlan.plants[itemId], this.canvasLayers);
        this.itemTypes[itemId] = GardenItemType.Plant;
        break;
      case GardenItemType.GardenObject:
        this.gardenObjects[itemId] = new CanvasGardenObject(this.simulatedPlan.gardenObjects[itemId], this.canvasLayers);
        this.itemTypes[itemId] = GardenItemType.GardenObject;
        break;
      case GardenItemType.Shape:
        this.shapes[itemId] = new CanvasShape(this.simulatedPlan.shapes[itemId], this.canvasLayers);
        this.itemTypes[itemId] = GardenItemType.Shape;
        break;
      case GardenItemType.Text:
        this.text[itemId] = new CanvasText(this.simulatedPlan.text[itemId], this.canvasLayers);
        this.itemTypes[itemId] = GardenItemType.Text;
        break;
      default:
        throw new Error(`Unhandled GardenItemType ${itemType}`);
    }
  }

  #addHistoricalPlan(planId: number) {
    const plan = this.simulatedPlan.historicalPlans[planId];
    this.historicalPlans[planId] = new CanvasHistoricalPlan(plan, this.canvasLayers);
  }

  #removeItem(itemId: number) {
    const itemType = this.itemTypes[itemId];

    if (!itemType) {
      console.error("Attempted to remove item from plan which wasn't present:", itemId);
      return;
    }

    switch (itemType) {
      case GardenItemType.Plant:
        this.plants[itemId].destroy();
        delete this.plants[itemId];
        break;
      case GardenItemType.GardenObject:
        this.gardenObjects[itemId].destroy();
        delete this.gardenObjects[itemId];
        break;
      case GardenItemType.Shape:
        this.shapes[itemId].destroy();
        delete this.shapes[itemId];
        break;
      case GardenItemType.Text:
        this.text[itemId].destroy();
        delete this.text[itemId];
        break;
      default:
        throw new Error(`Unhandled GardenItemType ${itemType}`);
    }

    delete this.itemTypes[itemId];
  }

  #onSimulatedItemAdded = (type: GardenItemType, id: number) => {
    this.#addItem(type, id);
  };

  #onSimulatedPlanUpdate = () => {
    const dimensions: Dimensions = {
      width: this.simulatedPlan.width,
      height: this.simulatedPlan.height,
    };

    // Update dimensions
    this.planNode.state.values.width = this.simulatedPlan.width;
    this.planNode.state.values.height = this.simulatedPlan.height;

    this.camera.state.values.limits = {
      top: 0,
      left: 0,
      bottom: this.simulatedPlan.height,
      right: this.simulatedPlan.width,
    };

    // Update rulers
    this.rulersNode.state.values.dimensions = dimensions;
    this.rulersNode.state.values.visible = this.simulatedPlan.showRulers;
    this.rulersNode.state.values.metric = this.simulatedPlan.useMetric;

    // Update grid
    this.gridNode.state.values.dimensions = dimensions;
    this.gridNode.state.values.visible = this.simulatedPlan.showGrid;
    this.gridNode.state.values.metric = this.simulatedPlan.useMetric;

    // Update layer display mode
    this.canvasLayersController.setLayerDisplayMode(this.simulatedPlan.layerDisplayMode);

    // Update crop rotation mode
    this.cropRotationContext.setCropRotationMode(this.simulatedPlan.cropRotationMode);

    // Update plant visibility
    if (this.simulatedPlan.visibleMonth !== this.#visibleMonth) {
      this.#visibleMonth = this.simulatedPlan.visibleMonth;
      Object.values(this.plants).forEach((plant) => {
        plant.updateVisibility(this.simulatedPlan.visibleMonth);
      });
    }

    this.setPlanSettings({
      layer: this.simulatedPlan.layerDisplayMode,
      metric: this.simulatedPlan.useMetric,
      month: this.simulatedPlan.visibleMonth,
      showGrid: this.simulatedPlan.showGrid,
      showRulers: this.simulatedPlan.showRulers,
      cropRotationMode: this.simulatedPlan.cropRotationMode,
    });

    // Validate camera
    this.#updateCameraMinMagnification();
  };

  #onSimulatedGardenItemRemoved = (type: GardenItemType, id: number) => {
    this.#removeItem(id);
  };

  #onSimulatedHistoricalPlanAdded = (planId: number) => {
    this.historicalPlans[planId] = new CanvasHistoricalPlan(this.simulatedPlan.historicalPlans[planId], this.canvasLayers);
  };

  #onSimulatedHistoricalPlanRemoved = (planId: number) => {
    if (!this.historicalPlans[planId]) {
      console.error(`Attempted to remove historical plan that doesn't exist (planID ${planId})`);
      return;
    }

    this.historicalPlans[planId].destroy();
    delete this.historicalPlans[planId];
  };

  centreCameraToPlan() {
    this.camera.state.values.position = {
      x: this.simulatedPlan.width / 2,
      y: this.simulatedPlan.height / 2,
    };
  }

  /**
   * Changes the magnification of the camera and centres the camera on the plan so the plan fits inside the
   * viewport with a small amount of padding
   */
  fitPlanToCamera() {
    const { viewportSize } = this.camera.state.values;

    if (viewportSize.width === 0 || viewportSize.height === 0) {
      console.error('Asked to fit plan to camera without viewport size');
      return;
    }

    const wRatio = this.simulatedPlan.width / Math.max(1, viewportSize.width - VIEWPORT_PLAN_PADDING * 2);
    const hRatio = this.simulatedPlan.height / Math.max(1, viewportSize.height - VIEWPORT_PLAN_PADDING * 2);

    const ratio = 1 / Math.max(wRatio, hRatio);
    this.camera.state.values.magnification = ratio;

    this.centreCameraToPlan();
  }

  setSettings(settings: Partial<GardenCanvasSettings>) {
    const keys = Object.keys(settings);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      this.settingsContext.state.values[key] = settings[key];
    }
  }

  setPlanSettings(settings: Partial<PlannerSettings>) {
    const keys = Object.keys(settings);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      this.planSettingsContext.state.values[key] = settings[key];
    }
  }
}
