import React, { CSSProperties, ReactNode } from 'react';

import FadedScrollContainer from '../faded-scroll-container/faded-scroll-container';

import './button-strip.scss';

interface iProps {
  children: ReactNode;
  className?: string;
  gap?: number;
  endPadding?: number;
  isVertical?: boolean;
  shouldCenter?: boolean;
  showDividers?: boolean;
  hideScrollbar?: boolean;
  shouldAlignCenter?: boolean;
  tolerance?: number;
  hideOverflowArrows?: boolean;
  disableScrollButtons?: boolean;
}

const ButtonStrip = ({
  children,
  className,
  gap = 0,
  endPadding = 0,
  isVertical = false,
  shouldCenter = false,
  showDividers = false,
  hideScrollbar = false,
  shouldAlignCenter = false,
  tolerance = 5,
  hideOverflowArrows = false,
  disableScrollButtons = false,
}: iProps): JSX.Element => {
  const classNames = ['button-strip'];
  if (className) {
    classNames.push(className);
  }
  if (isVertical) {
    classNames.push('vertical');
  }
  if (shouldCenter) {
    classNames.push('center');
  }
  if (showDividers) {
    classNames.push('show-dividers');
  }
  if (hideScrollbar) {
    classNames.push('hide-scrollbar');
  }
  if (shouldAlignCenter) {
    classNames.push('align-center');
  }

  const styles = {
    '--gap': `${gap}px`,
    '--padding': `${endPadding}px`,
  } as CSSProperties;

  return (
    <FadedScrollContainer
      className={classNames.join(' ')}
      style={styles}
      isHorizontal={!isVertical}
      tolerance={tolerance}
      hideScrollbars={hideScrollbar}
      hideOverflowArrows={hideOverflowArrows}
      disableScrollButtons={disableScrollButtons}
    >
      <div className='button-strip-content'>{children}</div>
    </FadedScrollContainer>
  );
};

export default ButtonStrip;
