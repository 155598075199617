import React, { useMemo, useRef, useState } from 'react';

import { useOnClickOutside } from '@gi/react-utils';
import { LoadingState } from '@gi/constants';

import TopMenuButton from '../components/top-menu-button';
import { usePlanStatusGetter } from '../../utils/use-plan-status';
import DesktopPlannerMenuDropdown from '../../desktop-planner-dropdown/desktop-planner-dropdown';

import styles from './desktop-plan-title.module.css';

interface iProps {
  activePlanId: number | null;
}

const DesktopPlanTitle = ({ activePlanId }: iProps): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const topMenuButtonRef = useRef<HTMLButtonElement>(null);
  const topMenuDropdownRef = useRef<HTMLDivElement>(null);

  const getPlanAndStatus = usePlanStatusGetter();
  const activePlanInfo = useMemo(() => {
    if (activePlanId === null) {
      return null;
    }
    return getPlanAndStatus(activePlanId);
  }, [activePlanId, getPlanAndStatus]);

  useOnClickOutside<HTMLElement>([topMenuButtonRef, topMenuDropdownRef], () => {
    setMenuOpen(false);
  });

  const titleIconContent = useMemo(() => {
    if (!activePlanInfo) {
      return null;
    }
    switch (activePlanInfo.status) {
      case LoadingState.LOADING:
        return <i className='icon-spinner animate-pulse' />;
      case LoadingState.ERROR:
        return <i className={`icon-warning ${styles.error}`} title='Plan failed to load' />;
      default:
        return null;
    }
  }, [activePlanInfo?.status]);

  const titleContent = useMemo(() => {
    if (activePlanId === null) {
      return <div className={styles.titleDetails}>No Open Plan</div>;
    }

    return (
      <>
        <div className={styles.titleDetails}>
          {titleIconContent}
          <div className={styles.titleText}>{activePlanInfo ? activePlanInfo.plan.name : ''}</div>
        </div>
        <div className={styles.titleYear}>{activePlanInfo ? activePlanInfo.plan.year : ''}</div>
      </>
    );
  }, [activePlanId, activePlanInfo, titleIconContent]);

  return (
    <>
      <TopMenuButton
        type='button'
        className={styles.titleContainer}
        ref={topMenuButtonRef}
        onClick={() => setMenuOpen((_menuOpen) => !_menuOpen)}
        data-identifier='top-menu-plan-dropdown'
      >
        {titleContent}
        <div className={styles.titleIcon}>
          <i className='icon-down-dir' />
        </div>
      </TopMenuButton>
      {menuOpen ? <DesktopPlannerMenuDropdown target={topMenuButtonRef!.current!} closeMenu={() => setMenuOpen(false)} ref={topMenuDropdownRef} /> : null}
    </>
  );
};

export default DesktopPlanTitle;
