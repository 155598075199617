import React, { useState } from 'react';

import OverlayToolbarButton from '../../overlay-toolbar-button';
import PlannerSettingsDrawer from './planner-settings-drawer';

const PlannerSettingsToolbarButton = (): JSX.Element => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <OverlayToolbarButton onClick={() => setShowDrawer(true)} identifier='overlay-planner-settings-button'>
        <i className='icon-dots' />
      </OverlayToolbarButton>
      <PlannerSettingsDrawer open={showDrawer} onClose={() => setShowDrawer(false)} />
    </>
  );
};

export default PlannerSettingsToolbarButton;
