import React, { useContext } from 'react';
import { DebugModalContext } from './debug-modal-context';
import DebugModal from './debug-modal';

const DebugModalRenderer = () => {
  const { open } = useContext(DebugModalContext);

  if (open) {
    return <DebugModal />;
  }

  return null;
};

export default DebugModalRenderer;
