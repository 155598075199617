import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useState } from 'react';
import { NotificationActionCreators, NotificationTypes } from '@gi/notifications';
import { CanvasSelectors, GardenCanvasContext } from '@gi/react-garden-canvas';
import Modal, {
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
  ModalPaneContent,
} from '@gi/modal';
import LoadingButton from '@gi/loading-button';
import Plan from '@gi/plan';
import { fileDownload } from '@gi/utils';
import { canvasBackgroundUsageCompatibility, generateImage } from '@gi/core-renderer';
import { RulersMode } from '@gi/plan-simulation';
import { IntercomActionCreators } from '@gi/intercom';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

import './plan-image-modal.scss';

const MAX_PLAN_DIMENSION = 10000; // cm/pixel limit for plan canvas generation

function generateImageName(plan: Plan): string {
  return `${plan.name} - ${plan.year}.png`;
}

enum ErrorType {
  InvalidDimensions = 'InvalidDimensions',
  PlanUnavailable = 'PlanUnavailable',
}

function getMaxPlanDimensions(metric: boolean) {
  if (metric) {
    return '100m';
  }

  return '328 feet';
}

interface iProps {
  onClose: () => void;
}

const PlanImageModal = ({ onClose }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const { gardenCanvas } = useContext(GardenCanvasContext);
  const [generating, setGenerating] = useState(false);
  const plan = useSelector(CanvasSelectors.getActivePlan);

  const errors: ErrorType[] = [];
  const errorMessages: Record<ErrorType, string> = {
    [ErrorType.InvalidDimensions]: `Your plan is too big to support image generation, the maximum width and height are ${getMaxPlanDimensions(plan ? plan.plannerSettings.metric : true)}`,
    [ErrorType.PlanUnavailable]: 'An unknown error occured',
  };

  if (plan === null) {
    errors.push(ErrorType.PlanUnavailable);
  }

  if (plan !== null) {
    if (plan.width > MAX_PLAN_DIMENSION || plan.height > MAX_PLAN_DIMENSION) {
      errors.push(ErrorType.InvalidDimensions);
    }
  }

  let notification = NotificationActionCreators.createNotification({
    title: 'Image Generated',
    text: 'Saved to your device, please check your downloads folder',
    icon: 'icon-picture',
    type: NotificationTypes.SUCCESS,
  });

  const generate = () => {
    try {
      if (plan === null) {
        throw new Error('Plan data unavailable');
      }

      if (gardenCanvas === null) {
        throw new Error('Canvas unavailable');
      }

      const activePlan = gardenCanvas.getActivePlan();
      if (!activePlan) {
        throw new Error('Cannot get dimensions of plan: No active plan from the GardenCanvas.');
      }

      const { dimensions, showRulers } = activePlan.simulatedPlan;
      const rulerSize = activePlan.canvasPlan.rulersNode.state.values.thickness;

      activePlan.canvasPlan.rulersNode.setMode(showRulers ? RulersMode.SHOW_ALL : RulersMode.HIDDEN);

      setGenerating(true);
      let canvas: HTMLCanvasElement;
      try {
        canvas = generateImage(gardenCanvas.engine, {
          frame: { top: 0, left: 0, right: dimensions.width, bottom: dimensions.height },
          padding: showRulers ? rulerSize - 1 : 0,
        });
      } finally {
        activePlan.canvasPlan.rulersNode.setMode(RulersMode.DEFAULT);
      }
      const dataURL = canvas.toDataURL('png');

      if (dataURL === 'data:,') {
        throw new Error('Data URL was not created correctly');
      }

      fileDownload(dataURL, generateImageName(plan));
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ExportPlanImage, { planId: plan.id }));
      onClose();
    } catch (e) {
      notification = NotificationActionCreators.createNotification({
        title: 'Image Generation Failed',
        text: 'Failed to create an image of your plan, please contact support through our live chat if you continue to have problems',
        type: NotificationTypes.ERROR,
      });
    } finally {
      setGenerating(false);
      dispatch(notification);
    }
  };

  return (
    <Modal closeRequest={onClose} style={{ maxWidth: '520px' }} className='plan-image-modal'>
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Generate Image</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <ModalPaneContent>
              <p>Generate an image of your plan which is saved to your device.</p>
              <p>Your browser may ask for permission to save images from the Garden Planner.</p>
              {!canvasBackgroundUsageCompatibility.supported ? (
                <div className='warning'>
                  <p>Your browser may be blocking certain canvas features needed to generate an image of your plan.</p>
                  <p>
                    If the final image appears broken or incorrect, check your browser privacy settings, or{' '}
                    <button
                      className='button button-inline intercom-button'
                      type='button'
                      onClick={() => dispatch(IntercomActionCreators.openIntercomWithAnalytics('export-image-modal-missing-features'))}
                    >
                      contact support
                    </button>{' '}
                    for assistance.
                  </p>
                </div>
              ) : null}
              {errors.length === 0 ? null : (
                <div className='errors'>
                  <h3 className='title'>
                    <i className='icon-warning' /> Generating an image is not available for this plan
                  </h3>
                  {errors.map((errorType, key) => (
                    <p key={key}>{errorMessages[errorType]}</p>
                  ))}
                </div>
              )}
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={onClose}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <LoadingButton loading={generating} disabled={errors.length > 0} type='button' className='button button-primary' onClick={generate}>
                Generate
              </LoadingButton>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PlanImageModal;
