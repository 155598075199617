import { GardenItemType } from '@gi/constants';
import Plan, { PlanGardenObject, PlanPlant, PlanShape, PlanText, createPlanDiff } from '@gi/plan';
import { getArrayDiff } from '@gi/utils';
import { UserPlantVarietySetDiff, UserPlantVarietySetUtils } from '@gi/user';

import { SimulatedPlan, SimulatedPlanEvents } from '../simulation/simulated-plan';
import { SimulationFactory } from './simulation-factory';
import {
  updateAddedItemsFromSimulated,
  updatePlanPropertiesFromSimulated,
  updateRemovedItemsFromSimulated,
  updateUpdatedItemsFromSimulated,
} from './sync-utils';
import { SimulatedPlant } from '../simulation/simulated-plant';
// import { updateAddedItemsFromSimulated, updatePlanPropertiesFromSimulated, updateRemovedItemsFromSimulated, updateUpdatedItemsFromSimulated } from './sync-utils';

export type PlanUpdateCallback = (plan: Plan) => void;

type UpdateQueue = {
  plan: boolean;
  addedItems: number[];
  updatedItems: number[];
  removedItems: number[];
};

const filterPlansById = (plans: Record<number, Plan>, ids: number[]) => {
  const result: Record<number, Plan> = {};
  for (let i = 0; i < ids.length; i++) {
    if (plans[ids[i]]) {
      result[ids[i]] = plans[ids[i]];
    }
  }
  return result;
};

const filterPlantsByCode = (plants: SimulatedPlant[], code: string, variety?: string): SimulatedPlant[] => {
  if (variety !== undefined) {
    return plants.filter((plant) => plant.plant.code === code && plant.variety === variety);
  }
  return plants.filter((plant) => plant.plant.code === code);
};

/**
 * Keeps a Plan in sync with a SimulatedPlan
 */
export class SyncedPlan {
  #plan: Plan;
  get plan() {
    return this.#plan;
  }

  readonly updateCallback: PlanUpdateCallback;

  #simulationFactory: SimulationFactory;

  #simulatedPlan: SimulatedPlan;
  get simulatedPlan() {
    return this.#simulatedPlan;
  }

  #historicalPlans: Record<number, Plan> = {};
  get historicalPlans() {
    return this.#historicalPlans;
  }

  #updateQueue: UpdateQueue = {
    plan: false,
    addedItems: [],
    updatedItems: [],
    removedItems: [],
  };

  #updateFromMutablePlanInProgress = false;

  constructor(plan: Plan, availableHistoricalPlans: Record<number, Plan>, updateCallback: PlanUpdateCallback, simulationFactory: SimulationFactory) {
    this.#plan = plan;
    this.#simulationFactory = simulationFactory;
    this.updateCallback = updateCallback;

    this.#simulatedPlan = this.#simulationFactory.createSimulatedPlan(plan, availableHistoricalPlans);
    this.#simulatedPlan.on(SimulatedPlanEvents.DidUpdate, this.#onSimulatedPlanUpdate);
    this.#simulatedPlan.on(SimulatedPlanEvents.ItemAdded, this.#onSimulatedGardenItemAdded);
    this.#simulatedPlan.on(SimulatedPlanEvents.ItemRemoved, this.#onSimulatedGardenItemRemoved);
    this.#simulatedPlan.on(SimulatedPlanEvents.ItemUpdated, this.#onSimulatedGardenItemUpdated);
    this.#simulatedPlan.on(SimulatedPlanEvents.PushUpdates, this.#onSimulatedPlanSignalPushUpdates);

    this.#historicalPlans = filterPlansById(availableHistoricalPlans, plan.history);
  }

  #updateQueueEmpty() {
    return (
      !this.#updateQueue.plan &&
      this.#updateQueue.addedItems.length === 0 &&
      this.#updateQueue.updatedItems.length === 0 &&
      this.#updateQueue.removedItems.length === 0
    );
  }

  pushUpdates() {
    if (this.#updateQueueEmpty()) {
      return;
    }

    let updatedPlan = this.plan;

    if (this.#updateQueue.plan) {
      updatedPlan = updatePlanPropertiesFromSimulated(updatedPlan, this.#simulatedPlan);
      this.#updateQueue.plan = false;
    }

    if (this.#updateQueue.addedItems.length !== 0) {
      updatedPlan = updateAddedItemsFromSimulated(updatedPlan, this.#updateQueue.addedItems, this.#simulatedPlan);
      this.#updateQueue.addedItems = [];
    }

    if (this.#updateQueue.updatedItems.length !== 0) {
      updatedPlan = updateUpdatedItemsFromSimulated(updatedPlan, this.#updateQueue.updatedItems, this.#simulatedPlan);
      this.#updateQueue.updatedItems = [];
    }

    if (this.#updateQueue.removedItems.length !== 0) {
      updatedPlan = updateRemovedItemsFromSimulated(updatedPlan, this.#updateQueue.removedItems, this.#simulatedPlan);
      this.#updateQueue.removedItems = [];
    }

    this.#plan = updatedPlan;
    this.updateCallback(this.plan);
  }

  /**
   * Updates the SimulatedPlan from a Plan change
   */
  updatePlan(plan: Plan) {
    if (plan === this.plan) {
      // No changes
      return;
    }

    if (plan.id !== this.plan.id) {
      throw new Error('Sync plan ID mismatch');
    }

    this.#updateFromMutablePlanInProgress = true;

    // Create diff
    const diff = createPlanDiff(this.plan, plan);

    // Update historical plans
    if (diff.changedProps.includes('history')) {
      const historyDiff = getArrayDiff(this.plan.history, plan.history);

      for (let i = 0; i < historyDiff.removed.length; i++) {
        if (this.#simulatedPlan.historicalPlans[historyDiff.removed[i]]) {
          this.#simulatedPlan.removeHistoricalPlan(historyDiff.removed[i]);
        }
      }

      // Update the age of all the historical plans to match the order of the plan history.
      for (let i = 0; i < plan.history.length; i++) {
        const simulatedHistory = this.#simulatedPlan.historicalPlans[plan.history[i]];
        if (simulatedHistory) {
          simulatedHistory.setAge(i + 1);
          simulatedHistory.emitUpdate();
        } else {
          console.warn(`Couldn't update history with id ${plan.history[i]}`);
        }
        // Don't create the historical plan here, we don't have access to it. This is done _somewhere_ else
      }
    }

    this.#plan = plan;

    if (diff.changedProps.includes('width') || diff.changedProps.includes('height')) {
      this.#simulatedPlan.setDimensions(plan.width, plan.height);
    }

    // TODO: Maybe get an actual diff of the changed settings in the future.
    if (diff.changedProps.includes('plannerSettings')) {
      this.#simulatedPlan.setShowGrid(plan.plannerSettings.showGrid);
      this.#simulatedPlan.setShowRulers(plan.plannerSettings.showRulers);
      this.#simulatedPlan.setMetricDistanceUnits(plan.plannerSettings.metric);
      this.#simulatedPlan.setLayerDisplayMode(plan.plannerSettings.layer);
      this.#simulatedPlan.setVisibleMonth(plan.plannerSettings.month);
      this.#simulatedPlan.setCropRotationMode(plan.plannerSettings.cropRotationMode);
    }

    // Remove removed garden items
    for (let i = 0; i < diff.removedItems.length; i++) {
      this.#simulatedPlan.removeItem(diff.removedItems[i]);
    }

    // Add new garden items
    for (let i = 0; i < diff.addedItems.length; i++) {
      switch (plan.itemTypes[diff.addedItems[i]]) {
        case GardenItemType.Plant:
          this.addPlant(plan.plants[diff.addedItems[i]]);
          break;
        case GardenItemType.GardenObject:
          this.addGardenObject(plan.gardenObjects[diff.addedItems[i]]);
          break;
        case GardenItemType.Shape:
          this.addShape(plan.shapes[diff.addedItems[i]]);
          break;
        case GardenItemType.Text:
          this.addText(plan.text[diff.addedItems[i]]);
          break;
        case undefined:
          break;
        default:
      }
    }

    // Update updated garden items
    for (let i = 0; i < diff.updatedItems.length; i++) {
      switch (plan.itemTypes[diff.updatedItems[i]]) {
        case GardenItemType.Plant:
          this.updatePlant(plan.plants[diff.updatedItems[i]]);
          break;
        case GardenItemType.GardenObject:
          this.updateGardenObject(plan.gardenObjects[diff.updatedItems[i]]);
          break;
        case GardenItemType.Shape:
          this.updateShape(plan.shapes[diff.updatedItems[i]]);
          break;
        case GardenItemType.Text:
          this.updateText(plan.text[diff.updatedItems[i]]);
          break;
        case undefined:
          break;
        default:
      }
    }

    this.#simulatedPlan.updateCollisions();

    this.#updateFromMutablePlanInProgress = false;
  }

  onHistoricalPlanUpdate(plan: Plan) {
    if (!this.plan.history.includes(plan.id)) {
      console.error(`Synced plan told about irrelevant historical plan update (plan ID ${plan.id})`);
      return;
    }

    if (this.historicalPlans[plan.id] && this.#simulatedPlan.historicalPlans[plan.id]) {
      // The plan has already been added, update it
      this.#updateHistoricalPlan(plan);
    } else {
      // The plan is required, add it.
      this.#addHistoricalPlan(plan, this.#plan.history.indexOf(plan.id) + 1);
    }
  }

  #addHistoricalPlan(plan: Plan, age: number) {
    if (!this.#plan.history.includes(plan.id)) {
      console.error(`SyncedPlan tried to add an irrelevant historical plan (plan ID ${plan.id})`);
    }
    this.historicalPlans[plan.id] = plan;
    this.#simulatedPlan.addHistoricalPlan(this.#simulationFactory.createSimulatedPlanHistory(plan, age));
  }

  #updateHistoricalPlan(plan: Plan) {
    if (!this.#plan.history.includes(plan.id) || !this.#historicalPlans[plan.id]) {
      console.error(`Given a historical plan that isn't part of this plan's history (given ${plan.id}, history is ${plan.history.join(', ')})`);
      return;
    }

    // Create diff
    const diff = createPlanDiff(this.#historicalPlans[plan.id], plan);

    this.#historicalPlans[plan.id] = plan;

    const historicalPlan = this.#simulatedPlan.historicalPlans[plan.id];
    if (!historicalPlan) {
      console.error(`Tried to update a historical plan that isn't being simulated (planID ${plan.id})`);
      return;
    }

    // Remove removed garden items
    for (let i = 0; i < diff.removedItems.length; i++) {
      const itemId = diff.removedItems[i];
      if (plan.itemTypes[itemId] === GardenItemType.Plant) {
        historicalPlan.removePlant(itemId);
      }
    }

    // Add new garden items
    for (let i = 0; i < diff.addedItems.length; i++) {
      const itemId = diff.addedItems[i];
      if (plan.itemTypes[itemId] === GardenItemType.Plant) {
        historicalPlan.addPlant(this.#simulationFactory.createSimulatedPlant(plan.plants[itemId]));
      }
    }

    // Update updated garden items
    for (let i = 0; i < diff.updatedItems.length; i++) {
      const itemId = diff.updatedItems[i];
      if (plan.itemTypes[itemId] === GardenItemType.Plant) {
        const plant = plan.plants[itemId];
        historicalPlan.updatePlant(plant.id, plant.rowStart, plant.rowEnd, plant.height);
      }
    }

    historicalPlan.emitUpdate();
  }

  /**
   * Called when the interal Plan has been updated from a SimulatedPlan change
   */
  #planDidUpdate() {
    this.updateCallback(this.plan);
  }

  addPlant(planPlant: PlanPlant) {
    this.#simulatedPlan.addPlant(this.#simulationFactory.createSimulatedPlant(planPlant));
  }

  addGardenObject(planGardenObject: PlanGardenObject) {
    this.#simulatedPlan.addGardenObject(this.#simulationFactory.createSimulatedGardenObject(planGardenObject));
  }

  addShape(planShape: PlanShape) {
    this.#simulatedPlan.addShape(this.#simulationFactory.createSimulatedShape(planShape));
  }

  addText(planText: PlanText) {
    this.#simulatedPlan.addText(this.#simulationFactory.createSimulatedText(planText));
  }

  updatePlant(plant: PlanPlant) {
    const simulatedPlant = this.#simulatedPlan.plants[plant.id];
    if (!simulatedPlant) {
      console.warn(`Asked to update plant that isn't in the simulation (ID: ${plant.id})`);
      return;
    }
    // TODO: Make some form of setAll, as this emits a bunch of wasteful events, and probably validates numerous times.
    simulatedPlant.doBatchUpdates(() => {
      simulatedPlant.setPositions(plant.rowStart, plant.rowEnd, plant.height);
      simulatedPlant.setInGround(plant.inGroundStart, plant.inGroundEnd, plant.inGroundAll);
      simulatedPlant.setLabelOffset(plant.labelOffset);
      simulatedPlant.setShowLabel(plant.showLabel);
      simulatedPlant.setLabelText(plant.labelText);
      simulatedPlant.setVariety(plant.variety);
      simulatedPlant.setUserPlantVariety(
        UserPlantVarietySetUtils.getByPlantCodeAndName(this.#simulationFactory.userPlantVarieties, plant.plantCode, plant.variety)
      );
      simulatedPlant.setLocked(plant.locked);
      simulatedPlant.setZIndex(plant.zIndex);
    });
  }

  updateGardenObject(gardenObject: PlanGardenObject) {
    const simulatedGardenObject = this.#simulatedPlan.gardenObjects[gardenObject.id];
    if (!simulatedGardenObject) {
      console.warn(`Asked to update garden object that isn't in the simulation (ID: ${gardenObject.id})`);
      return;
    }
    simulatedGardenObject.doBatchUpdates(() => {
      simulatedGardenObject.setPosition(gardenObject.start, gardenObject.mid, gardenObject.end, gardenObject.rotation);
      simulatedGardenObject.setLocked(gardenObject.locked);
      simulatedGardenObject.setZIndex(gardenObject.zIndex);
    });
  }

  updateShape(shape: PlanShape) {
    const simulatedShape = this.#simulatedPlan.shapes[shape.id];
    if (!simulatedShape) {
      console.warn(`Asked to update shape that isn't in the simulation (ID: ${shape.id})`);
      return;
    }
    simulatedShape.doBatchUpdates(() => {
      simulatedShape.setPosition(shape.point1, shape.point2, shape.point3, shape.rotation);
      simulatedShape.setStyle(shape.texture ?? shape.fill, shape.stroke, shape.strokeWidth);
      simulatedShape.setLocked(shape.locked);
      simulatedShape.setZIndex(shape.zIndex);
    });
  }

  updateText(text: PlanText) {
    const simulatedText = this.#simulatedPlan.text[text.id];
    if (!simulatedText) {
      console.warn(`Asked to update text that isn't in the simulation (ID: ${text.id})`);
      return;
    }
    simulatedText.doBatchUpdates(() => {
      simulatedText.setPositions(text.start, text.end, text.rotation);
      simulatedText.setText(text.text, text.fill, text.fontSize);
      simulatedText.setLocked(text.locked);
      simulatedText.setZIndex(text.zIndex);
    });
  }

  updateUserPlantVarieties(userPlantVarietiesDiff: UserPlantVarietySetDiff) {
    // TODO: Optimizations:
    //   Should the simulated plan store a proper [plant code] -> [simulated plant] map for efficiency?
    const allPlants = Object.values(this.simulatedPlan.plants);

    for (let i = 0; i < userPlantVarietiesDiff.added.length; i++) {
      const variety = userPlantVarietiesDiff.added[i];
      const plants = filterPlantsByCode(allPlants, variety.plantCode, variety.name);
      for (let j = 0; j < plants.length; j++) {
        plants[j].setUserPlantVariety(userPlantVarietiesDiff.added[i]);
      }
    }

    for (let i = 0; i < userPlantVarietiesDiff.updated.length; i++) {
      const variety = userPlantVarietiesDiff.updated[i];
      const plants = filterPlantsByCode(allPlants, variety.plantCode, variety.name);
      for (let j = 0; j < plants.length; j++) {
        plants[j].setUserPlantVariety(userPlantVarietiesDiff.updated[i]);
      }
    }

    for (let i = 0; i < userPlantVarietiesDiff.removed.length; i++) {
      const variety = userPlantVarietiesDiff.removed[i];
      const plants = filterPlantsByCode(allPlants, variety.plantCode, variety.name);
      for (let j = 0; j < plants.length; j++) {
        plants[j].setUserPlantVariety(null);
      }
    }
  }

  #setPlan(plan: Plan) {
    if (this.plan !== plan) {
      this.#plan = plan;
      this.#planDidUpdate();
    }
  }

  #onSimulatedPlanSignalPushUpdates = () => {
    this.pushUpdates();
  };

  #onSimulatedPlanUpdate = () => {
    if (this.#updateFromMutablePlanInProgress) {
      console.debug('Update from mutable plan in progress, not adding to update');
      return;
    }

    this.#updateQueue.plan = true;
  };

  #onSimulatedGardenItemAdded = (type: GardenItemType, id: number) => {
    if (this.#updateFromMutablePlanInProgress) {
      console.debug('Update from mutable plan in progress, not adding to update');
      return;
    }

    if (!this.#updateQueue.addedItems.includes(id)) {
      this.#updateQueue.addedItems.push(id);
    }
  };

  /**
   * When an item
   */
  #onSimulatedGardenItemRemoved = (type: GardenItemType, id: number) => {
    if (this.#updateFromMutablePlanInProgress) {
      console.debug('Update from mutable plan in progress, not adding to update');
      return;
    }

    const addedIndex = this.#updateQueue.addedItems.indexOf(id);
    if (addedIndex !== -1) {
      // Added and removed in the same update batch, remove all reference to it
      this.#updateQueue.addedItems.splice(addedIndex, 1);
    }

    const updatedIndex = this.#updateQueue.updatedItems.indexOf(id);
    if (updatedIndex !== -1) {
      // Item has been removed since update, we don't need to update it any more
      this.#updateQueue.updatedItems.splice(updatedIndex, 1);
    }

    if (addedIndex === -1 && !this.#updateQueue.removedItems.includes(id)) {
      this.#updateQueue.removedItems.push(id);
    }
  };

  #onSimulatedGardenItemUpdated = (type: GardenItemType, id: number): void => {
    if (this.#updateFromMutablePlanInProgress) {
      console.debug('Update from mutable plan in progress, not adding to update');
      return;
    }

    if (!this.#updateQueue.updatedItems.includes(id)) {
      this.#updateQueue.updatedItems.push(id);
    }
  };

  /**
   * Not to be confused with NSYNC, this won't play American boyband music
   *
   * Removes all listeners from the simulated plan
   */
  endSync() {
    this.#simulatedPlan.off(SimulatedPlanEvents.DidUpdate, this.#onSimulatedPlanUpdate);
    this.#simulatedPlan.off(SimulatedPlanEvents.ItemAdded, this.#onSimulatedGardenItemAdded);
    this.#simulatedPlan.off(SimulatedPlanEvents.ItemRemoved, this.#onSimulatedGardenItemRemoved);
    this.#simulatedPlan.off(SimulatedPlanEvents.ItemUpdated, this.#onSimulatedGardenItemUpdated);
  }
}
