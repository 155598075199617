import React from 'react';
import { ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import styles from './expression-tab.module.css';

interface iProps {
  result: any;
}

const ExpressionResult = ({ result }: iProps): JSX.Element => {
  let expressionString = '';
  const expressionType = typeof result;

  if (result === undefined) {
    expressionString = 'undefined';
  } else {
    expressionString = JSON.stringify(result, undefined, 2);
  }

  return (
    <ModalPaneSection>
      <ModalPaneSectionContent>
        <div className={styles.expressionResult}>
          <div className={styles.resultTitle}>Result</div>
          <div className={styles.resultContent}>
            <div className={styles.expressionType}>{expressionType}</div>
            <div className={styles.expressionString}>{expressionString}</div>
          </div>
        </div>
      </ModalPaneSectionContent>
    </ModalPaneSection>
  );
};
export default ExpressionResult;
