/* eslint-disable no-continue */
import EditGroup from '../simulation/edit/edit-group';
import EditSimulatedGardenObject from '../simulation/edit/edit-simulated-garden-object';
import EditSimulatedPlant from '../simulation/edit/edit-simulated-plant';
import EditSimulatedPlantLabel from '../simulation/edit/edit-simulated-plant-label';
import EditSimulatedShape from '../simulation/edit/edit-simulated-shape';
import EditSimulatedText from '../simulation/edit/edit-simulated-text';
import type { SimulatedPlan } from '../simulation/simulated-plan';
import CanvasInteractionGroup from './canvas-interaction-group';

export function getEditGroup(group: CanvasInteractionGroup, simulatedPlan: SimulatedPlan): EditGroup {
  const editGroup = new EditGroup();
  const plantIDs: number[] = [];

  for (let i = 0; i < group.plants.length; i++) {
    const plant = simulatedPlan.plants[group.plants[i].id];
    if (!plant) {
      continue;
    }

    plantIDs.push(group.plants[i].id);

    editGroup.add(new EditSimulatedPlant(plant));
  }

  for (let i = 0; i < group.sfgPlants.length; i++) {
    const plant = simulatedPlan.plants[group.sfgPlants[i].id];
    if (!plant) {
      continue;
    }

    plantIDs.push(group.sfgPlants[i].id);

    editGroup.add(new EditSimulatedPlant(plant));
  }

  for (let i = 0; i < group.plantLabels.length; i++) {
    const plant = simulatedPlan.plants[group.plantLabels[i].id];
    if (!plant) {
      continue;
    }

    const edit = new EditSimulatedPlantLabel(plant);
    if (plantIDs.includes(group.plantLabels[i].id)) {
      edit.isEditingPlant = true;
    }

    editGroup.add(edit);
  }

  for (let i = 0; i < group.gardenObjects.length; i++) {
    const gardenObject = simulatedPlan.gardenObjects[group.gardenObjects[i].id];
    if (!gardenObject) {
      continue;
    }

    const edit = new EditSimulatedGardenObject(gardenObject);
    editGroup.add(edit);
  }

  for (let i = 0; i < group.shapes.length; i++) {
    const shape = simulatedPlan.shapes[group.shapes[i].id];
    if (!shape) {
      continue;
    }

    const edit = new EditSimulatedShape(shape);
    editGroup.add(edit);
  }

  for (let i = 0; i < group.text.length; i++) {
    const text = simulatedPlan.text[group.text[i].id];
    if (!text) {
      continue;
    }

    const edit = new EditSimulatedText(text);
    editGroup.add(edit);
  }

  return editGroup;
}
