import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RequestStatus, RequestsUtils } from '@gi/request';
import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';
import { SessionSelectors } from '@gi/react-session';
import { RequestSelectors } from '@gi/react-requests';
import Icon from '@gi/icon';
import { PlanSetUtils, PlanUtils } from '@gi/plan';

import './save-button.scss';

const SaveButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);
  const requests = useSelector(RequestSelectors.getRequests);
  const activePlanId = useSelector(CanvasSelectors.getActivePlanID);
  const plan = useSelector(CanvasSelectors.getActivePlan);
  const lastSavedPlans = useSelector(CanvasSelectors.getLastSavePlans);

  const requestStatus = useMemo(() => {
    return plan ? RequestsUtils.getStatus(requests, `SAVE_PLAN_${plan.id}`) : null;
  }, [plan, requests]);

  const saving = useMemo(() => {
    return requestStatus !== null ? requestStatus === RequestStatus.IN_PROGRESS : false;
  }, [requestStatus]);

  const lastSavedPlan = useMemo(() => {
    if (activePlanId === null) {
      return null;
    }

    return PlanSetUtils.planSetGetPlan(lastSavedPlans, activePlanId);
  }, [lastSavedPlans, activePlanId]);

  const hasUnsavedChanges = useMemo(() => {
    return plan !== null && lastSavedPlan !== null ? !PlanUtils.plansShallowEqual(plan, lastSavedPlan) : false;
  }, [plan, lastSavedPlan]);

  const onSave = () => {
    if (user === null || plan === null) {
      return;
    }
    if (hasUnsavedChanges) {
      dispatch(CanvasActionCreators.savePlan(plan));
    } else {
      dispatch(CanvasActionCreators.noChangesToSave());
    }
  };

  return (
    <button className='mobile-plan-menu-save' type='button' onClick={onSave} disabled={saving} data-identifier='mobile-topbar-save'>
      <Icon faded loading={saving} size={17}>
        <i className='icon-floppy' />
      </Icon>
      {!hasUnsavedChanges ? <i className='icon-ok success-indicator' /> : null}
    </button>
  );
};

export default SaveButton;
