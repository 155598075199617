import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Anchor, ButtonStrip, ButtonStripGroup, IconButton, IconButtonType } from '@gi/mobile-components';
import { DrawingToolsContext } from '@gi/drawing-tools';
import { OrientationTypes } from '@gi/constants';

import ShapesDrawer from './shapes/shapes-drawer';
import PlantsDrawer from './plants/plants-drawer';
import GardenObjectsDrawer from './garden-objects/garden-objects-drawer';
import TextDrawer from './text/text-drawer';

import './mobile-drawing-tools.scss';

interface iProps {
  orientation?: OrientationTypes;
}

const MobileDrawingTools = ({ orientation }: iProps): JSX.Element => {
  const { plantFilters, resetPlantFilters } = useContext(DrawingToolsContext);

  const [showPlantsDrawer, setShowPlantsDrawer] = useState(false);
  const [showGardenObjectsDrawer, setShowGardenObjectsDrawer] = useState(false);
  const [showShapesDrawer, setShowShapesDrawer] = useState(false);
  const [showTextDrawer, setShowTextDrawer] = useState(false);

  const initialised = useRef<boolean>(false);

  /**
   * Open the plants drawer if a companion filter is added.
   * Don't do this on first run, as we're probably switching back to this tab
   */
  useEffect(() => {
    if (plantFilters.filters.companion?.inputs?.enabled && initialised.current) {
      setShowPlantsDrawer(true);
    }
    initialised.current = true;
  }, [plantFilters.filters.companion]);

  /**
   * If the user clicks the Plants button and companion filters are enabled, clear filters.
   * This prevents the plant list opening in companion mode, which may be confusing.
   */
  const openPlantsDrawer = () => {
    if (plantFilters.filters.companion?.inputs?.enabled) {
      resetPlantFilters();
    }
    setShowPlantsDrawer(true);
  };

  /**
   * Check if we're in landscape mode, thus everythign should be displayed vertically
   */
  const isVertical = useMemo(() => orientation === OrientationTypes.LANDSCAPE, [orientation]);

  const anchor = useMemo<Anchor>(() => (isVertical ? 'LEFT' : 'BOTTOM'), [isVertical]);

  const classNames = ['mobile-drawing-tools'];
  if (isVertical) {
    classNames.push('vertical');
  }

  return (
    <div className={classNames.join(' ')}>
      <ButtonStrip className='mobile-drawing-tools-buttons' shouldCenter hideScrollbar isVertical={isVertical} gap={10}>
        <ButtonStripGroup>
          <IconButton
            shareSpace
            type={IconButtonType.TAB}
            ariaLabel='Open menu to add a plant'
            icon='icon-plants-black'
            onClick={() => openPlantsDrawer()}
            identifier='plants-drawing-tools-button'
          >
            Plants
          </IconButton>
          <IconButton
            shareSpace
            type={IconButtonType.TAB}
            ariaLabel='Open menu to add a garden object'
            icon='icon-garden-objects-black'
            onClick={() => setShowGardenObjectsDrawer(true)}
            identifier='garden-object-drawing-tools-button'
          >
            Objects
          </IconButton>
          <IconButton
            shareSpace
            type={IconButtonType.TAB}
            ariaLabel='Open menu to add a shape'
            icon='icon-shapes-black'
            onClick={() => setShowShapesDrawer(true)}
            identifier='shapes-drawing-tools-button'
          >
            Shapes
          </IconButton>
          <IconButton
            shareSpace
            type={IconButtonType.TAB}
            ariaLabel='Open menu to add text'
            icon='icon-text-black'
            onClick={() => setShowTextDrawer(true)}
            identifier='text-drawing-tools-button'
          >
            Text
          </IconButton>
        </ButtonStripGroup>
      </ButtonStrip>
      {/* <CurrentlyDrawing isVertical={isVertical} /> */}
      <PlantsDrawer anchor={anchor} open={showPlantsDrawer} onClose={() => setShowPlantsDrawer(false)} />
      <GardenObjectsDrawer anchor={anchor} open={showGardenObjectsDrawer} onClose={() => setShowGardenObjectsDrawer(false)} />
      <ShapesDrawer anchor={anchor} open={showShapesDrawer} onClose={() => setShowShapesDrawer(false)} />
      <TextDrawer anchor={anchor} open={showTextDrawer} onClose={() => setShowTextDrawer(false)} />
      {/* Element to pre-fetch the spritesheet before the drawers open */}
      <span className='mobile-drawing-tools-preloader'>
        <span className='planner-icon' />
      </span>
    </div>
  );
};

export default MobileDrawingTools;
