import React, { useCallback, useContext } from 'react';
import { ExpressionUtils } from '@gi/expression';
import { Tutorial, TutorialContext } from '@gi/tutorial';
import { ModalTabContext } from '@gi/modal';

import { DebugModalContext } from '../../debug-modal-context';
import { DebugTab } from '../../debug-tabs';

import styles from './tutorial-tab.module.css';

interface iProps {
  tutorial: Tutorial;
}

const TutorialDetails = ({ tutorial }: iProps): JSX.Element => {
  const { setExpressionText, setShowExpressionResult } = useContext(DebugModalContext);
  const { startTutorial } = useContext(TutorialContext);
  const { setActiveTab } = useContext(ModalTabContext);

  const openInExpressionTab = useCallback(() => {
    if (tutorial.data.enabled && tutorial.data.enabled.length > 0) {
      const joinedExpressions = ExpressionUtils.joinExpressions(tutorial.data.enabled.map((enabledData) => enabledData.expression));
      setExpressionText(JSON.stringify(joinedExpressions));
      setActiveTab(DebugTab.Expressions);
    }
  }, [tutorial]);

  const watchEnabledCondition = useCallback(() => {
    if (tutorial.data.enabled && tutorial.data.enabled.length > 0) {
      const joinedExpressions = ExpressionUtils.joinExpressions(tutorial.data.enabled.map((enabledData) => enabledData.expression));
      setExpressionText(JSON.stringify(joinedExpressions));
      setShowExpressionResult(true);
    }
  }, [tutorial]);

  return (
    <div className={styles.tutorialDetails}>
      <div>
        <div className={`${styles.enabledIndicator} ${tutorial.enabled ? styles.enabled : ''}`} />
      </div>
      <div>
        <div className={styles.detailsButtons}>
          <button type='button' onClick={() => startTutorial(tutorial)}>
            Start
          </button>
          <button type='button' onClick={openInExpressionTab}>
            Open Expression
          </button>
          <button type='button' onClick={watchEnabledCondition}>
            Watch Expression
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutorialDetails;
