import React, { useContext } from 'react';
import { List, AutoSizer } from 'react-virtualized';

import { Anchor, Drawer, DrawerContent, DrawerHeader, DrawerSticky } from '@gi/mobile-components';
import { DrawingToolsContext } from '@gi/drawing-tools';

import SelectableGardenObjectRow from './selectable-garden-object';
import GardenObjectsDrawerSearchbar from './garden-objects-drawer-searchbar';
import GardenObjectGroupHeadingRow from './garden-object-group-row';

interface iProps {
  open: boolean;
  onClose: () => void;
  anchor?: Anchor;
}

const GardenObjectsDrawer = ({ open, onClose, anchor = 'BOTTOM' }: iProps): JSX.Element => {
  const { groupedFilteredGardenObjects, resetGardenObjectFilters } = useContext(DrawingToolsContext);

  const rowRenderer = ({ key, index, style }) => {
    const item = groupedFilteredGardenObjects[index];
    switch (item.type) {
      case 'garden-object':
        return <SelectableGardenObjectRow key={key} gardenObject={item.gardenObject} style={style} onClick={() => onClose()} />;
      case 'grouped-garden-object':
        return (
          <SelectableGardenObjectRow
            key={key}
            gardenObject={item.gardenObject}
            isInGroup
            isFirstInGroup={item.isFirst}
            isLastInGroup={item.isLast}
            style={style}
            onClick={() => onClose()}
          />
        );
      case 'group':
        return (
          <GardenObjectGroupHeadingRow key={key} gardenObjectGroup={item.group} gardenObjects={item.gardenObjects} expanded={item.expanded} style={style} />
        );
      default:
        console.error(`Unknown garden object list item type`);
        return null;
    }
  };

  return (
    <Drawer open={open} anchor={anchor} onClose={onClose} width={500}>
      <DrawerHeader as='h2' onClose={onClose}>
        Add a Garden Object
      </DrawerHeader>
      <DrawerSticky padX padY border>
        <GardenObjectsDrawerSearchbar />
      </DrawerSticky>
      {groupedFilteredGardenObjects.length > 0 ? (
        <DrawerContent fillHeight overflowHidden data-identifier='drawing-tools-garden-object-list'>
          <AutoSizer>
            {({ width, height }) => (
              <List width={width} height={height} rowCount={groupedFilteredGardenObjects.length} rowHeight={60} rowRenderer={rowRenderer} />
            )}
          </AutoSizer>
        </DrawerContent>
      ) : (
        <DrawerContent fillHeight padX padY data-identifier='drawing-tools-garden-object-list'>
          <p>
            No items found with your current filters, you can{' '}
            <button type='button' className='button button-inline' onClick={resetGardenObjectFilters}>
              reset
            </button>{' '}
            your filters to try again.
          </p>
        </DrawerContent>
      )}
    </Drawer>
  );
};

export default GardenObjectsDrawer;
