import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

/**
 * Checks if the given element is "visible"
 * Currently only checks if the element isn't display: none
 */
function isVisible(element: HTMLElement): boolean {
  // Could use element.checkVisibility(), but it's extremely new
  // From MDN: offsetParent returns null in the following situations:
  // - The element is <body> or <html>.
  // - The element has the position property set to fixed (Firefox returns <body>).
  // - The element or any ancestor has the display property set to none.
  if (element === document.documentElement || element === document.body) {
    return true;
  }
  if (window.getComputedStyle(element).position.toLowerCase() === 'fixed') {
    // We can't use the offsetParent method here, just assume it's visible
    return true;
  }
  return element.offsetParent !== null;
}

/**
 * Returns the first element that matches the given CSS selector, or null
 * if no element matches
 */
function getElm<T extends HTMLElement>(selector: string, checkVisibility: boolean = true): T | null {
  const elms = document.querySelectorAll<T>(selector);

  if (elms.length > 0) {
    // Attempt to prioritise visible elements
    if (checkVisibility) {
      for (let i = 0; i < elms.length; i++) {
        const element = elms[i];
        if (isVisible(element)) {
          return element;
        }
      }
    }

    return elms[0];
  }

  return null;
}

/**
 * Returns a HTMLElement of type <T> or null
 *
 * If the Element is not found it returns null and watches for DOM changes to check for it again
 *
 * If no argument is passed this will always resolve as null and won't set up a Mutation observer on the body
 *
 * Warning: This is probably pretty heavy and should be used sparingly
 */
export function useElement<T extends HTMLElement>(selector?: string): T | null {
  const initialValue = useMemo(() => {
    if (!selector) {
      return null;
    }

    return getElm<T>(selector);
  }, [selector]);

  const [element, setElement] = useState<T | null>(initialValue);

  useEffect(() => {
    if (!selector) {
      setElement(null);
      return;
    }

    setElement(getElm<T>(selector));
  }, [selector]);

  useLayoutEffect(() => {
    if (!selector) {
      return () => {};
    }

    const config = { attributes: false, childList: true, subtree: true };

    const callback: MutationCallback = () => {
      if (element) {
        // We have an element so we want to make sure it's connected
        if (!element.isConnected) {
          setElement(null);
        }
      } else {
        // We don't have an element at the moment so we're looking for the correct one
        const elm = getElm<T>(selector);
        if (elm) {
          setElement(elm);
        }
      }
    };

    // Create an observer instance and observe the document body
    const observer = new MutationObserver(callback);
    observer.observe(document.body, config);

    return () => {
      // Disconnect observer when we're done with this version of 'element'
      observer.disconnect();
    };
  }, [element]);

  return element;
}
