import React from 'react';

import { RunningTutorial } from '../running-tutorial';

import TutorialPortal from './tutorial-portal';
import TutorialWindow from './tutorial-window';
import TutorialPane from './tutorial-pane';

interface iProps {
  runningTutorial: RunningTutorial | null;
  stopTutorial: (() => void) | null;
  next: (() => void) | null;
  previous: (() => void) | null;
}

const TutorialDisplay = ({ runningTutorial, stopTutorial, next, previous }: iProps) => {
  if (!runningTutorial) {
    return null;
  }

  return (
    <TutorialPortal>
      <TutorialWindow paused={runningTutorial.currentRequirementMessages.length > 0} attachment={runningTutorial.current.attachment}>
        <TutorialPane runningTutorial={runningTutorial} stopTutorial={stopTutorial} next={next} previous={previous} />
      </TutorialWindow>
    </TutorialPortal>
  );
};

export default TutorialDisplay;
