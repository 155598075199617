export enum Type {
  Undefined = 'Undefined',
  Null = 'Null',
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Infer = 'Infer',
  Array = 'Array',
}

// Here because I don't know how to get the type of 'typeof'
export type TS_TYPE_TYPE = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function';

export const StringTypeToType: Partial<Record<TS_TYPE_TYPE, Type>> = {
  undefined: Type.Undefined,
  object: Type.Null,
  string: Type.String,
  number: Type.Number,
  boolean: Type.Boolean,
} as const;
