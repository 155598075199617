import React, { CSSProperties, MouseEvent, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DrawingToolsContext } from '@gi/drawing-tools';
import { ModalActionCreators } from '@gi/garden-platform-modal-renderer';
import { SearchDisplayMode } from '@gi/search-service';
import GardenObject from '@gi/garden-object';

import SelectableItem, { iSelectableItemTag } from '../selectable-item';
import GardenObjectIcon from './garden-object-icon';

interface iProps {
  gardenObject: GardenObject;
  isInGroup?: boolean;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  style?: CSSProperties;
  onClick: () => void;
}

const SelectableGardenObjectRow = ({ gardenObject, style, onClick, isInGroup, isFirstInGroup, isLastInGroup }: iProps): JSX.Element => {
  const { onGardenObjectSelected, cancelDraw, gardenObjectFilters, selectedGardenObjectCode } = useContext(DrawingToolsContext);
  const dispatch = useDispatch();

  const { searchResults } = gardenObjectFilters.filters.search.inputs;
  const searchResult = searchResults.getResult(gardenObject.code);

  /**
   * Keep track of if this garden object is selected
   */
  const isSelected = useMemo(() => {
    return selectedGardenObjectCode === gardenObject.code;
  }, [selectedGardenObjectCode, gardenObject]);

  /**
   * When clicked, select the garden object and let our parent know.
   */
  const handleOnClick = useCallback(() => {
    if (isSelected) {
      cancelDraw();
    } else {
      onGardenObjectSelected(gardenObject);
    }
    onClick();
  }, [onGardenObjectSelected, onClick, gardenObject, isSelected]);

  /**
   * Create the list of tags related to the garden object.
   */
  const tags = useMemo(() => {
    const finalTags: iSelectableItemTag[] = [];
    if (gardenObject.plantModifier !== null) {
      finalTags.push({ name: 'Season Extender' });
    }
    return finalTags;
  }, [gardenObject.plantModifier]);

  /**
   * Create the small info icon for opening the garden object information modal
   */
  const infoButton = useMemo(() => {
    const handleInfoClick = (e: MouseEvent) => {
      e.preventDefault();
      dispatch(ModalActionCreators.openGardenObjectInfoModal(gardenObject));
    };

    return (
      <button key='infoButton' type='button' onClick={handleInfoClick}>
        <i className='icon-info-circled' />
      </button>
    );
  }, [gardenObject]);

  /**
   * Generate the garden object name and otherName if a search result exists.
   */
  const gardenObjectName: { name: ReactNode; otherName?: ReactNode } = useMemo(() => {
    if (!searchResult) {
      return { name: gardenObject.name };
    }
    switch (searchResult.displayMode) {
      case SearchDisplayMode.Primary:
        return {
          name: searchResult.getHtml(),
        };
      case SearchDisplayMode.Secondary:
        return {
          name: gardenObject.name,
          otherName: <em title={searchResult.getText()}>{searchResult.getHtml()}</em>,
        };
      default:
        return { name: gardenObject.name };
    }
  }, [searchResult, gardenObject]);

  return (
    <SelectableItem
      icon={<GardenObjectIcon code={gardenObject.code} />}
      name={gardenObjectName.name}
      otherName={gardenObjectName.otherName}
      selected={isSelected}
      tags={tags}
      onClick={handleOnClick}
      buttons={[infoButton]}
      style={style}
      isInGroup={isInGroup}
      isFirstInGroup={isFirstInGroup}
      isLastInGroup={isLastInGroup}
      identifier='selectable-garden-object'
    />
  );
};

export default SelectableGardenObjectRow;
