import React, { useContext } from 'react';

import MenuIcon from '../desktop-menu/common/menu-icon';
import TopMenuButton from '../components/top-menu-button';
import SaveTopMenuButton from '../desktop-plan-title/save-top-menu-button';
import { PlannerCommandContext } from '../../planner-command-provider';

import styles from './desktop-plan-actions.module.css';

const DesktopPlanActions = (): JSX.Element => {
  const { openSettings } = useContext(PlannerCommandContext);

  return (
    <div className={styles.container}>
      <TopMenuButton type='button' onClick={openSettings} data-identifier='top-menu-settings'>
        <MenuIcon icon='icon-cog' /> Settings
      </TopMenuButton>
      <SaveTopMenuButton />
    </div>
  );
};

export default DesktopPlanActions;
