import { InputContainer } from '@gi/form-responsive';
import React, { ChangeEvent, useCallback, useState } from 'react';

import './length-input.scss';

interface iProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  valid?: boolean;
  inputClasses?: string;
  inline?: boolean;
  id?: string;
}

const DegreesInput = ({ value, onChange, disabled = false, valid = true, inputClasses = '', inline = false, id }: iProps): JSX.Element => {
  const [currentVal, setCurrentVal] = useState(value.toString());

  let className = 'length-input-container';

  if (disabled) {
    className += ' disabled';
  }

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        setCurrentVal('');
        onChange(0);
        return;
      }

      let rotation = parseInt(e.target.value, 10);

      if (Number.isNaN(rotation)) {
        rotation = 0;
      }

      if (rotation < -360) {
        rotation = -360;
      }

      if (rotation > 360) {
        rotation = 360;
      }

      let val = 0;

      try {
        val = Math.round(rotation);
      } finally {
        onChange(val);
        setCurrentVal(val.toString());
      }
    },
    [onChange]
  );

  return (
    <InputContainer className={className} size={84} inputSuffix={<>&deg;</>} inputSuffixInline={inline}>
      <input
        id={id}
        disabled={disabled}
        type='number'
        inputMode='numeric'
        pattern='\d*'
        className={`length-input ${valid ? '' : 'invalid'} ${inputClasses}`}
        value={currentVal}
        onChange={handleOnChange}
      />
    </InputContainer>
  );
};

export default DegreesInput;
