import React, { useMemo, useState } from 'react';
import { useLocalStorage } from '@gi/react-utils';

import DebugModalRenderer from './debug-modal-renderer';
import { DebugModalContext, DebugModalContextType } from './debug-modal-context';

interface iProps {
  children: React.ReactNode;
}

export const DebugModalProvider = ({ children }: iProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [showExpressionResult, setShowExpressionResult] = useState<boolean>(false);
  const [expressionText, setExpressionText] = useLocalStorage<string>('debugger-expression-text', '');

  const value = useMemo<DebugModalContextType>(
    () => ({ open, setOpen, showExpressionResult, setShowExpressionResult, expressionText, setExpressionText }),
    [open, expressionText, showExpressionResult]
  );

  return (
    <DebugModalContext.Provider value={value}>
      {children}
      <DebugModalRenderer />
    </DebugModalContext.Provider>
  );
};
