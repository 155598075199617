import React, { useContext } from 'react';
import { FeatureGate } from '@gi/features';
import FooterExpressionResult from './footer-expression-result';
import { DebugModalContext } from '../debug-modal-context';

const FooterExpressionDisplay = (): JSX.Element | null => {
  const { showExpressionResult } = useContext(DebugModalContext);

  if (!showExpressionResult) {
    return null;
  }

  return (
    <FeatureGate featureId='DEBUG_MODAL'>
      <FooterExpressionResult />
    </FeatureGate>
  );
};

export default FooterExpressionDisplay;
