import React, { useContext } from 'react';

import { PlannerCommandContext } from '@gi/app-planner-desktop';

import MobilePlanMenuButton from '../mobile-plan-menu-button';

interface iProps {
  callAndClose: (callback: null | (() => void)) => () => void;
}

/**
 * Common controls to display for the active plan, regardless of planner tab
 */
const MobileSharedActiveControls = ({ callAndClose }: iProps): JSX.Element => {
  const { openSettings } = useContext(PlannerCommandContext);

  return (
    <MobilePlanMenuButton leftIcon='icon-cog' onClick={callAndClose(openSettings)} identifier='mobile-plan-menu-plan-settings'>
      Plan Settings
    </MobilePlanMenuButton>
  );
};

export default MobileSharedActiveControls;
