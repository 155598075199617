import React, { useContext, useMemo } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';

import { CropRotationModes } from '@gi/constants';
import { getTopbarSelectStyles } from '../../common/select-styles';
import { PlannerCommandContext } from '../../../../planner-command-provider';

import styles from './crop-rotation-selector.module.css';

const BASE_SELECT_STYLES = getTopbarSelectStyles(152);

const SELECT_STYLES = {
  ...BASE_SELECT_STYLES,
  singleValue: (provided) => ({
    ...provided,
    width: '100%',
    paddingRight: '8px',
  }),
};

interface iFormatCropRotationOptionLabel {
  label: string;
  className: string;
}

const FormatCropRotationOptionLabel = ({ label, className }: iFormatCropRotationOptionLabel): JSX.Element => {
  let indicator: JSX.Element | null = <div className={styles.familyIndicator} />;
  if (className === styles.familyOff || className === styles.familyAuto) {
    indicator = null;
  }

  return (
    <div className={`crop-rotation-dropdown-option ${className}`}>
      <div className='family-name'>{label}</div>
      {indicator}
    </div>
  );
};

const options = [
  { label: 'Off', value: CropRotationModes.OFF, className: styles.familyOff },
  { label: 'Automatic', value: CropRotationModes.AUTOMATIC, className: styles.familyAuto },
  { label: 'Brassicas', value: CropRotationModes.BRASSICAS, className: styles.familyBrassicas },
  { label: 'Legumes', value: CropRotationModes.LEGUMES, className: styles.familyLegumes },
  { label: 'Solanaceae', value: CropRotationModes.SOLANACEAE, className: styles.familySolanaceae },
  { label: 'Allium', value: CropRotationModes.ALLIUM, className: styles.familyAllium },
  {
    label: 'Umbellliferae',
    value: CropRotationModes.UMBELLIFERAE,
    className: styles.familyUmbelliferae,
  },
  { label: 'Cucurbits', value: CropRotationModes.CUCURBITS, className: styles.familyCucurbits },
  {
    label: 'Chenopodiaceae',
    value: CropRotationModes.CHENOPODIACEAE,
    className: styles.familyChenopodiaceae,
  },
];

const getLabelForValue = (value: CropRotationModes) => {
  switch (value) {
    case CropRotationModes.OFF:
      return 'Off';
    case CropRotationModes.AUTOMATIC:
      return 'Automatic';
    case CropRotationModes.BRASSICAS:
      return 'Brassicas';
    case CropRotationModes.LEGUMES:
      return 'Legumes';
    case CropRotationModes.SOLANACEAE:
      return 'Solanaceae';
    case CropRotationModes.ALLIUM:
      return 'Allium';
    case CropRotationModes.UMBELLIFERAE:
      return 'Umbellliferae';
    case CropRotationModes.CUCURBITS:
      return 'Cucurbits';
    case CropRotationModes.CHENOPODIACEAE:
      return 'Chenopodiaceae';
    default:
      return '';
  }
};

const getClassForValue = (value: CropRotationModes) => {
  switch (value) {
    case CropRotationModes.OFF:
      return 'family-off';
    case CropRotationModes.AUTOMATIC:
      return 'family-auto';
    case CropRotationModes.BRASSICAS:
      return 'family-brassicas';
    case CropRotationModes.LEGUMES:
      return 'family-legumes';
    case CropRotationModes.SOLANACEAE:
      return 'family-solanaceae';
    case CropRotationModes.ALLIUM:
      return 'family-allium';
    case CropRotationModes.UMBELLIFERAE:
      return 'family-umbellliferae';
    case CropRotationModes.CUCURBITS:
      return 'family-cucurbits';
    case CropRotationModes.CHENOPODIACEAE:
      return 'family-chenopodiaceae';
    default:
      return '';
  }
};

interface iProps {
  hideLabel?: boolean;
  menuPlacement?: MenuPlacement;
  attachToBody?: boolean;
  id?: string;
  disabled?: boolean;
  identifier?: string;
}

const CropRotationSelector = ({ hideLabel, menuPlacement, attachToBody = false, id, disabled, identifier }: iProps): JSX.Element | null => {
  const { cropRotation, setCropRotation } = useContext(PlannerCommandContext);

  const onReset = () => {
    if (setCropRotation) {
      setCropRotation(CropRotationModes.AUTOMATIC);
    }
  };

  const setCropRotationModeValue = (e) => {
    if (setCropRotation) {
      setCropRotation(e.value);
    }
  };

  const cropRotationModeValue = useMemo(() => {
    if (cropRotation) {
      return {
        label: getLabelForValue(cropRotation),
        value: cropRotation,
        className: getClassForValue(cropRotation),
      };
    }

    return {
      label: getLabelForValue(options[0].value),
      value: options[0].value,
      className: getClassForValue(options[0].value),
    };
  }, [cropRotation]);

  const renderReset = () => {
    if (!cropRotation || cropRotation === CropRotationModes.AUTOMATIC) {
      return null;
    }

    return (
      <span>
        <button type='button' className={`button button-borderless ${styles.cropRotationReset}`} onClick={onReset}>
          Reset
        </button>
      </span>
    );
  };

  const selectComponent = (
    <ReactSelect
      isDisabled={disabled}
      styles={SELECT_STYLES}
      options={options}
      value={cropRotationModeValue}
      onChange={setCropRotationModeValue}
      formatOptionLabel={FormatCropRotationOptionLabel}
      menuPortalTarget={attachToBody ? document.body : undefined}
      menuPlacement={menuPlacement}
      inputId={id}
      isSearchable={false}
    />
  );

  if (hideLabel) {
    return selectComponent;
  }

  return (
    <div className={styles.cropRotationInput} data-identifier={identifier}>
      <div>{selectComponent}</div>
      {renderReset()}
    </div>
  );
};

export default CropRotationSelector;
