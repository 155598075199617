import { Geometry, Matrix } from '@gi/math';
import { SimulatedPlant } from '../simulated-plant';
import { EditSimulatedGardenItem } from './edit-simulated-garden-item';
import { PlantSnapUtils } from '../snap-utils';

class EditSimulatedPlant extends EditSimulatedGardenItem<SimulatedPlant> {
  rowStart: Vector2;
  rowEnd: Vector2;
  center: Vector2;
  labelPosition: Vector2;
  height: number;
  rotation: number;

  start(): void {
    this.rowStart = { ...this.target.rowStart };
    this.rowEnd = { ...this.target.rowEnd };
    this.center = Geometry.midpoint(this.rowStart, this.rowEnd);
    this.labelPosition = Geometry.addPoint(this.center, this.target.labelOffset);
    this.height = this.target.height;
    this.rotation = this.target.rotation;
  }

  translate(positionOffset: Vector2): void {
    this.target.setPositions(Geometry.addPoint(this.rowStart, positionOffset), Geometry.addPoint(this.rowEnd, positionOffset), this.height);
  }

  transform(rowStart: Vector2, rowEnd: Vector2, height: number) {
    this.target.setPositions(rowStart, rowEnd, height);
  }

  rotate(angleOffset: number, pivot: Vector2, matrix: number[][]): void {
    const startPos = Matrix.multiplyVector(this.rowStart, matrix);
    const endPos = Matrix.multiplyVector(this.rowEnd, matrix);

    this.target.setPositions(startPos, endPos, this.height);

    // Don't rotate the labels of SFG plants, it feels wrong.
    if (this.target.isSquareFoot) {
      return;
    }

    // Also rotate the plant label position
    const newLabelPos = Matrix.multiplyVector(this.labelPosition, matrix);
    const diff = Geometry.getPointDelta(Geometry.midpoint(startPos, endPos), newLabelPos);

    this.target.setLabelOffset(diff);
  }

  snapTranslate(snapDistance: number): void {
    const { rowStart, rowEnd } = PlantSnapUtils.snapTranslate(this.target.rowStart, this.target.rowEnd, snapDistance);

    this.target.setPositions(rowStart, rowEnd, this.target.height);
  }

  snapTransform(snapDistance: number): void {
    const { rowStart, rowEnd } = PlantSnapUtils.snapTransform(this.target.rowStart, this.target.rowEnd, snapDistance);

    this.target.setPositions(rowStart, rowEnd, this.target.height);
  }

  // eslint-disable-next-line class-methods-use-this
  end(): void {
    // Do nothing
  }
}

export default EditSimulatedPlant;
