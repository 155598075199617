import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ObjectivesSelectors } from '../slice/objectives-slice';

const useObjectiveGroup = (id: string) => {
  const objectives = useSelector(ObjectivesSelectors.getObjectives);
  const groupsMap = useSelector(ObjectivesSelectors.getGroups);
  const objectivesCompletionDataMap = useSelector(ObjectivesSelectors.getObjectiveCompletionData);

  /** Find the group from its ID */
  const group = useMemo(() => {
    return groupsMap[id] ?? null;
  }, [id, groupsMap]);

  /** Find a list of all the enabled objective IDs */
  const enabledObjectiveIds = useMemo<string[]>(() => {
    if (!group) {
      return [];
    }
    return group.objectiveIds.filter((objectiveId) => objectives[objectiveId].enabled);
  }, [group, objectives]);

  /** Find a list of all the completed objective IDs */
  const completedObjectiveIds = useMemo<string[]>(() => {
    if (!group) {
      return [];
    }
    return enabledObjectiveIds.filter((objectiveId) => objectivesCompletionDataMap[objectiveId]);
  }, [group, objectivesCompletionDataMap, enabledObjectiveIds]);

  return useMemo(
    () => ({
      group,
      completedObjectiveIds,
      enabledObjectiveIds,
    }),
    [group, completedObjectiveIds, enabledObjectiveIds]
  );
};

export default useObjectiveGroup;
