import { useEffect, useMemo, useState } from 'react';

export const useLocalStorage = <T>(storageKey: string, fallbackState: T) => {
  const initialValue = useMemo(() => {
    const storageValue = localStorage.getItem(storageKey);

    if (storageValue === null) {
      return fallbackState;
    }
    try {
      return JSON.parse(storageValue);
    } catch {
      console.error(`Failed to parse local storage value ${storageKey}`);
      return fallbackState;
    }
  }, []);

  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue] as const;
};
