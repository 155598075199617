import { Geometry, Matrix } from '@gi/math';
import { SimulatedText } from '../simulated-text';
import { EditSimulatedGardenItem } from './edit-simulated-garden-item';
import { TextSnapUtils } from '../snap-utils';

class EditSimulatedText extends EditSimulatedGardenItem<SimulatedText> {
  startPos: Vector2;
  endPos: Vector2;
  rotation: number;
  centerPos: { x: number; y: number };
  size: { width: number; height: number };

  start(): void {
    this.startPos = { ...this.target.start };
    this.endPos = { ...this.target.end };
    this.centerPos = { ...this.target.center };
    this.size = { ...this.target.dimensions };
    this.rotation = this.target.rotation;
  }

  translate(positionOffset: Vector2) {
    this.target.setPosition(Geometry.addPoint(this.centerPos, positionOffset), this.size, this.rotation);
  }

  rotate(angle: number, pivot: Vector2, matrix: number[][]): void {
    const center = Matrix.multiplyVector(this.centerPos, matrix);

    this.target.setPosition(center, this.size, this.rotation + angle);
  }

  transform(center: Vector2, dimensions: Dimensions, rotation: number): void {
    this.target.setPosition(center, dimensions, rotation);
  }

  snapTranslate(snapDistance: number): void {
    const { start, end } = TextSnapUtils.snapTranslate(this.target.start, this.target.end, snapDistance);

    this.target.setPositions(start, end, this.target.rotation);
  }

  snapTransform(snapDistance: number): void {
    const { start, end } = TextSnapUtils.snapTransform(this.target.start, this.target.end, snapDistance);

    this.target.setPositions(start, end, this.target.rotation);
  }

  // eslint-disable-next-line class-methods-use-this
  end(): void {
    // Do nothing
  }
}

export default EditSimulatedText;
