import Plant from '@gi/plant';
import { Node } from '@gi/core-renderer';
import { GardenItemType } from '@gi/constants';
import { PlantFamilyTypes } from '@gi/plant-families';

import TextNode from '../canvas-plan/nodes/text/text-node';
import PlantNode from '../canvas-plan/nodes/plant/plant-node';
import ShapeNode from '../canvas-plan/nodes/shapes/shape-node';
import SFGPlantNode from '../canvas-plan/nodes/plant/sfg-plant-node';
import PlantLabelNode from '../canvas-plan/nodes/plant/plant-label-node';
import GardenObjectNode from '../canvas-plan/nodes/garden-objects/garden-object-node';
import { NodeSerialisationUtils, SerialisedCanvasInteractionGroup } from './serialised-canvas-interaction-group';

export function getNodeType(node: Node): GardenItemType | null {
  if (node instanceof PlantNode || node instanceof SFGPlantNode || node instanceof PlantLabelNode) {
    return GardenItemType.Plant;
  }
  if (node instanceof GardenObjectNode) {
    return GardenItemType.GardenObject;
  }
  if (node instanceof ShapeNode) {
    return GardenItemType.Shape;
  }
  if (node instanceof TextNode) {
    return GardenItemType.Text;
  }
  return null;
}

class CanvasInteractionGroup {
  plants: PlantNode[] = [];
  sfgPlants: SFGPlantNode[] = [];
  plantLabels: PlantLabelNode[] = [];
  gardenObjects: GardenObjectNode[] = [];
  shapes: ShapeNode[] = [];
  text: TextNode[] = [];
  all: (PlantNode | SFGPlantNode | PlantLabelNode | GardenObjectNode | ShapeNode | TextNode)[] = [];
  totalItems: number = 0;
  itemIds: Set<number> = new Set();

  constructor(nodes: Node[] = []) {
    for (let i = 0; i < nodes.length; i++) {
      const target = nodes[i];
      if (target instanceof PlantNode) {
        this.plants.push(target);
      } else if (target instanceof SFGPlantNode) {
        this.sfgPlants.push(target);
      } else if (target instanceof PlantLabelNode) {
        this.plantLabels.push(target);
      } else if (target instanceof GardenObjectNode) {
        this.gardenObjects.push(target);
      } else if (target instanceof ShapeNode) {
        this.shapes.push(target);
      } else if (target instanceof TextNode) {
        this.text.push(target);
      } else {
        // eslint-disable-next-line no-continue
        continue;
      }
      this.itemIds.add(target.id);
      this.all.push(target);
      this.totalItems++;
    }
  }

  /**
   * Is this slection empty
   */
  isEmpty() {
    return this.totalItems === 0;
  }

  /**
   * Is this slection a single item
   */
  isSingleItem() {
    return this.totalItems === 1;
  }

  /**
   * Returns the first item in this selection group. Null if nothing in selection.
   * @returns A node
   */
  getItem() {
    return this.all[0] ?? null;
  }

  /**
   * Gets the plant from this group, if the group is a single selection.
   * @returns The plant node
   */
  getPlant(): PlantNode | SFGPlantNode | null {
    if (!this.isSingleItem()) {
      return null;
    }
    if (this.plants.length > 0) {
      return this.plants[0];
    }
    if (this.sfgPlants.length > 0) {
      return this.sfgPlants[0];
    }
    return null;
  }

  /**
   * Extracts a list of Plants from this selection group.
   * @returns A list of unique plants
   */
  getPlants(): Plant[] {
    const plants: Set<Plant> = new Set();
    for (let i = 0; i < this.plants.length; i++) {
      plants.add(this.plants[i].plant);
    }
    for (let i = 0; i < this.sfgPlants.length; i++) {
      plants.add(this.sfgPlants[i].plant);
    }
    return Array.from(plants);
  }

  /**
   * Extracts a list of Plant Families from this selection group.
   * @returns A list of unique plant families
   */
  getPlantFamilies(): PlantFamilyTypes[] {
    const families: Set<PlantFamilyTypes> = new Set();
    for (let i = 0; i < this.plants.length; i++) {
      families.add(this.plants[i].plant.familyID);
    }
    for (let i = 0; i < this.sfgPlants.length; i++) {
      families.add(this.sfgPlants[i].plant.familyID);
    }
    return Array.from(families);
  }

  /**
   * Converts this interaction group into something serialisable for use in redux
   */
  serialise(): SerialisedCanvasInteractionGroup {
    const plants = [...this.plants, ...this.sfgPlants].map(NodeSerialisationUtils.serialisePlantNode);
    const plantLabels = this.plantLabels.map(NodeSerialisationUtils.serialisePlantLabelNode);
    const gardenObjects = this.gardenObjects.map(NodeSerialisationUtils.serialiseGardenObjectNode);
    const shapes = this.shapes.map(NodeSerialisationUtils.serialiseShapeNode);
    const text = this.text.map(NodeSerialisationUtils.serialiseTextNode);
    return {
      plants,
      plantLabels,
      gardenObjects,
      shapes,
      text,
      all: [...plants, ...plantLabels, ...gardenObjects, ...shapes, ...text],
    };
  }
}

export default CanvasInteractionGroup;
