import React, { useContext } from 'react';

import { TooltipKey } from '@gi/tooltip';

import { PlannerCommandContext } from '../../../../planner-command-provider';
import ControlButtonGroup from '../control-button-group';
import MenuButton from '../menu-button';
import SaveMenuButton from '../save-menu-button';

/**
 * A button group containing New Plan, Open Plan and Save Plan
 */
const FileMenuButtonGroup = (): JSX.Element => {
  const { newPlan, openPlan } = useContext(PlannerCommandContext);

  return (
    <ControlButtonGroup>
      <MenuButton id='new-plan' onClick={newPlan} tooltipKey={TooltipKey.NewPlan}>
        <i className='icon-doc' />
      </MenuButton>
      <MenuButton id='open-plan' onClick={openPlan} tooltipKey={TooltipKey.OpenPlan}>
        <i className='icon-folder-open-empty' />
      </MenuButton>
      <SaveMenuButton />
    </ControlButtonGroup>
  );
};

export default FileMenuButtonGroup;
