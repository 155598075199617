import { ExpressionUtils, Type } from '@gi/expression';
import { Tutorial } from './tutorial';
import { TutorialRequirementCondition, TutorialStep } from './tutorial-data';

export type TutorialAnalytics = {
  maxStep: number;
};

export type VideoAnalytics = {
  ref?: string;
  tutorialUuid: string;
  stepIndex: number;
  contentIndex: number;
  playCount: number;
  totalPlayTime: number;
};

export type RunningTutorial = {
  tutorial: Tutorial;
  current: TutorialStep;
  currentRequirementMessages: string[];
  currentIndex: number;
  next: TutorialStep | null;
  previous: TutorialStep | null;
  analytics: TutorialAnalytics;
};

export class RunningTutorialUtils {
  /**
   * Creates a new RunningTutorial from a Tutorial, setting the current step as the first
   * step in the tutorial and assigning next/previous and currentIndex as required
   */
  static createRunningTutorial(tutorial: Tutorial, state: Record<string, any>): RunningTutorial | null {
    // Handle malformed tutorials
    if (tutorial.data.steps.length === 0) {
      return null;
    }

    const currentTutorialRequirementMessages = RunningTutorialUtils.evaluateTutorialRequirements(tutorial, state);
    const currentStepRequirementMessages = RunningTutorialUtils.evaluateStepRequirements(tutorial.data.steps[0], state);

    return {
      tutorial,
      previous: null,
      current: tutorial.data.steps[0],
      currentRequirementMessages: [...currentTutorialRequirementMessages, ...currentStepRequirementMessages],
      currentIndex: 0,
      next: tutorial.data.steps.length > 1 ? tutorial.data.steps[1] : null,
      analytics: {
        maxStep: 0,
      },
    };
  }

  static updateCurrentStepRequirements(runningTutorial: RunningTutorial, state: Record<string, any>): RunningTutorial {
    if (!runningTutorial.current.requirements && !runningTutorial.tutorial.data.requirements) {
      return runningTutorial;
    }

    const currentTutorialRequirementMessages = RunningTutorialUtils.evaluateTutorialRequirements(runningTutorial.tutorial, state);
    const currentStepRequirementMessages = RunningTutorialUtils.evaluateStepRequirements(runningTutorial.current, state);

    return {
      ...runningTutorial,
      currentRequirementMessages: [...currentTutorialRequirementMessages, ...currentStepRequirementMessages],
    };
  }

  static evaluateStepRequirements(step: TutorialStep, state: Record<string, any>): string[] {
    if (!('requirements' in step)) {
      return [];
    }

    const { requirements } = step;

    if (requirements === undefined) {
      return [];
    }

    const messages: string[] = [];

    // Go through each condition, all conditions need to evaluate to true for this tutorial to be enabled
    for (let i = 0; i < requirements.length; i++) {
      const expressionResult = ExpressionUtils.getExpressionResult(requirements[i].expression, state);
      if (!expressionResult.success) {
        console.error('Failed to parse expression: ', expressionResult.error);
        // eslint-disable-next-line no-continue
        continue;
      }

      if (expressionResult.result[1] !== Type.Boolean) {
        console.error('Tutorial enabled expression returned incorrect type (expected boolean): ', expressionResult.result[1]);
        // eslint-disable-next-line no-continue
        continue;
      }

      // This expression evaluated as false
      if (!expressionResult.result[0]) {
        messages.push(requirements[i].failureMessage);
      }
    }

    return messages;
  }

  static evaluateTutorialRequirements(tutorial: Tutorial, state: Record<string, any>): string[] {
    if (!('requirements' in tutorial.data || tutorial.data.requirements === undefined)) {
      return [];
    }

    const requirementConditions = tutorial.data.requirements as TutorialRequirementCondition[];
    // Tutorials without requirements have no requirement messages
    if (requirementConditions.length === 0) {
      return [];
    }

    const messages: string[] = [];

    // Go through each condition, all conditions need to evaluate to true for this tutorial to be enabled
    for (let i = 0; i < requirementConditions.length; i++) {
      const expressionResult = ExpressionUtils.getExpressionResult(requirementConditions[i].expression, state);
      if (!expressionResult.success) {
        console.error('Failed to parse expression: ', expressionResult.error);
        // eslint-disable-next-line no-continue
        continue;
      }

      // Make sure expressions result in a boolean, if they do not they are not valid as enabled expressions
      if (expressionResult.result[1] !== Type.Boolean) {
        console.error('Tutorial enabled expression returned incorrect type (expected boolean): ', expressionResult.result[1]);
        // eslint-disable-next-line no-continue
        continue;
      }

      // This expression evaluated as false
      if (!expressionResult.result[0]) {
        messages.push(requirementConditions[i].message);
      }
    }

    return messages;
  }

  /**
   * Immutably advances a RunningTutorial, returning a new RunningTutorial
   * instance if it is able to advance to the next step, otherwise returns the
   * provided RunningTutorial
   */
  static advanceStep(runningTutorial: RunningTutorial): RunningTutorial {
    if (!runningTutorial.next) {
      console.warn('Attempted to go to next tutorial step but not present');
      return runningTutorial;
    }

    const newRunningTutorial = {
      ...runningTutorial,
      analytics: {
        ...runningTutorial.analytics,
      },
    };

    newRunningTutorial.current = runningTutorial.next;
    newRunningTutorial.currentIndex = runningTutorial.currentIndex + 1;
    newRunningTutorial.previous = runningTutorial.current;
    newRunningTutorial.next = RunningTutorialUtils.findNextStep(runningTutorial.tutorial, newRunningTutorial.current);
    newRunningTutorial.analytics.maxStep = Math.max(newRunningTutorial.currentIndex, newRunningTutorial.analytics.maxStep);

    return newRunningTutorial;
  }

  /**
   * Immutably reverts a RunningTutorial to its previous step, returning a new RunningTutorial
   * instance if a previous step is available, otherwise returns the
   * provided RunningTutorial
   */
  static backStep(runningTutorial: RunningTutorial): RunningTutorial {
    if (!runningTutorial.previous) {
      console.warn('Attempted to go to previous tutorial step but not present');
      return runningTutorial;
    }

    const newRunningTutorial = {
      ...runningTutorial,
    };

    newRunningTutorial.current = runningTutorial.previous;
    newRunningTutorial.currentIndex = runningTutorial.currentIndex - 1;
    newRunningTutorial.next = runningTutorial.current;
    newRunningTutorial.previous = RunningTutorialUtils.findPreviousStep(runningTutorial.tutorial, newRunningTutorial.current);

    return newRunningTutorial;
  }

  private static findNextStep(tutorial: Tutorial, step: TutorialStep): TutorialStep | null {
    const index = tutorial.data.steps.indexOf(step);

    if (index === -1) {
      console.error('Attempted to find next step in tutorial but current step not present');
      return null;
    }

    if (index === tutorial.data.steps.length - 1) {
      // No next step
      return null;
    }

    return tutorial.data.steps[index + 1];
  }

  private static findPreviousStep(tutorial: Tutorial, step: TutorialStep): TutorialStep | null {
    const index = tutorial.data.steps.indexOf(step);

    if (index === -1) {
      console.error('Attempted to find next step in tutorial but current step not present');
      return null;
    }

    if (index === 0) {
      // No next step
      return null;
    }

    return tutorial.data.steps[index - 1];
  }
}
