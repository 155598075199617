import React, { useContext } from 'react';
import ControlGroup from '../../common/control-group';
import ControlRow from '../../common/control-row';
import ControlButtonGroup from '../../common/control-button-group';
import MenuButton from '../../common/menu-button';

import { PlannerCommandContext } from '../../../../planner-command-provider';

const HelpControls = (): JSX.Element => {
  const { openLearnToUse, openLiveChat, openHelpPages } = useContext(PlannerCommandContext);
  return (
    <ControlGroup>
      <ControlRow>
        <ControlButtonGroup>
          <MenuButton helpButton id='new-plan' onClick={openLearnToUse} data-identifier='toolbar-learn-to-use'>
            <i className='icon-mortar-board' /> Learn to Use
          </MenuButton>
          <MenuButton helpButton id='new-plan' onClick={() => openLiveChat('desktop-planner-menubar')} data-identifier='toolbar-chat'>
            Chat
          </MenuButton>
          <MenuButton helpButton id='new-plan' onClick={openHelpPages} data-identifier='toolbar-help-pages'>
            Help Pages
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default HelpControls;
