import React, { useContext } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalTabs,
  ModalTabList,
  ModalTab,
  ModalTabContentContainer,
  ModalTabContent,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';
import { DebugModalContext } from './debug-modal-context';
import PlatformStateTab from './tabs/platform-state-tab';
import ExpressionTab from './tabs/expression-tab/expression-tab';
import UUIDTab from './tabs/uuid-tab';
import TutorialsTab from './tabs/tutorial-tab/tutorial-tab';
import { DebugTab } from './debug-tabs';
import FeatureFlagsTab from './tabs/feature-flags-tab';

const DebugModal = () => {
  const { setOpen } = useContext(DebugModalContext);

  return (
    <Modal closeRequest={() => setOpen(false)}>
      <ModalContent>
        <ModalCloseButton onClick={() => setOpen(false)} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Debug</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalTabs defaultActiveTab={DebugTab.PlatformState}>
          <ModalTabList>
            <ModalTab tabID={DebugTab.PlatformState}>PlatformState</ModalTab>
            <ModalTab tabID={DebugTab.Expressions}>Expressions</ModalTab>
            <ModalTab tabID={DebugTab.UUID}>UUID</ModalTab>
            <ModalTab tabID={DebugTab.Tutorials}>Tutorials</ModalTab>
            <ModalTab tabID={DebugTab.FeatureFlags}>Feature Flags</ModalTab>
          </ModalTabList>
          <ModalTabContentContainer>
            <ModalTabContent tabID={DebugTab.PlatformState}>
              <PlatformStateTab />
            </ModalTabContent>
            <ModalTabContent tabID={DebugTab.Expressions}>
              <ExpressionTab />
            </ModalTabContent>
            <ModalTabContent tabID={DebugTab.UUID}>
              <UUIDTab />
            </ModalTabContent>
            <ModalTabContent tabID={DebugTab.Tutorials}>
              <TutorialsTab />
            </ModalTabContent>
            <ModalTabContent tabID={DebugTab.FeatureFlags}>
              <FeatureFlagsTab />
            </ModalTabContent>
          </ModalTabContentContainer>
        </ModalTabs>
      </ModalContent>
    </Modal>
  );
};

export default DebugModal;
