import Bitmask, { BitmaskComparisonMode, BitmaskType } from '@gi/bitmask';
import { LayerDisplayModes, LayerType, LayerTypes } from '@gi/constants';
import { DisplayModeFlag } from '@gi/core-renderer';

function getLayerDisplayModeFlag(layer: LayerType): DisplayModeFlag {
  switch (layer) {
    case LayerTypes.PLANTS:
      return DisplayModeFlag.LAYER_PLANTS;
    case LayerTypes.STRUCTURES:
      return DisplayModeFlag.LAYER_STRUCTURES;
    case LayerTypes.IRRIGATION:
      return DisplayModeFlag.LAYER_IRRIGATION;
    case LayerTypes.TEXT:
      return DisplayModeFlag.LAYER_TEXT;
    case LayerTypes.LAYOUT:
      return DisplayModeFlag.LAYER_LAYOUT;
    case LayerTypes.PLANT_LABELS:
      return DisplayModeFlag.LAYER_PLANT_LABELS;
    default:
      console.error(`Unknown layer type: ${layer}`);
      return DisplayModeFlag.LAYER_LAYOUT;
  }
}

/**
 * Creates a DisplayMode bitmask, containing the layer and locked information
 * @param layer The layer the item is on
 * @param locked Is this item locked
 * @returns A bitmask representing this item
 */
export function getDisplayModeBitmask(layer: LayerType, locked: boolean): BitmaskType<DisplayModeFlag> {
  let bitmask = Bitmask.Create(getLayerDisplayModeFlag(layer));
  if (locked) {
    bitmask = Bitmask.Add(bitmask, DisplayModeFlag.LOCKED);
  } else {
    bitmask = Bitmask.Add(bitmask, DisplayModeFlag.UNLOCKED);
  }
  return bitmask;
}

type DisplayModeRule = { mask: BitmaskType<DisplayModeFlag>; mode: BitmaskComparisonMode };

/**
 * Mapping fro defining which items should be disabled for the given layer display mode
 */
const DisabledMapping = {
  [LayerDisplayModes.ALL]: {
    // Disable nothing (default)
    mask: Bitmask.NONE.value,
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.VIEW_ONLY]: {
    mask: Bitmask.NONE.value,
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.PLANTS]: {
    // Disable everything that isn't a plant or plant label
    mask: Bitmask.Create(DisplayModeFlag.LAYER_PLANTS, DisplayModeFlag.LAYER_PLANT_LABELS),
    mode: BitmaskComparisonMode.NotContainsAny,
  },
  [LayerDisplayModes.STRUCTURES]: {
    mask: Bitmask.Create(DisplayModeFlag.LAYER_STRUCTURES),
    mode: BitmaskComparisonMode.NotContainsAny,
  },
  [LayerDisplayModes.IRRIGATION]: {
    mask: Bitmask.Create(DisplayModeFlag.LAYER_IRRIGATION),
    mode: BitmaskComparisonMode.NotContainsAny,
  },
  [LayerDisplayModes.TEXT]: {
    mask: Bitmask.Create(DisplayModeFlag.LAYER_TEXT),
    mode: BitmaskComparisonMode.NotContainsAny,
  },
  [LayerDisplayModes.LAYOUT]: {
    mask: Bitmask.Create(DisplayModeFlag.LAYER_LAYOUT),
    mode: BitmaskComparisonMode.NotContainsAny,
  },
  [LayerDisplayModes.LOCKED_ITEMS]: {
    // Disable everything that isn't locked
    mask: Bitmask.Create(DisplayModeFlag.LOCKED),
    mode: BitmaskComparisonMode.NotContainsAny,
  },
} satisfies Record<LayerDisplayModes, DisplayModeRule>;

/**
 * Mapping for defining which items should be in view-only mode for the given layer display mode
 */
const ViewOnlyMapping = {
  [LayerDisplayModes.ALL]: {
    // Make all locked items view-only (default for most modes)
    mask: Bitmask.Create(DisplayModeFlag.LOCKED),
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.VIEW_ONLY]: {
    // Make everything view-only
    mask: Bitmask.ALL.value,
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.PLANTS]: {
    mask: Bitmask.Create(DisplayModeFlag.LOCKED),
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.STRUCTURES]: {
    mask: Bitmask.Create(DisplayModeFlag.LOCKED),
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.IRRIGATION]: {
    mask: Bitmask.Create(DisplayModeFlag.LOCKED),
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.TEXT]: {
    mask: Bitmask.Create(DisplayModeFlag.LOCKED),
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.LAYOUT]: {
    mask: Bitmask.Create(DisplayModeFlag.LOCKED),
    mode: BitmaskComparisonMode.ContainsAny,
  },
  [LayerDisplayModes.LOCKED_ITEMS]: {
    // Make nothing view-only. Unlocked items will be disabled instead.
    mask: Bitmask.NONE.value,
    mode: BitmaskComparisonMode.ContainsAny,
  },
} satisfies Record<LayerDisplayModes, DisplayModeRule>;

/**
 * Returns masks and matching rules for which items to display for the given layer mode.
 * @param layerMode The selected layer mode
 * @returns Masks for which items to display as disabled/view-only
 */
export function getDisplayModeRulesFromLayerDisplayMode(layerMode: LayerDisplayModes): {
  disabled: DisplayModeRule;
  viewOnly: DisplayModeRule;
} {
  return {
    disabled: DisabledMapping[layerMode],
    viewOnly: ViewOnlyMapping[layerMode],
  };
}
