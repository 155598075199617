import React, { useContext } from 'react';
import { TutorialEditorContext } from '../tutorial-editor-context';
import Item from './item';

import styles from './tutorial-selection-list.module.css';

const TutorialSelectionList = (): JSX.Element | null => {
  const { editingTutorials, selectedTutorial, setSelectedTutorial } = useContext(TutorialEditorContext);

  if (editingTutorials.uuids.length === 0) {
    return null;
  }

  return (
    <div className={styles.selectionListScrollPane}>
      <ul className={styles.tutorialSelectionList}>
        {editingTutorials.uuids.map((uuid) => (
          <Item
            key={uuid}
            tutorial={editingTutorials.byUuid[uuid]}
            selected={editingTutorials.byUuid[uuid] === selectedTutorial}
            onSelect={setSelectedTutorial}
          />
        ))}
      </ul>
    </div>
  );
};

export default TutorialSelectionList;
