import React, { useContext } from 'react';

import { TooltipKey } from '@gi/tooltip';

import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuButton from '../common/menu-button';
import { PlannerCommandContext } from '../../../planner-command-provider';
import FileMenuButtonGroup from '../common/parts/file-menu-button-group';

interface iProps {
  hideFileControls?: boolean;
}

const NotesControls = ({ hideFileControls }: iProps): JSX.Element => {
  const { printNotes } = useContext(PlannerCommandContext);

  return (
    <ControlGroup>
      <ControlRow>
        {!hideFileControls ? <FileMenuButtonGroup /> : null}
        <ControlButtonGroup>
          <MenuButton id='print-notes' onClick={printNotes} tooltipKey={TooltipKey.PrintNotes}>
            <i className='icon-print' />
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default NotesControls;
